import axiosTestSimulator from "../../../../../utils/test_simulatorAxios";
import axios from "../../../../../utils/workflowAxios";

export const workflowService = {

    async createWorkflow({ name, botId }) {
        try {
            const result = await axios.post(`workflow/create`, { name, botId });
            return result;
        } catch (error) {
            console.log(error);
        }
    },

    async deleteWorkflow(botId, workflowId) {
        try {
            const result = await axios.delete(`workflow/${botId}/${workflowId}`);
            return result;
        } catch (error) {
            console.log(error);
        }
    },

    async getWorkflowListOfBotFormServerAsync(botId, selectedVersion) {
        try {
            const getAllWorkflowResult = await axios.get(`workflow/getAll/${botId}/${selectedVersion}`);
            return getAllWorkflowResult.data.data;
        } catch (error) {
            console.log(error);
        }
    },

    async getWorkflowDataFromServerAsync(botId, workflowId, projection = {}) {
        try {
            const response = await axios.get(`workflow/getOne/${botId}/${workflowId}`, {
                headers: { "Content-Type": "application/json" },
                params: projection,
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    async getMediaListFromServerAsync() {
        try {
            const response = await axios.get(`workflow/get-media`, {
                headers: { "Content-Type": "application/json" },
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    async getMessageTemplateList() {
        try {
            const getAllTemplateResult = await axios.get(`message-template/getAll`);
            return getAllTemplateResult.data;
        } catch (error) {
            console.log(error);
        }
    },

    async updateWorkflowDataAsync({
        botId,
        workflowId,
        nodes,
        edges,
        variables,
        variableSuggestionList,
        errorCount,
        usedSubflowIdArray,
        selectedLocale,
        defaultLocale,
        localeSupportedByBot,
        workflowSaveType
    }) {
        try {
            const dataToSend = {
                nodes,
                edges,
                variables,
                variableSuggestionList,
                errorCount,
                usedSubflowIdArray,
                selectedLocale,
                defaultLocale,
                localeSupportedByBot,
                workflowSaveType,
                locales: selectedLocale
            };
            const response = await axios.post(
                `workflow/update/${botId}/${workflowId}`,
                dataToSend,
                { headers: { "Content-Type": "application/json" } }
            );
            return response;
        } catch (error) {
            console.log(error);
            return error;
        }
    },

    async uploadMediaListInServerAsync(formData) {
        try {
            return await axios.post(`workflow/upload-media`, formData);
        } catch (error) {
            throw error;
        }
    },


    async deleteMediaListInServerAsync(mediaFile) {
        try {
            return await axios.post(`workflow/delete-media-file`, mediaFile);
        } catch (error) {
            throw error;
        }
    },

    async deployBotAsync({
        botId,
        workflowId,
        nodes,
        edges,
        variables,
        variableSuggestionList,
    }) {
        try {
            const dataToSend = { nodes, edges, variables, variableSuggestionList };
            const response = await axios.post(
                `workflow/deploy-bot/${botId}/${workflowId}`,
                dataToSend,
                { headers: { "Content-Type": "application/json" } }
            );
            return response;
        } catch (error) {
            console.log(error);
            return error;
        }
    },

    async deleteMediaListInServerAsync(mediaFile) {
        try {
            return await axios.post(`workflow/delete-media-file`, mediaFile);
        } catch (error) {
            throw error;
        }
    },

    async deployBotAsync({
        botId,
        workflowId,
        nodes,
        edges,
        variables,
        variableSuggestionList,
    }) {
        try {
            const dataToSend = { nodes, edges, variables, variableSuggestionList };
            const response = await axios.post(
                `workflow/deploy-bot/${botId}/${workflowId}`,
                dataToSend,
                { headers: { "Content-Type": "application/json" } }
            );
            return response;
        } catch (error) {
            console.log(error);
            return error;
        }
    },

    async testUrlForWebhookNode({ url, method, headers, body }) {
        try {
            const response = await axios.post(
                `workflow/test-url-for-webhook`,
                { url, method, headers, body },
                { headers: { "Content-Type": "application/json" } }
            );
            return response;
        } catch (error) {
            console.log(error);
        }
    },

    async testUrlForWebhookNodeV2({ url, method, headers, body, contentType }) {
        try {
            const response = await axios.post(
                `workflow/v2/test-url-for-webhook`,
                { url, method, headers, body, contentType },
                { headers: { "Content-Type": "application/json" } }
            );
            return response;
        } catch (error) {
            console.log(error);
        }
    },

    async testWhatsAppInSimulator({
        isDefaultWorkflow,
        botId,
        workflowId,
        data,
        formData,
        botVersion
    }) {

        let path = "";
        if (isDefaultWorkflow) {
            path = `/whatsapp-simulated-server-for-default`;
        } else {
            path = `/whatsapp-simulated-server`;
        }

        if (formData !== undefined) {
            formData.append("botId", botId);
            formData.append("workflowId", workflowId);
            formData.append("data", JSON.stringify(data));
            formData.append("botVersion", botVersion);
            return await axiosTestSimulator.post(path, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        } else {
            return await axiosTestSimulator.post(
                path,
                { botId, workflowId, data, botVersion },
                {
                    headers: {},
                }
            );
        }
    },

    async testTubuluInSimulator({
        isDefaultWorkflow,
        botId,
        workflowId,
        data,
        formData,
        botVersion
    }) {

        let path = "";
        if (isDefaultWorkflow) {
            path = `tubulu/simulated-server-for-default`;
        } else {
            path = `tubulu/simulated-server`;
        }
        if (formData !== undefined) {
            formData.append("botId", botId);
            formData.append("workflowId", workflowId);
            formData.append("data", JSON.stringify(data));
            formData.append("botVersion", botVersion);
            return await axiosTestSimulator.post(path, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        } else {
            return await axiosTestSimulator.post(
                path,
                { botId, workflowId, data, botVersion },
            );
        }
    },

    async testWebChatInSimulator() { },

    async getDLTEntityDataForSMS({ integrationId }) {
        try {
            const result = await axios.get(`workflow/get-dlt-entity-data-for-sms?integrationId=${integrationId}`);
            if (
                result.data
                && result.data.senderNames
                && result.data.dltTemplateList
                && result.data.id
                && Array.isArray(result.data.dltTemplateList) === true
                && Array.isArray(result.data.senderNames) === true
            ) {
                return { dltEntityData: result.data };
            } else {
                return { dltEntityData: {} };
            }
        } catch (error) {
            console.log(error);
            return { dltEntityData: {} };
        }
    },

    async getSenderEmailListAndReplayToEmailListForEmail({ integrationId }) {
        try {
            const result = await axios.get(`workflow/get-sender-email-list?integrationId=${integrationId}`);
            if (result.data.senderEmailList) {
                return { replyToEmailList: result.data.replyToEmailList, senderEmailList: result.data.senderEmailList };
            } else {
                return { replyToEmailList: [], senderEmailList: [] };
            }
        } catch (error) {
            console.log(error);
            return { replyToEmailList: [], senderEmailList: [] };
        }
    },

    async deleteMedia(mediaFile) {
        try {
            return await axios.post(`workflow/delete-media`, mediaFile);
        } catch (error) {
            throw error;
        }
    },

    async getWorkflowData(botId, workflowId) {
        try {
            return await axios.get(`workflow/getOne/${botId}/${workflowId}`);
        } catch (error) {
            throw error;
        }
    },

    async getEncryptedWorkflowDataForExport(botId, workflowId, workflowType) {
        try {
            return await axios.post(`import-export/workflow/export/${workflowType}/${botId}/${workflowId}`);
        } catch (error) {
            throw error;
        }
    },

    async importWorkflowData(botId, workflowId, workflowType, workflowData) {
        try {
            return await axios.put(`import-export/workflow/import/${workflowType}/${botId}/${workflowId}`, { data: workflowData });
        } catch (error) {
            throw error;
        }
    },

    async decryptWorkflowData(workflowData, workflowType) {
        try {
            return await axios.post(`import-export/workflow/${workflowType}/decrypt`, { data: workflowData });
        } catch (error) {
            throw error;
        }
    },

};






