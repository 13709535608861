import { ECacheNode } from '../config';
import { limitation } from '../redux/builderLogic/limitation';

export default class WorkflowErrors {
    static limitations = limitation;

    static nodeLimitations = limitation.nodes;

    static whatsAppChannelLimitations = limitation.channels.whatsapp.nodes;

    static rcsChannelLimitations = limitation.channels.rcs.nodes;

    static HANGING_NODE_ERROR = 'Hanging node.';

    static START_NODE_NOT_CONNECTED_ERROR = 'Start node should be connected to another node.';

    static OUTPUT_VARIABLE_NOT_SELECTED_ERROR = 'Please select output variable.';

    static OUTPUT_VARIABLE_NOT_DEFINED = 'output variable not defined.';

    static SELECTED_OUTPUT_VARIABLE_NOT_VALID = 'Selected output variable is not valid.';

    static INPUT_VARIABLE_NOT_SELECTED_ERROR = 'Please select input variable.';

    static NOT_VALID_VARIABLE = 'not a valid variable.';

    static NODE_DESCRIPTION_MAX_CHARACTER_ERROR = `Node description character length should not exceed ${this.limitations.description.maxCharacterLength} characters.`;

    static CONTENT_NOT_FOUND_ERROR = 'Node content not found. Please delete, this node and re-insert it in canvas.';

    //========================================= INPUT_VALIDATIONS =========================================
    static INPUT_VALIDATIONS = {
        VALIDATION_TYPE_NOT_SELECTED_ERROR: 'Select validation type.',

        MEDIA_TYPE_NOT_SELECTED_ERROR: 'Select media type to validate.',

        ON_ERROR_HANDLE_NOT_CONNECTED: 'Connect On Error handle.',

        INPUT_TIMEOUT_HANDLE_NOT_CONNECTED: 'Connect Timeout handle.',
    };
    //====================================================================================================

    //=========================================== NODE_VALIDATIONS ===========================================

    static PLAIN_TEXT_NODE = {
        RESPONSE_TEXT_EMPTY_ERROR: "Response text can't be empty.",

        MAX_CHARACTER_LENGTH_ERROR: `Response text character length should not exceed ${this.nodeLimitations.plainMessageTextNode.maxCharacterLength} characters.`,
    };

    //========================================= QUESTION_NODE =========================================

    static QUESTION_NODE = {
        QUESTION_TEXT_EMPTY_ERROR: "Question text can't be empty.",

        MAX_CHARACTER_LENGTH_ERROR: `Question text character length should not exceed ${this.nodeLimitations.questionNode.maxCharacterLength} characters.`,
    };

    //========================================= BUTTON_NODE ============================================

    static BUTTON_NODE = {
        CONTENT_NOT_FOUND_ERROR: 'Node content not found. Please delete, this node and re-insert it in Canvas.',

        BODY_TEXT_EMPTY_ERROR: "Body text can't be empty.",

        BODY_TEXT_MAX_CHARACTER_LENGTH_ERROR: `Body text character length should not exceed ${this.nodeLimitations.buttonNode.bodyText} characters.`,

        MIN_REQUIRED_BUTTON_ERROR: 'Button node should have at least one button.',

        MAX_BUTTON_ERROR: `Button node does not support more than ${this.nodeLimitations.buttonNode.maxButtons} buttons.`,

        BUTTON_TEXT_ERROR: 'Please enter button text.',

        BUTTON_INPUT_VALIDATION: 'Please select Input Validation.',
    };

    //========================================= LIST_NODE ============================================

    static LIST_NODE = {
        CONTENT_NOT_FOUND_ERROR: 'Node content not found. Please delete, this node and re-insert it in Canvas.',

        BODY_TEXT_EMPTY_ERROR: "Body text can't be empty.",

        BODY_TEXT_MAX_CHARACTER_LENGTH_ERROR: `Body text character length should not exceed ${this.nodeLimitations.listNode.bodyText} characters.`,

        OPEN_BTN_TEXT_EMPTY_ERROR: "Open button text can't be empty.",

        EMPTY_LIST_ERROR: 'Please add at least one list item.',

        LIST_ITEM_TEXT_EMPTY_ERROR: 'Please enter list item name.',
    };

    //========================================= MEDIA_NODE ============================================

    static MEDIA_NODE = {
        MEDIA_TYPE_REQUIRED_ERROR: 'Media type is required.',

        MEDIA_URL_IS_FROM_ERROR: 'Please select Image URL from.',

        MEDIA_FILE_NOT_SELECTED_ERROR_FOR_BY_URL: 'Please enter a valid URL.',

        MEDIA_FILE_NOT_SELECTED_ERROR_FOR_BY_UPLOAD: 'Select a uploaded URL from dropdown.',

        MEDIA_CAPTION_MAX_CHARACTER_LENGTH_ERROR: `Media caption character length should not exceed ${this.nodeLimitations.mediaNode.caption} characters.`,

        MEDIA_FILENAME_MAX_CHARACTER_LENGTH_ERROR: `Media filename character length should not exceed ${this.nodeLimitations.mediaNode.fileDisplayNameForDocument} characters.`,

        MEDIA_FILENAME_EMPTY_ERROR: `Media filename can't be empty.`,
    };

    //========================================= MEDIA_INPUT_NODE ============================================

    static MEDIA_INPUT_NODE = {
        INSTRUCTION_EMPTY_ERROR: "Media input instruction text can't be empty.",

        LIST_ITEM_TEXT_EMPTY_ERROR: 'Please enter list item title.',

        INSTRUCTION_MAX_CHARACTER_LENGTH_ERROR: `Media caption character length should not exceed ${this.nodeLimitations.mediaInputNode.textLength} characters.`,
    };

    //========================================= WEBHOOK_NODE ============================================

    static WEBHOOK_NODE = {
        EMPTY_URL_ERROR: 'URL should not be empty.',

        BODY_VARIABLE_NOT_SELECTED_ERROR: 'Select body variable.',
    };

    //========================================= VARIABLE ASSIGNMENT NODE ============================================

    static VARIABLE_ASSIGNMENT_NODE = {
        NO_VARIABLE_ASSIGN_ERROR: 'Please add a variable to assign.',

        SOURCE_VARIABLE_EMPTY_ERROR: 'Select source variable.',

        TARGET_VARIABLE_EMPTY_ERROR: 'Select target variable.',
    };

    //========================================= CONSTANT ASSIGNMENT NODE ============================================

    static CONSTANT_ASSIGNMENT_NODE = {
        NO_VARIABLE_ASSIGN_ERROR: 'Please add a variable to assign.',

        VARIABLE_EMPTY_ERROR: 'Select variable.',

        VALUE_EMPTY_ERROR: 'Enter value.',
    };

    //========================================= CONDITION NODE ============================================

    static CONDITION_NODE = {
        SELECT_COMPARE_TO_VALUE_IN_FIRST_CONDITION: 'Select the compare to value in first condition.',

        SELECT_COMPARE_WITH_VALUE_IN_FIRST_CONDITION: 'Select the compare with value in first condition.',

        SELECT_COMPARE_TO_VALUE_IN_SECOND_CONDITION: 'Select the compare to value in second condition.',

        SELECT_COMPARE_WITH_VALUE_IN_SECOND_CONDITION: 'Select the compare with value in second condition.',
    };

    //========================================= SWITCH CONDITION NODE ============================================

    static SWITCH_CONDITION_NODE = {
        SELECT_COMPARE_TO_VALUE: 'Select the compare to value.',

        SELECT_CONDITION_TYPE: 'Select the condition type.',

        SELECT_COMPARE_WITH: 'Select the compare with value.',

        COMPARE_WITH_VALUE_TEXT_LENGTH_ERROR: 'Compare with value text should not exceed 50 characters.',
    };

    //========================================= LOOP BACK NODE ============================================

    static LOOP_BACK_NODE = {
        SOURCE_AND_TARGET_MUST_CONNECTED_TO_PARENT_ERROR: 'The incoming and outgoing edges of the card must be connected to create a loop.',

        LOOP_BACK_CYCLE_VALID_CARD_ERROR: `In Loop Back cycle at least one Iteration Guard Card is required.`,
    };

    //========================================= ITERATION GUARD NODE ============================================

    static ITERATION_GUARD_NODE = {
        MAX_ITERATION_VALUE_ERROR: 'Max value should be greater than 0.',

        SELECT_MAX_VALUE_VARIABLE_ERROR: 'Select max value variable.',

        CONNECT_BREAK_HANDLE_ERROR: 'Connect break handle.',

        CONNECT_CONTINUE_HANDLE_ERROR: 'Connect continue handle.',

        MAX_ITERATION_VALUE_DECIMAL_ERROR: 'Max value must be an integer.',
    };

    //========================================= SUB FLOW NODE ============================================

    static SUB_FLOW_NODE = {
        SELECT_SUB_FLOW_ERROR: 'Select subflow.',
        SELECT_INPUT_VARIABLE_ERROR: 'Please enter value or variable',
        SELECT_OUTPUT_VARIABLE_ERROR: 'Select output variable',
        RECURSIVE_SUB_WORKFLOW_CALLS_ERROR: 'Invalid sub-workflow selection detected: Recursive call.'
    };

    //========================================= LOCATION OUTPUT NODE ============================================

    static LOCATION_OUTPUT_NODE = {
        SELECT_VARIABLE_ERROR: 'Select variable.',

        ENTER_LATITUDE_ERROR: 'Enter latitude.',

        ENTER_LONGITUDE_ERROR: 'Enter longitude.',
    };

    //========================================= LOCATION INPUT NODE ============================================

    static LOCATION_INPUT_NODE = {
        ENTER_TEXT_ERROR: 'Enter instruction text.',
    };

    //========================================= DYNAMIC BUTTON NODE ============================================

    static DYNAMIC_BUTTON_NODE = {
        BODY_TEXT_EMPTY_ERROR: "Body text can't be empty.",

        BODY_TEXT_MAX_CHARACTER_LENGTH_ERROR: `Body text character length should not exceed ${this.nodeLimitations.dynamicButtonNode.bodyText} characters.`,
    };

    //========================================= DYNAMIC LIST NODE ============================================

    static DYNAMIC_LIST_NODE = {
        BODY_TEXT_EMPTY_ERROR: "Body text can't be empty.",

        OPEN_BUTTON_TEXT_EMPTY_ERROR: "Open button text can't be empty.",

        BODY_TEXT_MAX_CHARACTER_LENGTH_ERROR: `Body text character length should not exceed ${this.nodeLimitations.dynamicListNode.bodyText} characters.`,
    };

    //========================================= DELAY NODE ============================================

    static DELAY_NODE = {
        INTERVAL_GREATER_THAN_ZERO_ERROR: 'Delay interval should be greater than zero.',
    };

    //========================================= SEND SMS NODE ============================================

    static SEND_SMS_NODE = {
        SELECT_RECEIPIENT_NUMBER_ERROR: 'Select recipient number variable.',

        SELECT_SENDER_NAME_ERROR: 'Select sender name.',

        SELECT_DLT_ENTITY_ID_ERROR: 'Select DLT entity Id.',

        SELECT_DLT_TEMPLATE_ID_ERROR: 'Select DLT Template Id.',
    };

    //========================================= SEND SMS NODE ============================================

    static SEND_EMAIL_NODE = {
        SELECT_RECEIPIENT_EMAIL_ERROR: 'Select recipient email variable.',

        EMAIL_BODY_EMPTY_ERROR: "Email body text can't be empty.",

        EMAIL_SUBJECT_EMPTY_ERROR: "Email subject text can't be empty.",

        SELECT_SENDER_EMAIL_ERROR: 'Select sender email.',

        SELECT_SENDER_DISPLAY_NAME_ERROR: 'Select sender display name.',

        SELECT_REPLY_TO_EMAIL_ERROR: 'Select reply to email.',

        SELECT_REPLY_TO_DISPLAY_NAME_ERROR: 'Select reply to display name.',
    };

    //========================================= SAVE RECIPIENT DATA NODE ============================================

    static SAVE_RECIPIENT_DATA_NODE = {
        ADD_DATA_IN_CARD_ERROR: 'Please add data.',

        SELECT_FULLNAME_VARIABLE_ERROR: 'Select fullname variable.',

        SELECT_EMAIL_VARIABLE_ERROR: 'Select email variable.',

        SELECT_PHONE_NUMBER_ERROR: 'Select phone number variable.',
    };

    //========================================= CAROUSEL NODE ============================================

    static CAROUSEL_NODE = {
        ADD_CAROUSEL_ERROR: 'Please add carousel.',

        EMPTY_IMAGE_URL_BY_URL: ({ carouselNumber }) => `Carousel ${carouselNumber}: Image URL can't be empty.`,

        EMPTY_IMAGE_URL_BY_UPLOAD: ({ carouselNumber }) => `Carousel ${carouselNumber}: Please select image from given dropdown.`,

        INVALID_IMAGE_URL: ({ carouselNumber }) => `Carousel ${carouselNumber}: Invalid image URL.`,

        BODY_TEXT_EMPTY_ERROR: ({ carouselNumber }) => `Carousel ${carouselNumber}: Body text can't be empty.`,

        MINIMUM_BUTTON_ERROR: ({ carouselNumber }) => `Carousel ${carouselNumber}: At least 1 button is required.`,

        BUTTON_NAME_EMPTY_ERROR: ({ carouselNumber, buttonNumber }) =>
            `Carousel ${carouselNumber}: Please enter button name in button ${buttonNumber}.`,

        BUTTON_NAME_MAX_CHARACTER_ERROR: ({ carouselNumber, buttonNumber }) =>
            `Carousel ${carouselNumber}: Button name of button ${buttonNumber} exceeding 20 characters limit.`,

        EMPTY_BUTTON_REDIRECT_URL_ERROR: ({ carouselNumber, buttonNumber }) =>
            `Carousel ${carouselNumber}: Please enter redirect url in button ${buttonNumber}.`,

        INVALID_BUTTON_REDIRECT_URL_ERROR: ({ carouselNumber, buttonNumber }) =>
            `Carousel ${carouselNumber}: Please enter valid redirect url in button ${buttonNumber}.`,
    };

    //========================================= LIVE AGENT NODE ============================================

    static LIVE_AGENT_NODE = {
        MESSAGE_AFTER_LIVE_AGENT_CONNECTED_EMPTY_ERROR: "Message after live agent connected text can't be empty.",

        MESSAGE_AFTER_LIVE_AGENT_CONNECTED_MAX_CHAR_ERROR: 'Message after live agent connected text should not exceed 1024 chracters.',

        DISCONNECT_KEYWORD_ERROR: 'Add disconnect keyword.',

        AGENT_NOT_AVAILABLE_MESSAGE_EMPTY_ERROR: "Agent not available body text can't be empty.",

        AGENT_NOT_AVAILABLE_BODY_TEXT_MAX_CHAR_ERROR: 'Agent not available body text should not exceed 1024 chracters.',

        CONFIRM_BUTTON_TEXT_EMPTY_ERROR: "Confirm button text can't be empty.",

        CANCEL_BUTTON_TEXT_EMPTY_ERROR: "Cancel button text can't be empty.",

        AFTER_CALLBACK_RECEIVED_BODY_TEXT_EMPTY_ERROR: "After callback is received body text can't be empty.",

        AFTER_CALLBACK_RECEIVED_BODY_TEXT_MAX_CHAR_ERROR: 'After callback is received body text should not exceed 1024 chracters.',

        CONNECT_BUTTON_TEXT_EMPTY_ERROR: "Connect button text can't be empty.",

        SKIP_BUTTON_TEXT_EMPTY_ERROR: "Skip button text can't be empty.",
    };

    //========================================= DOCUMENT COGNITION NODE ============================================

    static DOCUMENT_COGNITION_NODE = {
        INITIAL_MESSAGE_EMPTY_ERROR: 'Enter initial message.',

        SELECT_DOCUMENT_ERROR: 'Select document.',

        DISCONNECT_KEYWORD_ERROR: 'Add disconnect keywords.',
    };

    //========================================= RESPONSE CARD ============================================

    static RESPONSE_CARD = {
        VARIABLE_NOT_SELECTED: 'Please select response variable.',

        STATUS_CODE_NOT_SELECTED_ERROR: 'Please select Status Code.',

        CONTENT_TYPE_NOT_SELECTED_ERROR: 'Please select Content Type',
    };

    //========================================= BROADCAST NODE ============================================

    static BROADCAST_NODE = {
        CHANNELS_NOT_SELECTED_ERROR: 'At least one channel should be selected.',

        PAYLOAD_VARIABLE_NOT_SELECTED: 'Please select payload variable.',

        INTEGRATION_NOT_SELECTED_ERROR: integration => `Please select ${integration} integration.`,
    };

    //  ===========================================================================================================

    //========================================= REGISTER TRIGGER NODE ============================================

    static REGISTER_HTTP_TRIGGER_NODE = {
        HTTP_TRIGGER_NODE_ID_EMPTY_ERROR: 'Enter HTTP Trigger node Id.',
    };

    //  ===========================================================================================================

    //========================================= BROADCAST NODE ============================================

    static BROADCAST_SMS_NODE = {
        PAYLOAD_VARIABLE_NOT_SELECTED: 'Please select payload variable.',

        INTEGRATION_NOT_SELECTED_ERROR: `Please select SMS integration.`,
    };

    //  ===========================================================================================================

    // ==================================  SET_LOCALE NODE =========================================================

    static SET_LOCALE_NODE = {
        LOCALE_NOT_SELECTED: 'Please select a locale.',

        NOT_VALID_LOCALE: `Invalid locale.`,
    };

    // ==================================  WHATSAPP FLOWS NODE =========================================================

    static WHATSAPP_FLOWS_NODE = {
        BODY_TEXT_MAX_LIMIT_ERROR: `Body text should not exceed ${this.whatsAppChannelLimitations.whatsAppFlowsNode.bodyText} characters.`,

        HEADER_TEXT_MAX_LIMIT_ERROR: `Header text should not exceed ${this.whatsAppChannelLimitations.whatsAppFlowsNode.headerText} characters.`,

        FOOTER_TEXT_MAX_LIMIT_ERROR: `Footer text should not exceed ${this.whatsAppChannelLimitations.whatsAppFlowsNode.footerText} characters.`,

        OPEN_BUTTON_TEXT_MAX_LIMIT_ERROR: `Open button text should not exceed ${this.whatsAppChannelLimitations.whatsAppFlowsNode.openButtonText} characters.`,

        OPEN_BUTTON_TEXT_EMPTY_ERROR: "Open button text should not be empty.",

        BODY_TEXT_EMPTY_ERROR: "Body text should not be empty.",

        FLOW_NOT_SELECTED_ERROR: "Please select a flow.",

        FLOW_VARIABLE_ERROR: " variable should not be empty.",
    };

    static WHATSAPP_CTA_NODE = {
        BODY_TEXT_MAX_LIMIT_ERROR: `Body text should not exceed ${this.whatsAppChannelLimitations.whatsAppCTANode.bodyText} characters.`,

        HEADER_TEXT_MAX_LIMIT_ERROR: `Header text should not exceed ${this.whatsAppChannelLimitations.whatsAppCTANode.headerText} characters.`,

        FOOTER_TEXT_MAX_LIMIT_ERROR: `Footer text should not exceed ${this.whatsAppChannelLimitations.whatsAppCTANode.footerText} characters.`,

        TEXT_MAX_LIMIT_ERROR: `text should not exceed ${this.whatsAppChannelLimitations.whatsAppCTANode.text} characters.`,

        TEXT_EMPTY_ERROR: "CTA text should not be empty.",

        BODY_TEXT_EMPTY_ERROR: "Body text should not be empty.",

        CTA_URL_EMPTY_ERROR: "CTA url should not be empty.",

    };


    static WHATSAPP_TEMPLATE_NODE = {
        BODY_TEXT_MAX_LIMIT_ERROR: `Body text should not exceed ${this.whatsAppChannelLimitations.whatsAppTemplateNode.bodyText} characters.`,

        HEADER_TEXT_MAX_LIMIT_ERROR: `Header text should not exceed ${this.whatsAppChannelLimitations.whatsAppTemplateNode.headerText} characters.`,

        FOOTER_TEXT_MAX_LIMIT_ERROR: `Footer text should not exceed ${this.whatsAppChannelLimitations.whatsAppTemplateNode.footerText} characters.`,

        OPEN_BUTTON_TEXT_MAX_LIMIT_ERROR: `Open button text should not exceed ${this.whatsAppChannelLimitations.whatsAppTemplateNode.openButtonText} characters.`,

        OPEN_BUTTON_TEXT_EMPTY_ERROR: 'Open button text should not be empty.',

        BODY_TEXT_EMPTY_ERROR: 'Body text should not be empty.',

        CONTENT_DATA_ERROR: 'content data not found.',

        TEMPLATE_NOT_SELECTED_ERROR: 'Please select Whatsapp template.',

        TEMPLATE_TYPE_NOT_SELECTED_ERROR: 'Please select Whatsapp template type.',

        CARD_IMAGE_URL_ERROR: 'Please add card details.',

        TEMPLATE_DATA_BY_VARIABLE: 'Please add Whatsapp template data variable.',
    };

    static WHATSAPP_PAYMENT_NODE = {
        BODY_TEXT_MAX_LIMIT_ERROR: `Body text should not exceed ${this.whatsAppChannelLimitations.whatsAppPaymentNode.bodyText} characters.`,

        PAYMENT_CONFIGURATION_EMPTY_ERROR: `Please Select Payment Configuration`,
        ITEMS_EMPTY_ERROR: ` should not be empty`,

        PAYMENT_CONFIGURATION_LIMIT_ERROR: `Payment Configuration should not exceed ${this.whatsAppChannelLimitations.whatsAppPaymentNode.paymentConfiguration} characters.`,

        BODY_TEXT_EMPTY_ERROR: 'Body text should not be empty.',

        FAILURE_RESPONSE_EMPTY_ERROR: 'Failure response should not be empty.',

        SUCCESS_RESPONSE_EMPTY_ERROR: 'Success response should not be empty.',

        ITEM_TYPE_ERROR: 'type must be Number',

        ITEM_SALE_AMOUNT_ERROR: 'Item offer price value should be less than product MRP',

        EXPIRATION_TIME_ERROR: 'The expiration time should not be set to less than 5 minutes from the current time.',

        DESCRIPTION_ERROR_MESSAGE: 'Expiration description should not be empty',

        PAYMENT_ITEM_VARIABLE_ERROR_MESSAGE: 'Please Select Payment Item variable',

        PAYMENT_ITEM_MAX_LIMIT_ERROR: `Payment item should not exceed ${this.whatsAppChannelLimitations.whatsAppPaymentNode.itemName} characters.`,

        TIMESTAMP_DESCRIPTION_MAX_LIMIT_ERROR: `Timestamp description should not exceed ${this.whatsAppChannelLimitations.whatsAppPaymentNode.TimestampDescription} characters.`,

        DESCRIPTION_MAX_LIMIT_ERROR: `description should not exceed ${this.whatsAppChannelLimitations.whatsAppPaymentNode.description} characters.`,

        RESPONSE_MAX_LIMIT_ERROR: `should not exceed ${this.whatsAppChannelLimitations.whatsAppPaymentNode.responseText} characters.`,

        RETAILER_ID_MAX_LIMIT_ERROR: `Retailer id should not exceed ${this.whatsAppChannelLimitations.whatsAppPaymentNode.retailerId} characters.`,
    };

    static WHATSAPP_CATALOG_NODE = {
        BODY_TEXT_MAX_LIMIT_ERROR: `Body text should not exceed ${this.whatsAppChannelLimitations.whatsAppCatalogNode.bodyText} characters.`,
        HEADER_TEXT_MAX_LIMIT_ERROR: `Header text should not exceed ${this.whatsAppChannelLimitations.whatsAppCatalogNode.headerText} characters.`,
        FOOTER_TEXT_MAX_LIMIT_ERROR: `Footer text should not exceed ${this.whatsAppChannelLimitations.whatsAppCatalogNode.footerText} characters.`,
        BODY_TEXT_EMPTY_ERROR: "Body text should not be empty.",

        HEADER_TEXT_EMPTY_ERROR: "Header text should not be empty.",

        CATALOG_ID_EMPTY_ERROR: "Catalog Id should not be empty.",

        SECTION_DYNAMIC_EMPTY_ERROR: "Please select input variable.",
    };

    /* ===========================================================================================================
                                                 CHANNEL_VALIDATIONS
       =========================================================================================================== */

    static WHATSAPP_VALIDATIONS = {
        EMPTY_MESSAGE_TEXT_ERROR: "WhatsApp message text can't be empty.",

        MESSAGE_TEXT_MAX_CHARACTER_ERROR: `WhatsApp message text should not exceed ${this.whatsAppChannelLimitations.plainMessageTextNode.maxCharacterLength} characters.`,

        // CAROUSEL_NODE_NOT_SUPPORTED: 'WhatsApp channel does not support carousel node.',

        RCS_STAND_ALONE_NODE_NOT_SUPPORTED: 'WhatsApp channel does not support RCS interactive card.',

    };

    //========================================= TUBULU_VALIDATIONS =========================================

    static TUBULU_VALIDATIONS = {
        LIST_NODE_NOT_SUPPORTED: 'Tubulu channel does not support list node.',

        DYNAMIC_LIST_NOT_SUPPORTED: 'Tubulu channel does not support dynamic list node.',

        LOCATION_INPUT_NODE_NOT_SUPPORTED: 'Tubulu channel does not support location input node.',

        LOCATION_OUTPUT_NODE_NOT_SUPPORTED: 'Tubulu channel does not support location output node.',

        WHATSAPP_FLOWS_NODE_NOT_SUPPORTED: 'Tubulu channel does not support whatsapp flows node.',

        WHATSAPP_TEMPLATE_NODE_NOT_SUPPORTED: 'Tubulu channel does not support whatsapp template node.',

        WHATSAPP_CATALOG_NODE_NOT_SUPPORTED: 'Tubulu channel does not support whatsapp catalog node.',

        WHATSAPP_PAYMENT_NODE_NOT_SUPPORTED: 'Tubulu channel does not support whatsapp payment node.',

        RCS_STAND_ALONE_NODE_NOT_SUPPORTED: 'Tubulu channel does not support RCS interactive card.',

        WHATSAPP_CTA_NODE_NOT_SUPPORTED: 'Tubulu channel does not support whatsapp CTA node.',

    };

    //================================== FACEBOOK_MESSENGER_VALIDATIONS =========================================

    static FACEBOOK_MESSENGER_VALIDATIONS = {
        LIST_NODE_NOT_SUPPORTED: 'Facebook Messenger channel does not support list node.',

        DYNAMIC_LIST_NOT_SUPPORTED: 'Facebook Messenger channel does not support dynamic list node.',

        LOCATION_INPUT_NODE_NOT_SUPPORTED: 'Facebook Messenger channel does not support location input node.',

        LOCATION_OUTPUT_NODE_NOT_SUPPORTED: 'Facebook Messenger channel does not support location output node.',

        WHATSAPP_FLOWS_NODE_NOT_SUPPORTED: 'Facebook Messenger channel does not support whatsapp flows node.',

        WHATSAPP_TEMPLATE_NODE_NOT_SUPPORTED: 'Facebook Messenger channel does not support whatsapp template node.',

        WHATSAPP_CATALOG_NODE_NOT_SUPPORTED: 'Facebook channel does not support whatsapp catalog node.',

        WHATSAPP_PAYMENT_NODE_NOT_SUPPORTED: 'Facebook channel does not support whatsapp payment node.',

        RCS_STAND_ALONE_NODE_NOT_SUPPORTED: 'Facebook Messenger channel does not support RCS interactive card.',

        WHATSAPP_CTA_NODE_NOT_SUPPORTED: 'Facebook channel does not support whatsapp CTA node'

    };

    //========================================= INSTAGRAM_VALIDATIONS =========================================

    static INSTAGRAM_VALIDATIONS = {
        LIST_NODE_NOT_SUPPORTED: 'Instagram channel does not support list node.',

        DYNAMIC_LIST_NOT_SUPPORTED: 'Instagram channel does not support dynamic list node.',

        LOCATION_INPUT_NODE_NOT_SUPPORTED: 'Instagram channel does not support location input node.',

        LOCATION_OUTPUT_NODE_NOT_SUPPORTED: 'Instagram channel does not support location output node.',

        WHATSAPP_FLOWS_NODE_NOT_SUPPORTED: 'Instagram channel does not support whatsapp flows node.',

        WHATSAPP_TEMPLATE_NODE_NOT_SUPPORTED: 'Instagram channel does not support whatsapp template node.',

        WHATSAPP_CATALOG_NODE_NOT_SUPPORTED: 'Instagram channel does not support whatsapp catalog node.',

        WHATSAPP_PAYMENT_NODE_NOT_SUPPORTED: 'Instagram channel does not support whatsapp payment node.',

        RCS_STAND_ALONE_NODE_NOT_SUPPORTED: 'Instagram channel does not support RCS interactive card.',

        WHATSAPP_CTA_NODE_NOT_SUPPORTED: 'Instagram channel does not support whatsapp CTA node'

    };

    //========================================= TELEGRAM_VALIDATIONS =========================================

    static TELEGRAM_VALIDATIONS = {
        LIST_NODE_NOT_SUPPORTED: 'Telegram channel does not support list node.',

        DYNAMIC_LIST_NOT_SUPPORTED: 'Telegram channel does not support dynamic list node.',

        CAROUSEL_NODE_NOT_SUPPORTED: 'Telegram channel does not support carousel node.',

        WHATSAPP_FLOWS_NODE_NOT_SUPPORTED: 'Telegram channel does not support whatsapp flows node.',

        WHATSAPP_TEMPLATE_NODE_NOT_SUPPORTED: 'Telegram channel does not support whatsapp template node.',

        WHATSAPP_CATALOG_NODE_NOT_SUPPORTED: 'Telegram channel does not support whatsapp catalog node.',

        WHATSAPP_PAYMENT_NODE_NOT_SUPPORTED: 'Telegram channel does not support whatsapp payment node.',

        RCS_STAND_ALONE_NODE_NOT_SUPPORTED: 'Telegram channel does not support RCS interactive card.',

        WHATSAPP_CTA_NODE_NOT_SUPPORTED: 'Telegram channel does not support whatsapp CTA node'

    };

    //========================================= WEBCHAT_VALIDATIONS =========================================

    static WEBCHAT_VALIDATIONS = {
        LIST_NODE_NOT_SUPPORTED: 'Webchat channel does not support list node.',

        DYNAMIC_LIST_NOT_SUPPORTED: 'Webchat channel does not support dynamic list node.',

        CAROUSEL_NODE_NOT_SUPPORTED: 'Webchat channel does not support carousel node.',

        LOCATION_INPUT_NODE_NOT_SUPPORTED: 'Webchat channel does not support location input node.',

        LOCATION_OUTPUT_NODE_NOT_SUPPORTED: 'Webchat channel does not support location output node.',

        WHATSAPP_FLOWS_NODE_NOT_SUPPORTED: 'Webchat channel does not support whatsapp flows node.',

        WHATSAPP_CTA_NODE_NOT_SUPPORTED: 'Webchat channel does not support  whatsapp CTA node.',

        WHATSAPP_TEMPLATE_NODE_NOT_SUPPORTED: 'Webchat channel does not support whatsapp template node.',

        WHATSAPP_CATALOG_NODE_NOT_SUPPORTED: 'Webchat channel does not support whatsapp catalog node.',

        WHATSAPP_PAYMENT_NODE_NOT_SUPPORTED: 'Webchat channel does not support whatsapp payment node.',

        RCS_STAND_ALONE_NODE_NOT_SUPPORTED: 'Webchat channel does not support RCS interactive card.',

    };

    //===========================================================================================================

    //====================================BROADCAST_WITH_RABBITMQ===========================================================
    static BROADCAST_WITH_RABBITMQ_NODE = {
        CHANNELS_NOT_SELECTED_ERROR: 'At least one channel should be selected.',

        PAYLOAD_VARIABLE_NOT_SELECTED: 'Please select payload variable.',

        INTEGRATION_NOT_SELECTED_ERROR: integration => `Please select ${integration} integration.`,

        WEBHOOKCONFIG_TYPE_NOT_SELECTED_ERROR: `Please select the type of webhook configration`,

        WEBHOOKCONFIG_TYPE_SELECTED_IS_STATIC_ERROR: `Please enter the static url for webhook config`,

        WEBHOOKCONFIG_AUTHENTICATION_TYPE_NOT_SELECTED_ERROR: `Please select the type of webhook authentication`,

        AUTHENTICATION_FEILD_EMPTY_ERROR: (authtype, feildName) => `Please enter the ${feildName} for ${authtype} authentication`,

        BEARER_AUTHENTICATION_TYPE_NOT_SELECTED_ERROR: `Please select the token type for bearer authentication`,

        ANY_FEILD_EMPTY_ERROR: (following, feildName) => `Please enter the ${feildName} for the ${following}`,

        NOT_A_VALID_URL_ERROR: feildName => `Please enter the valid url for ${feildName}`,

        AUTHENTICATION_FEILD_NOT_SELECTED_ERROR: (authtype, fieldName) => `Please select the ${fieldName} for ${authtype} authentication`,
    };
    //==================================== ZOHO CRM NODE  ===========================================================
    static ZOHO_CRM = {
        CHANNELS_NOT_SELECTED_ERROR: 'At least one channel should be selected.',

        FIELDS_CAN_NOT_EMPTY: 'Fields can not be empty!',

        OUTPUT_VARIABLE_NOT_DEFINED: 'Output variable must be selected.',
    };


    //==============================================RCS Node Validation=====================================================


    static RCS_VALIDATIONS = {
        EMPTY_MESSAGE_TEXT_ERROR: "RCS message text can't be empty.",

        MESSAGE_TEXT_MAX_CHARACTER_ERROR: `RCS message text should not exceed ${this.rcsChannelLimitations.plainMessageTextNode.maxCharacterLength} characters.`,

        CAROUSEL_NODE_NOT_SUPPORTED: 'RCS channel does not support carousel node.',

        RCS_QUESTION_CARD_ERROR: `RCS question card text should not exceed ${this.rcsChannelLimitations.plainMessageTextNode.maxCharacterLength} characters`,

        // RCS_MEDIA_INPUT_CARD_INSTRUCTION_ERROR: `RCS media input card instruction should not exceed ${this.rcsChannelLimitations.mediaInputNode.instruction} characters`,

        MEDIA_OUTPUT_CARD_EMPTY_CAPTION_ERROR: `Caption is required in rcs`,

        QUESTION_CARD_EMPTY_ERROR: `Question node text can't be empty`,

        LIST_NODE_NOT_SUPPORTED: 'RCS channel does not support list node.',

        DYNAMIC_LIST_NOT_SUPPORTED: 'RCS channel does not support dynamic list node.',

        WHATSAPP_FLOWS_NODE_NOT_SUPPORTED: 'RCS channel does not support whatsapp flows node.',

        WHATSAPP_TEMPLATE_NODE_NOT_SUPPORTED: 'RCS channel does not support whatsapp template node.',

        WHATSAPP_CATALOG_NODE_NOT_SUPPORTED: 'RCS channel does not support whatsapp catalog node.',

        WHATSAPP_PAYMENT_NODE_NOT_SUPPORTED: 'RCS channel does not support whatsapp payment node.',

        LOCATION_OUTPUT_NODE_NOT_SUPPORTED: 'RCS channel does not support location output node.',

        LOCATION_INPUT_NODE_NOT_SUPPORTED: 'RCS channel does not support location input node.',

        WHATSAPP_CTA_NODE_NOT_SUPPORTED: 'RCS channel does not support whatsapp CTA node',

        RCS_ATLEAST_TWO_CAROUSELS: `RCS requires a minimum of 2 carousel cards.`,

        RCS_MAX_CAROUSELS_VALIDATION: `RCS supports a maximum of 10 carousel cards.`,


    };

    static CACHE_NODE = {
        CACHE_KEY_EMPTY_ERROR: `Cache key cannot be empty`,
        CACHE_VALUE_EMPTY_ERROR: `Cache value cannot be empty`,
        INVALID_CACHE_TTL_VALUE: `Invalid ttl value for the cache`,
        CACHE_TTL_MAX_VALUE_EXCEED: `TTL value cannot be greater than ${ECacheNode.Max_TTL_Value} seconds`,
        CACHE_TTL_MIN_VALUE_EXCEED: `TTL value cannot be less than ${ECacheNode.Min_TTL_Value} seconds`
    }



}