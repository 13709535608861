
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    integrations: {
        smsIntegrations: [],
        emailIntegrations: [],
        whatsappIntegrations: [],
    },


};

export const rabbitMqIntegrationSlice = createSlice({
    name: "rabbitMqIntegrations",
    initialState,
    reducers: {
        SetRabbitMqIntegrations: (state, action) => {
            state.integrations = action.payload;

        },

        ResetRabbitMqIntegrations: (state, action) => {
            state.integrations = {
                smsIntegrations: [],
                emailIntegrations: [],
                whatsappIntegrations: []
            }
        },


    }
});

export const { SetRabbitMqIntegrations, ResetRabbitMqIntegrations } = rabbitMqIntegrationSlice.actions;

export default rabbitMqIntegrationSlice.reducer;

