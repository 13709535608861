export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  redirectUrl: process.env.REACT_APP_AUTH_REDIRECT_URL,
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

export const appConfig = {
  appServerUrl: process.env.REACT_APP_SERVER_URL,
  builderServerUrl: process.env.REACT_APP_BUILDER_SERVER_URL,
  appNotificationServerUrl: process.env.REACT_APP_NOTIFICATION_SERVER_URL,
  whatsAppTestSimulatorUrl: process.env.REACT_APP_WHATSAPP_SIMULATOR_URL,
  subscriptionServiceUrl: process.env.REACT_APP_SUBSCRIPTION_URL,
  utilityServiceBridgeUrl: process.env.REACT_APP_UTILITY_SERVICE_BRIDGE_URL,
  utilityServiceBridgeApiKey:
    process.env.REACT_APP_UTILITY_SERVICE_BRIDGE_API_KEY,

  // Process Automation Service URL
  processAutomationServiceUrl:
    process.env.REACT_APP_PROCESS_AUTOMATION_SERVICE_URL,

  // Trigger Observer Microservice URL
  TRIGGER_MICROSERVICE_URL: process.env.REACT_APP_TRIGGER_MICROSERVICE_URL,

  // Reporting Server URL
  reportingAndAlertServerUrl:
    process.env.REACT_APP_REPORTING_AND_ALERT_SERVER_URL,

    // Workflow Editor Iteration Guard Max Iteration Count 
    iterationGuardMaxIterationCount: process.env.REACT_APP_ITERATION_GUARD_MAX_ITERATION_COUNT,
   
};

export const EWhatsAppAPIVersion = {
  v1: "partners_v1",
  v3: "partners_v3",
};

export const EButtonType = {
  Text: "text",
  URL: "url",
};

export const ECacheNode = {
  //values are in seconds
  Min_TTL_Value: 60,
  Max_TTL_Value: 18000
}
