export const reports_key = {
    error_key: {
        "NODE_TYPE": {
            key: "NODE_TYPE",
            field: {
                message: []
            }
        },
        "NODE_CONTENT": {
            key: "NODE_CONTENT",
            field: {
                message: []
            }
        },
        "NODE_DESCRIPTION": {
            key: "NODE_DESCRIPTION",
            field: {
                message: []
            }
        },
        "OUTPUT_VARIABLE": {
            key: "OUTPUT_VARIABLE",
            field: {
                message: []
            }
        },
        "VARIABLE_NOT_DEFINED": {
            key: "VARIABLE_NOT_DEFINED",
            field: {
                message: []
            }
        },
        "INPUT_VALIDATION": {
            key: "INPUT_VALIDATION",
            field: {
                message: []
            }
        },
        "INPUT_TIMEOUT_EDGE": {
            key: "INPUT_VALIDATION",
            field: {
                message: []
            }
        },
    },
    warning_key: {
        "HANGING_NODE": {
            key: "HANGING_NODE",
            field: {
                message: []
            }
        },
        "VARIABLE_NOT_FOUND": {
            key: "VARIABLE_NOT_FOUND",
            field: {
                message: []
            }
        }
    }
}