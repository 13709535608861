import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { uuid } from 'short-uuid'
import axiosInstance from '../../utils/workflowAxios'


const deleteDynamicVariableFromIfScreen = (state, ifChildren, name, ifComponentIndex, thenElse) => {
    try {
        if (ifChildren) {
            const childrens = JSON.parse(JSON.stringify(ifChildren));
            for (let IfIndex = 0; IfIndex < childrens.length; IfIndex++) {

                if (childrens[IfIndex].componentData.type === 'If') {
                    const nestedIfComponentIndex = state.flowJson && state.flowJson.ifScreens.findIndex((ifData) => ifData.u_id === childrens[IfIndex].u_id);
                    if (nestedIfComponentIndex !== -1) {
                        const nestedIfScreenData = state.flowJson && state.flowJson.ifScreens[nestedIfComponentIndex];
                        deleteDynamicVariableFromIfScreen(state, nestedIfScreenData.then[0].childrens, name, nestedIfComponentIndex, "then")
                        deleteDynamicVariableFromIfScreen(state, nestedIfScreenData.else[0].childrens, name, nestedIfComponentIndex, "else")
                    }
                } else {
                    const newObject = JSON.parse(
                        JSON.stringify(childrens[IfIndex].componentData.value)
                    )
                    for (const key in newObject) {
                        const dataVariableToDelete =
                            (newObject[key] instanceof String ||
                                typeof newObject[key] === 'string') &&
                            newObject[key].includes('${data.') &&
                            newObject[key].split('${data.')[1].split('}')[0]

                        if (dataVariableToDelete === name) {
                            newObject[key] = null
                        }
                    }
                    childrens[IfIndex].componentData.value = newObject
                }
            }
            state.flowJson.ifScreens[ifComponentIndex][thenElse][0].childrens = childrens
            state.ifScreenSimulator.ifComponents[ifComponentIndex][thenElse][0].childrens = childrens
        }
    } catch (error) {
        console.log("Error Deleting dynamic variable from if screen", error)
    }

}

const deleteInitValueOnDeleteOption = (state, initValue, dataSourceObject, componentType, index, id) => {
    // Check if init value contain the deleting option, if yes then remove the option from init values.

    if (initValue && initValue.value) {
        if (componentType === "CheckboxGroup" &&
            Array.isArray(initValue.value) && initValue.value.includes(dataSourceObject[index].id)
        ) {
            const updatedInitValues = initValue.value.filter((variable) => variable !== dataSourceObject[index].id);
            if (updatedInitValues) {
                initValue.value = updatedInitValues;
            }
        } else {
            // DELETE ID FROM RADIO BUTTON AND DROPDOWN COMPONENT
            if (initValue && Object.keys(initValue) && initValue.value) {
                if (id === initValue.value) {
                    // in-it values is exist then then empty then remove in
                    initValue.value = "";
                }
            }
        }
    }
}

const deleteNestedIfScreenComponent = (state, componentId) => {
    try {
        const ifComponent = state.flowJson?.ifScreens?.find(
            screen => screen.u_id === componentId
        )
        for (let index = 0; index < ifComponent.then[0].childrens.length; index++) {
            const element = ifComponent.then[0].childrens[index];
            if (element.type === "If") {
                deleteNestedIfScreenComponent(state, element.u_id)
            }
            // same for switch
        }
        for (let index = 0; index < ifComponent.else[0].childrens.length; index++) {
            const element = ifComponent.else[0].childrens[index];
            if (element.type === "If") {
                deleteNestedIfScreenComponent(state, element.u_id)
            }
            // same for switch
        }

        // find with current component u_id
        const ifComponentIdIndex = state.flowJson?.ifScreens?.findIndex(
            screen => screen.u_id === componentId
        )

        state.flowJson.ifScreens.splice(ifComponentIdIndex, 1)

        const indexToDeleteFromIfComponentSimulator =
            state.ifScreenSimulator.ifComponents?.findIndex(
                screen => screen.u_id === componentId
            )
        state.ifScreenSimulator.ifComponents.splice(
            indexToDeleteFromIfComponentSimulator,
            1
        )
    } catch (error) {
        console.log(error)
    }
}


const findCurrentScreenIndex = (state, screen) => {
    return state.flowJson.TemporaryFlowJsonScreens.findIndex(
        component => component.id === screen.id
    )
}

const findChildIndex = (state, screenIndex, parentComponentData) => {
    return state.flowJson.TemporaryFlowJsonScreens[
        screenIndex
    ].layout.childrens.findIndex(
        components => components.u_id === parentComponentData.u_id
    )
}

const updateTemporaryFlowJson = (
    state,
    screenIndex,
    childIndex,
    dataToUpdate
) => {
    if (dataToUpdate['center-caption'] && dataToUpdate['center-caption'] !== '') {
        delete dataToUpdate['right-caption']
        delete dataToUpdate['left-caption']
    } else if (!dataToUpdate['center-caption']) {
        delete dataToUpdate['center-caption']
    }
    return (state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout.childrens[
        childIndex
    ].componentData.value = dataToUpdate)
}

const updateSimulatorJson = (state, screenIndex, childIndex, dataToUpdate) => {
    state.simulator.screenData[screenIndex].layout.childrens[
        childIndex
    ].componentData.value = dataToUpdate
}

const getComponentData = (state, screenIndex, childIndex) => {
    return state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout.childrens[
        childIndex
    ].componentData.value
}

const getComponentDataForConditionalComponentScreen = (
    state,
    ifComponentIndex,
    childIndex
) => {
    //currently working for if component only
    if (state.openConditionComponentScreen.success) {
        return state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
            childIndex
        ].componentData.value
    } else {
        return state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
            childIndex
        ].componentData.value
    }
}

const getComponentDataForConditionalComponentScreenComponentData = (
    state,
    ifComponentIndex,
    childIndex
) => {
    //currently working for if component only
    if (state.openConditionComponentScreen.success) {
        return state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
            childIndex
        ].componentData
    } else {
        return state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
            childIndex
        ].componentData
    }
}

const getComponentDataForSwitch = (
    state,
    ifComponentIndex,
    caseIndex,
    childIndex
) => {
    return state.flowJson.ifScreens[ifComponentIndex].cases[caseIndex].childrens[
        childIndex
    ].componentData
}

const findConditionalComponentIndex = (state, screen) => {
    //currently working for if only
    return state.flowJson.ifScreens.findIndex(
        screen => screen.u_id === state.openConditionComponentScreen.item.u_id
    )
}

const updateConditionalComponentScreensJson = (
    state,
    ifComponentIndex,
    childIndex,
    dataToUpdate
) => {
    if (dataToUpdate['center-caption'] && dataToUpdate['center-caption'] !== '') {
        delete dataToUpdate['right-caption']
        delete dataToUpdate['left-caption']
    } else if (!dataToUpdate['center-caption']) {
        delete dataToUpdate['center-caption']
    }

    //currently working for if component
    if (state.openConditionComponentScreen.success) {
        return (state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
            childIndex
        ].componentData.value = dataToUpdate)
    } else {
        return (state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
            childIndex
        ].componentData.value = dataToUpdate)
    }
}

const findChildIndexForConditionalComponentScreen = (
    state,
    ifComponentId,
    parentComponentData
) => {
    //currently working for if component only
    if (state.openConditionComponentScreen.success) {
        return state.flowJson.ifScreens[ifComponentId].then[0].childrens.findIndex(
            component => component.u_id === parentComponentData.u_id
        )
    } else {
        return state.flowJson.ifScreens[ifComponentId].else[0].childrens.findIndex(
            component => component.u_id === parentComponentData.u_id
        )
    }
}
// ============================================================ SWITCH-CONDITION ======================================
const findChildrenIndexForSwitch = (
    state,
    conditionIndex,
    parentComponentData,
    caseIndex
) => {
    //currently working for if component only
    return state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens.findIndex(
        component => component.u_id === parentComponentData.u_id
    )
}
const findCaseIndex = (state, conditionIndex) => {
    return state.flowJson?.ifScreens && state.flowJson?.ifScreens[conditionIndex].cases.findIndex((element) => element.caseId === state.openConditionComponentScreen.caseId)
}


const getComponentDataForConditionalComponentsSwitch = (
    state,
    conditionIndex,
    caseIndex,
    childIndex,
) => {
    //currently working for if component only
    return state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
        childIndex
    ].componentData.value
}

const updateConditionalComponentScreensJsonSwitch = (
    state,
    conditionIndex,
    caseIndex,
    childIndex,
    dataToUpdate
) => {
    if (dataToUpdate['center-caption'] && dataToUpdate['center-caption'] !== '') {
        delete dataToUpdate['right-caption']
        delete dataToUpdate['left-caption']
    } else if (!dataToUpdate['center-caption']) {
        delete dataToUpdate['center-caption']
    }
    //currently working for if component
    return (state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
        childIndex
    ].componentData.value = dataToUpdate)
}

const updateSimulatorJsonForConditionalComponentSwitch = (
    state,
    conditionIndex,
    caseIndex,
    childIndex,
    dataToUpdate
) => {
    state.ifScreenSimulator.ifComponents[conditionIndex].cases[caseIndex].childrens[
        childIndex
    ].componentData.value = dataToUpdate
}


// ============================================================ SWITCH ======================================


const getIfComponentDataFromIfScreens = state => {
    const currentIfComponentId = state.openConditionComponentScreen.item.u_id
    const parentData = state.flowJson.ifScreens.filter(
        item => item.u_id === currentIfComponentId
    )

    const item = state.flowJson.ifScreens.filter(
        data => data.u_id === parentData.u_id
    )
    return parentData
}

const getOutputVariablesForCurrentScreen = state => {
    const currentWorkingScreenId = state.screen.currentWorkingScreen.id

    const currentWorkingScreenIndex = state.screen.allScreens.findIndex(
        screenData => screenData.id === currentWorkingScreenId
    )
    let filteredObjectsFromOutputVariables = []
    for (let index = 0; index < state.screen.allScreens.length; index++) {
        const element = state.screen.allScreens[index]
        if (index > currentWorkingScreenIndex) break

        const tempArray = state.flowJson.outputVariable.filter(
            item => item.screenId === element.id
        )
        if (tempArray.length > 0)
            filteredObjectsFromOutputVariables = [
                ...filteredObjectsFromOutputVariables,
                ...tempArray
            ]
    }

    return filteredObjectsFromOutputVariables
}

const updateSimulatorJsonForConditionalComponentScreens = (
    state,
    screenIndex,
    childIndex,
    dataToUpdate
) => {
    if (state.openConditionComponentScreen.success) {
        state.ifScreenSimulator.ifComponents[screenIndex].then[0].childrens[
            childIndex
        ].componentData.value = dataToUpdate
    } else {
        state.ifScreenSimulator.ifComponents[screenIndex].else[0].childrens[
            childIndex
        ].componentData.value = dataToUpdate
    }
}

const findSwitchComponentIndex = state => {
    return state.flowJson?.switchCasesScreen?.findIndex(
        screen => screen.u_id === state.showPropertyWindowTab.value.u_id
    )
}
const initialStates = {
    flow: {
        flowId: '',
        categories: '',
        workflowJson: {},
        status: '',
        flowName: '',
        createdAt: '',
        screens: [],
        whatsAppIntegrationId: ''
    },
    disableComponentsForPublishFlow: false,

    screen: {
        currentWorkingScreen: null,
        allScreens: []
    },

    flowJson: {
        TemporaryFlowJsonScreens: [],
        dataChannelUri: null,
        finalFlowJson: null,
        flowJsonToShow: null,
        showJsonModal: false,
        outputVariable: [],
        ifScreens: [],
        switchCasesScreen: []
    },

    showPropertyWindowTab: {
        state: false,
        item: ''
    },

    openConditionComponentScreen: {
        state: false,
        success: false, //if state and success is true then this will work for success, if state is true and success is false it will work for failure
        item: {},
        type: null,
        caseName: null, // for switch component 
        caseId: null,
        isSwitchComponent: false,
    },

    simulator: {
        screenData: []
    },

    ifScreenSimulator: {
        ifComponents: [],
    },


    updatedFlowData: null,

    completeActionInFooterButtonExist: false,

    flowsListingData: {
        flows: [],
        page: 1,
        searchText: '',
        totalCount: 0,
        pageSize: 4,
        categories: [],
        whatsappChannelIntegrations: []
    },

    dropDownOptionslist: [],

    // CallDataChannelUrlOnClicked: {
    //     state: false,
    //     uuid: ''
    // },
    // CallDataChannelUrlOnClickeddropdown: {
    //     state: false,
    //     uuid: ''
    // },
    // CallDataChannelUrlOnClickedCheckBox: {
    //     state: false,
    //     uuid: ''
    // },
    // CallDataChannelUrlOnClickedDatePicker: {
    //     state: false,
    //     uuid: ''
    // },


    // ifConditionBlockForPropertyWindow: [
    //     // {
    //     //   u_id: null,
    //     //   conditionBlockValues: []
    //     // }
    // ],
    // Output variable and dynamic variable of current screen.
    // outputAndDynamicVariables: []
}

const whatsAppFlowSlice = createSlice({
    name: 'whatsAppFlowSlice',

    initialState: initialStates,

    reducers: {
        resetAllStates: () => initialStates,

        /**
         * @typedef { Array<{ value:any, name: 'flows' | 'page' | 'searchText' | 'totalCount' | 'categories' | 'whatsappChannelIntegrations' }> } TActionPayload
         * @param {{ type:string, payload:TActionPayload }} action
         */
        setFlowListingData: (state, action) => {
            const actionPayload = action.payload
            const newState = {}
            if (actionPayload.length > 0) {
                for (let index = 0; index < actionPayload.length; index++) {
                    const element = actionPayload[index]
                    newState[element.name] = element.value
                }
            }
            state.flowsListingData = { ...state.flowsListingData, ...newState }
        },

        deleteDynamicVariableInSlice: (state, action) => {
            const { name, value } = action.payload
            const flowJson = state.flowJson.TemporaryFlowJsonScreens
            for (let index = 0; index < flowJson.length; index++) {
                const element = flowJson[index]
                if (element.id === value) {
                    //value is a screen name
                    //delete varaible from data.variable
                    const variabledata = element.data.variables
                    if (
                        state.flowJson.TemporaryFlowJsonScreens[index].data.variables
                            .length === 1
                    ) {
                        state.flowJson.TemporaryFlowJsonScreens[index].data = {}
                    } else {
                        const newArray = variabledata.filter(obj => {
                            if (obj.name !== name) {
                                return obj
                            }
                        })
                        let tempJson = JSON.parse(
                            JSON.stringify(
                                state.flowJson.TemporaryFlowJsonScreens[index].data
                            )
                        )
                        tempJson.variables = newArray
                        state.flowJson.TemporaryFlowJsonScreens[index].data = tempJson
                    }

                    const childrenData = JSON.parse(
                        JSON.stringify(element.layout.childrens)
                    )

                    //delete varaible actually used in flowJson

                    for (let index = 0; index < childrenData.length; index++) {

                        if (childrenData[index].componentData.type === "If") {
                            // Added logic to delete dynamic variable from children 
                            const ifComponentIndex = state.flowJson && state.flowJson.ifScreens.findIndex((ifData) => ifData.u_id === childrenData[index].u_id);
                            const ifScreenData = state.flowJson && state.flowJson.ifScreens[ifComponentIndex];
                            deleteDynamicVariableFromIfScreen(state, ifScreenData.then[0].childrens, name, ifComponentIndex, "then")
                            deleteDynamicVariableFromIfScreen(state, ifScreenData.else[0].childrens, name, ifComponentIndex, "else")
                        } else {
                            const newObject = JSON.parse(
                                JSON.stringify(childrenData[index].componentData.value)
                            )

                            for (const key in newObject) {

                                const dataVaraibleToDelete =
                                    (newObject[key] instanceof String ||
                                        typeof newObject[key] === 'string') &&
                                    newObject[key].includes('${data.') &&
                                    newObject[key].split('${data.')[1].split('}')[0]

                                if (dataVaraibleToDelete === name) {
                                    newObject[key] = null
                                }
                            }
                            childrenData[index].componentData.value = newObject
                        }


                        // state.flowJson.TemporaryFlowJsonScreens[index].layout.childrens[
                        //   index
                        // ].componentData.value = newObject;
                    }


                    state.flowJson.TemporaryFlowJsonScreens[index].layout.childrens =
                        childrenData
                    state.simulator.screenData[index].layout.childrens = childrenData


                }
            }
            state.showPropertyWindowTab = {
                state: false,
                item: ''
            }
        },
        // deleteDynamicVariableInSlice: (state, action) => {
        //     const { name, value } = action.payload
        //     const flowJson = state.flowJson.TemporaryFlowJsonScreens
        //     for (let index = 0; index < flowJson.length; index++) {
        //         const element = flowJson[index]
        //         if (element.id === value) {
        //             //value is a screen name
        //             //delete varaible from data.variable
        //             const variabledata = element.data.variables
        //             if (
        //                 state.flowJson.TemporaryFlowJsonScreens[index].data.variables
        //                     .length === 1
        //             ) {
        //                 state.flowJson.TemporaryFlowJsonScreens[index].data = {}
        //             } else {
        //                 const newArray = variabledata.filter(obj => {
        //                     if (obj.name !== name) {
        //                         return obj
        //                     }
        //                 })
        //                 let tempJson = JSON.parse(
        //                     JSON.stringify(
        //                         state.flowJson.TemporaryFlowJsonScreens[index].data
        //                     )
        //                 )
        //                 tempJson.variables = newArray
        //                 state.flowJson.TemporaryFlowJsonScreens[index].data = tempJson
        //             }

        //             const childrenData = JSON.parse(
        //                 JSON.stringify(element.layout.childrens)
        //             )

        //             //delete varaible actually used in flowJson

        //             for (let index = 0; index < childrenData.length; index++) {
        //                 const newObject = JSON.parse(
        //                     JSON.stringify(childrenData[index].componentData.value)
        //                 )
        //                 for (const key in newObject) {

        //                     const dataVaraibleToDelete =
        //                         (newObject[key] instanceof String ||
        //                             typeof newObject[key] === 'string') &&
        //                         newObject[key].includes('${data.') &&
        //                         newObject[key].split('${data.')[1].split('}')[0]

        //                     if (dataVaraibleToDelete === name) {
        //                         newObject[key] = null
        //                     }
        //                 }
        //                 childrenData[index].componentData.value = newObject
        //                 // state.flowJson.TemporaryFlowJsonScreens[index].layout.childrens[
        //                 //   index
        //                 // ].componentData.value = newObject;
        //             }

        //             state.flowJson.TemporaryFlowJsonScreens[index].layout.childrens =
        //                 childrenData
        //             state.simulator.screenData[index].layout.childrens = childrenData
        //         }
        //     }
        //     state.showPropertyWindowTab = {
        //         state: false,
        //         item: ''
        //     }
        // },

        updateFlowName: (state, action) => {
            const { flowName } = action.payload
            state.flow.flowName = flowName
        },

        setFlowAndScreenData: (state, action) => {
            const {
                flowId,
                flowName,
                categories,
                createdAt,
                status,
                workflowJson,
                screens,
                dataChannelComponent,
                outputVariable,
                ifScreens,
                ifScreenSimulator,
                whatsapp_pinnacle_bsp_integration
            } = action.payload
            state.flow.flowId = flowId
            state.flow.flowName = flowName
            state.flow.categories = categories
            state.flow.createdAt = createdAt
            state.flow.status = status
            state.flowJson.TemporaryFlowJsonScreens = workflowJson
            state.simulator.screenData = workflowJson
            state.flowJson.dataChannelUri = dataChannelComponent
            state.flow.screens = screens
            state.screen.allScreens = screens
            state.screen.currentWorkingScreen = screens.find(
                screen => screen.screenIndex === 0
            )
            state.flowJson.outputVariable = outputVariable ?? []
            state.flowJson.ifScreens = ifScreens ?? []
            state.ifScreenSimulator.ifComponents = (ifScreenSimulator && ifScreenSimulator.ifComponents) ?? []
            state.flow.whatsAppIntegrationId = whatsapp_pinnacle_bsp_integration
        },

        setFlowNameAndCategory: (state, action) => {
            const { flowName, categories, name } = action.payload
            switch (name) {
                case 'flowName':
                    state.controls.flowName = flowName
                    break
                case 'categories':
                    state.controls.categories = categories
                    break
                default:
                    break
            }
        },

        //  ------------------------------------------------ Screens ----------------------------------------------------------------

        setNewScreen: (state, action) => {
            const { screenName, screenIndex } = action.payload

            const payloadToAddInAllScreen = {
                title: screenName,
                id: screenName.trim().toUpperCase().split(' ').join('_'),
                screenIndex: screenIndex
            }

            const payloadToAddInTemporaryFlowJson = {
                id: payloadToAddInAllScreen.id,
                title: payloadToAddInAllScreen.title,
                data: {},
                layout: { type: 'SingleColumnLayout', childrens: [] }
            }

            const payloadToAddForSimulatorScreenData = {
                id: payloadToAddInAllScreen.id,
                title: payloadToAddInAllScreen.title,
                data: {},
                layout: { type: 'SingleColumnLayout', childrens: [] }
            }

            state.simulator.screenData.push(payloadToAddForSimulatorScreenData)

            state.screen.allScreens.push(payloadToAddInAllScreen)
            state.flowJson.TemporaryFlowJsonScreens.push(
                payloadToAddInTemporaryFlowJson
            )
            // state.modals.showCreateScreenModal = false;
            state.screen.currentWorkingScreen = state.screen.allScreens[screenIndex]
            state.showPropertyWindowTab = {
                state: false,
                item: ''
            }
        },

        deleteScreen: (state, action) => {
            const allScreen = []
            const { screenIndex, currentScreenData } = action.payload
            const tempFlowIndexToDelete = findCurrentScreenIndex(
                state,
                currentScreenData
            )
            state.screen.allScreens.splice(screenIndex, 1)
            state.flowJson.TemporaryFlowJsonScreens.splice(tempFlowIndexToDelete, 1)
            state.simulator.screenData.splice(tempFlowIndexToDelete, 1)
            state.screen.allScreens.map((screenData, index) => {
                const screenObject = {
                    ...screenData,
                    screenIndex: index
                }
                allScreen.push(screenObject)
            })
            state.screen.allScreens = allScreen
            state.screen.currentWorkingScreen = state.screen.allScreens[0]
            state.showPropertyWindowTab = {
                state: false,
                item: ''
            }

            // Delete Output variable-> find with screen Id & delete Output Variable
            if (
                state.flowJson.outputVariable &&
                Array.isArray(state.flowJson.outputVariable)
            ) {
                const updatedOutputVariable = state.flowJson.outputVariable.filter(
                    key => key.screenId !== currentScreenData.id
                )
                state.flowJson.outputVariable = updatedOutputVariable
            }
        },

        changeCurrentFlowScreen: (state, action) => {
            const screenData = action.payload
            state.screen.currentWorkingScreen = screenData
            state.showPropertyWindowTab = {
                state: false,
                item: ''
            }
        },

        onChangeScreenDrop: (state, action) => {
            const {
                currentScreenIndex,
                dropingScreenIndex,
                screenData,
                currentScreenData
            } = action.payload

            state.screen.allScreens.splice(currentScreenIndex, 1)
            state.flowJson.TemporaryFlowJsonScreens.splice(currentScreenIndex, 1)
            state.simulator.screenData.splice(currentScreenIndex, 1)

            const indexToAddTheScreenData =
                currentScreenIndex > dropingScreenIndex
                    ? dropingScreenIndex + 1
                    : dropingScreenIndex

            state.screen.allScreens.splice(
                dropingScreenIndex === -1
                    ? dropingScreenIndex + 1
                    : indexToAddTheScreenData,
                0,
                screenData
            )
            state.flowJson.TemporaryFlowJsonScreens.splice(
                dropingScreenIndex === -1
                    ? dropingScreenIndex + 1
                    : indexToAddTheScreenData,
                0,
                currentScreenData
            )
            state.simulator.screenData.splice(
                dropingScreenIndex === -1
                    ? dropingScreenIndex + 1
                    : indexToAddTheScreenData,
                0,
                currentScreenData
            )

            state.showPropertyWindowTab = {
                state: false,
                item: ''
            }


            // change screen index 
            const updateScreenArray = [];
            for (let index = 0; index < state.screen.allScreens.length; index++) {
                const element = state.screen.allScreens[index];
                const updateScreen = {
                    ...element,
                    screenIndex: index
                }
                updateScreenArray.push(updateScreen);

            };
            state.screen.allScreens = updateScreenArray
            // find current working screen and update the screen index.

            const currentWorkingScreenId = state.screen.currentWorkingScreen.id

            const findCurrentScreenIndexById = updateScreenArray.find((screenId) => screenId.id === currentWorkingScreenId);

            // update screen index in current working screen
            state.screen.currentWorkingScreen = { ...state.screen.currentWorkingScreen, screenIndex: findCurrentScreenIndexById.screenIndex };


            // //for setting outputAndDynamicVariables after screen dragDrop
            // const filteredObjectsFromOutputVariables =
            //   getOutputVariablesForCurrentScreen(state)
            // if (filteredObjectsFromOutputVariables.length > 0) {
            //   state.outputAndDynamicVariables = [
            //     ...filteredObjectsFromOutputVariables
            //   ]
            // }

            // if (
            //   state.flowJson.TemporaryFlowJsonScreens[
            //     state.screen.currentWorkingScreen.screenIndex
            //   ]?.data &&
            //   state.flowJson.TemporaryFlowJsonScreens[
            //     state.screen.currentWorkingScreen.screenIndex
            //   ]?.data.variables
            // )
            //   //merging dynamic variables and output variables together
            //   state.outputAndDynamicVariables = [
            //     ...state.outputAndDynamicVariables,
            //     ...state.flowJson.TemporaryFlowJsonScreens[
            //       state.screen.currentWorkingScreen.screenIndex
            //     ]?.data.variables
            //   ]
        },

        // -----------------------------------------------  Flow Json ----------------------------------------------------------------
        saveFlowComponentValueToTemporaryFlowJson: (state, action) => {
            const { componentData, index, currentScreen } = action.payload

            const indexOfScreen = findCurrentScreenIndex(state, currentScreen)

            updateTemporaryFlowJson(state, indexOfScreen, index, componentData)
        },

        addComponentToTemporaryFlowComponents: (state, action) => {
            const { componentData, screenData, index } = action.payload

            const screenIndex = findCurrentScreenIndex(state, screenData)

            switch (componentData.type) {
                case 'If': {
                    const u_id =
                        screenData.id +
                        '_' +
                        componentData.type +
                        '_' +
                        Math.floor(Math.random() * 1000) +
                        '_' +
                        state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout
                            .childrens.length
                    const payloadToAddInTemoporaryComponentArray = {
                        screenId: screenData.id,
                        u_id: u_id,
                        type: componentData.type,
                        index,
                        componentData: {
                            type: componentData.type,
                            value: null
                        }
                    }

                    state.flowJson.TemporaryFlowJsonScreens[
                        screenIndex
                    ].layout.childrens.push(payloadToAddInTemoporaryComponentArray)

                    const payloadToAddInSimulatorScreenData = {
                        screenId: screenData.id,
                        type: componentData.type,
                        u_id: u_id,
                        componentData: {
                            type: componentData.type,
                            value: null
                        }
                    }

                    state.simulator.screenData[screenIndex].layout.childrens.push(
                        payloadToAddInSimulatorScreenData
                    )

                    const payloadToAddInIfScreen = {
                        screenId: screenData.id,
                        u_id: u_id,
                        parentData: {
                            root_u_id: null,
                            u_id: null,
                            type: null
                        },
                        then: [
                            {
                                id: screenData.id,
                                title: screenData.title,
                                childrens: []
                            }
                        ],
                        else: [
                            {
                                id: screenData.id,
                                title: screenData.title,
                                childrens: []
                            }
                        ]
                    }
                    state.flowJson.ifScreens.push(payloadToAddInIfScreen)

                    const payloadToAddInIfScreenSimulatorData = {
                        screenId: screenData.id,
                        u_id: u_id,
                        parentData: {
                            root_u_id: null,
                            u_id: null,
                            type: null
                        },
                        then: [
                            {
                                id: screenData.id,
                                title: screenData.title,
                                childrens: []
                            }
                        ],
                        else: [
                            {
                                id: screenData.id,
                                title: screenData.title,
                                childrens: []
                            }
                        ]
                    }

                    state.ifScreenSimulator.ifComponents.push(
                        payloadToAddInIfScreenSimulatorData
                    )
                    state.showPropertyWindowTab = {
                        state: true,
                        item: componentData.type,
                        value: payloadToAddInTemoporaryComponentArray
                    }
                    break
                }

                case 'Switch': {
                    const u_id =
                        screenData.id +
                        '_' +
                        componentData.type +
                        '_' +
                        Math.floor(Math.random() * 1000) +
                        '_' +
                        state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout
                            .childrens.length

                    const payloadToAddInTemoporaryComponentArray = {
                        screenId: screenData.id,
                        u_id: u_id,
                        type: componentData.type,
                        index,
                        componentData: {
                            type: componentData.type,
                            value: null
                        }
                    }

                    state.flowJson.TemporaryFlowJsonScreens[
                        screenIndex
                    ].layout.childrens.push(payloadToAddInTemoporaryComponentArray)

                    const payloadToAddInSimulatorScreenData = {
                        screenId: screenData.id,
                        type: componentData.type,
                        u_id: u_id,
                        componentData: {
                            type: componentData.type,
                            value: null
                        }
                    }

                    state.simulator.screenData[screenIndex].layout.childrens.push(
                        payloadToAddInSimulatorScreenData
                    )

                    // Switch Cases added
                    const payloadToAddInSwitchCasesScreen = {
                        screenId: screenData.id,
                        u_id: u_id,
                        parentData: {
                            root_u_id: null,
                            u_id: null,
                            type: null
                        },
                        cases: [], // default will added when switch condition render.
                    }


                    state.flowJson.ifScreens.push(payloadToAddInSwitchCasesScreen)

                    state.ifScreenSimulator.ifComponents.push(
                        payloadToAddInSwitchCasesScreen
                    )
                    state.showPropertyWindowTab = {
                        state: true,
                        item: componentData.type,
                        value: payloadToAddInTemoporaryComponentArray
                    }
                    break
                }

                default: {
                    const payloadToAddInTemoporaryComponentArray = {
                        screenId: screenData.id,
                        u_id:
                            screenData.id +
                            '_' +
                            componentData.type +
                            '_' +
                            Math.floor(Math.random() * 1000) +
                            '_' +
                            state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout
                                .childrens.length,
                        type: componentData.type,
                        index,
                        componentData: {
                            type: componentData.type,
                            value: null
                        }
                    }

                    if (componentData.type === 'DataChannelUrl') {
                        state.flowJson.dataChannelUri =
                            payloadToAddInTemoporaryComponentArray
                    }

                    const payloadToAddInSimulatorScreenData = {
                        screenId: screenData.id,
                        type: componentData.type,
                        u_id:
                            screenData.id +
                            '_' +
                            componentData.type +
                            '_' +
                            Math.floor(Math.random() * 1000) +
                            '_' +
                            state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout
                                .childrens.length,
                        componentData: {
                            type: componentData.type,
                            value: null
                        }
                    }

                    state.simulator.screenData[screenIndex].layout.childrens.push(
                        payloadToAddInSimulatorScreenData
                    )

                    if (componentData.type === 'Variable') {
                        state.flowJson.TemporaryFlowJsonScreens[
                            screenIndex
                        ].layout.childrens.splice(
                            0,
                            0,
                            payloadToAddInTemoporaryComponentArray
                        )
                    } else {
                        state.flowJson.TemporaryFlowJsonScreens[
                            screenIndex
                        ].layout.childrens.push(payloadToAddInTemoporaryComponentArray)
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: componentData.type,
                        value: payloadToAddInTemoporaryComponentArray
                    }
                    break
                }
            }
        },

        //  -------------------------------------------- Button Functions ---------------------------------------------------------

        addButtonLabel: (state, action) => {
            const { currentScreenData, parentComponentData, inputDataToUpdate } =
                action.payload

            const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
            const childIndex = findChildIndex(
                state,
                indexOfScreen,
                parentComponentData
            )
            const temporaryFlowJsonComponentData = getComponentData(
                state,
                indexOfScreen,
                childIndex
            )

            if (temporaryFlowJsonComponentData === null) {
                updateTemporaryFlowJson(
                    state,
                    indexOfScreen,
                    childIndex,
                    inputDataToUpdate
                )
                updateSimulatorJson(state, indexOfScreen, childIndex, inputDataToUpdate)
            } else {
                updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
                    ...temporaryFlowJsonComponentData,
                    ...inputDataToUpdate
                })
                updateSimulatorJson(state, indexOfScreen, childIndex, {
                    ...temporaryFlowJsonComponentData,
                    ...inputDataToUpdate
                })
            }
        },

        addButtonItems: (state, action) => {
            const { currentScreenData, parentComponentData, buttonDataToUpdate } =
                action.payload
            const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
            const childIndex = findChildIndex(
                state,
                indexOfScreen,
                parentComponentData
            )

            const temporaryFlowJsonComponentData = getComponentData(
                state,
                indexOfScreen,
                childIndex
            )

            if (temporaryFlowJsonComponentData === null) {
                updateTemporaryFlowJson(
                    state,
                    indexOfScreen,
                    childIndex,
                    buttonDataToUpdate
                )
                updateSimulatorJson(
                    state,
                    indexOfScreen,
                    childIndex,
                    buttonDataToUpdate
                )
            } else {
                updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
                    ...temporaryFlowJsonComponentData,
                    ...buttonDataToUpdate
                })
                updateSimulatorJson(state, indexOfScreen, childIndex, {
                    ...temporaryFlowJsonComponentData,
                    ...buttonDataToUpdate
                })
            }
        },

        pushDynamicButtonOptions: (state, action) => {
            const { currentScreenData, parentComponentData, dynamicArrayVariable } =
                action.payload

            switch (state.openConditionComponentScreen.type) {
                //for components of 'if
                case 'If': {
                    const ifComponentIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )
                    const ifComponentId = state.flowJson?.ifScreens?.findIndex(
                        screen =>
                            screen.u_id === state.openConditionComponentScreen.item.u_id
                    )

                    const childIndex = findChildIndexForConditionalComponentScreen(
                        state,
                        ifComponentId,
                        parentComponentData
                    )

                    let simulatorComponentDataObject = null
                    let ifScreensComponentDataObject = null
                    //for then screen
                    if (state.openConditionComponentScreen.success) {
                        simulatorComponentDataObject =
                            state.ifScreenSimulator.ifComponents[ifComponentIndex].then[0]
                                .childrens[childIndex].componentData

                        ifScreensComponentDataObject =
                            state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                                childIndex
                            ].componentData
                    }
                    //for else screen
                    else {
                        simulatorComponentDataObject =
                            state.ifScreenSimulator.ifComponents[ifComponentIndex].else[0]
                                .childrens[childIndex].componentData

                        ifScreensComponentDataObject =
                            state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                                childIndex
                            ].componentData

                        if ([null, undefined].includes(ifScreensComponentDataObject)) {
                            console.log(
                                'error while adding buttons in TemporaryFlowJsonScreens'
                            )
                            return
                        }
                    }
                    if ([null, undefined].includes(simulatorComponentDataObject)) {
                        console.log('error while adding buttons in screenData')
                        return
                    }

                    const getIfScreensValueToUpdate = () => {
                        if (
                            [null, undefined].includes(ifScreensComponentDataObject.value)
                        ) {
                            return { 'data-source': dynamicArrayVariable }
                        }

                        if (
                            [null, undefined].includes(
                                ifScreensComponentDataObject.value['data-source']
                            )
                        ) {
                            return {
                                ...ifScreensComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }

                        if (
                            Array(
                                ifScreensComponentDataObject.value['data-source'].includes(
                                    '{data.'
                                )
                            )
                        ) {
                            return {
                                ...ifScreensComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }
                        if (
                            Array.isArray(ifScreensComponentDataObject.value['data-source'])
                        ) {
                            return {
                                ...ifScreensComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }
                    }

                    const getIfScreenSimulatorDataValueToUpdate = () => {
                        if (
                            [null, undefined].includes(simulatorComponentDataObject.value)
                        ) {
                            return { 'data-source': dynamicArrayVariable }
                        }

                        if (
                            [null, undefined].includes(
                                simulatorComponentDataObject.value['data-source']
                            )
                        ) {
                            return {
                                ...simulatorComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }
                        if (
                            Array(
                                simulatorComponentDataObject.value['data-source'].includes(
                                    '{data.'
                                )
                            )
                        ) {
                            return {
                                ...simulatorComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }
                        if (
                            Array.isArray(simulatorComponentDataObject.value['data-source'])
                        ) {
                            return {
                                ...simulatorComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }
                    }

                    updateConditionalComponentScreensJson(
                        state,
                        ifComponentIndex,
                        childIndex,
                        getIfScreensValueToUpdate()
                    )
                    updateSimulatorJsonForConditionalComponentScreens(
                        state,
                        ifComponentIndex,
                        childIndex,
                        getIfScreenSimulatorDataValueToUpdate()
                    )
                    break
                }
                //for components of 'switch'
                case 'Switch': {
                    const conditionIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )

                    // Find case index
                    const caseIndex = findCaseIndex(state, conditionIndex)

                    // find working component to update value
                    const childIndex = state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens.findIndex(
                        component => component.u_id === parentComponentData.u_id
                    )

                    const simulatorComponentDataObject =
                        state.ifScreenSimulator.ifComponents[conditionIndex].cases[caseIndex]
                            .childrens[childIndex].componentData

                    const ifScreensComponentDataObject =
                        state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ].componentData

                    if ([null, undefined].includes(simulatorComponentDataObject)) {
                        console.log('error while adding buttons in screenData')
                        return
                    }

                    const getIfScreensValueToUpdate = () => {
                        if (
                            [null, undefined].includes(ifScreensComponentDataObject.value)
                        ) {
                            return { 'data-source': dynamicArrayVariable }
                        }

                        if (
                            [null, undefined].includes(
                                ifScreensComponentDataObject.value['data-source']
                            )
                        ) {
                            return {
                                ...ifScreensComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }

                        if (
                            Array(
                                ifScreensComponentDataObject.value['data-source'].includes(
                                    '{data.'
                                )
                            )
                        ) {
                            return {
                                ...ifScreensComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }
                        if (
                            Array.isArray(ifScreensComponentDataObject.value['data-source'])
                        ) {
                            return {
                                ...ifScreensComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }
                    }

                    const getIfScreenSimulatorDataValueToUpdate = () => {
                        if (
                            [null, undefined].includes(simulatorComponentDataObject.value)
                        ) {
                            return { 'data-source': dynamicArrayVariable }
                        }

                        if (
                            [null, undefined].includes(
                                simulatorComponentDataObject.value['data-source']
                            )
                        ) {
                            return {
                                ...simulatorComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }
                        if (
                            Array(
                                simulatorComponentDataObject.value['data-source'].includes(
                                    '{data.'
                                )
                            )
                        ) {
                            return {
                                ...simulatorComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }
                        if (
                            Array.isArray(simulatorComponentDataObject.value['data-source'])
                        ) {
                            return {
                                ...simulatorComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }
                    }

                    updateConditionalComponentScreensJsonSwitch(
                        state,
                        conditionIndex,
                        caseIndex,
                        childIndex,
                        getIfScreensValueToUpdate()
                    );
                    updateSimulatorJsonForConditionalComponentSwitch(
                        state,
                        conditionIndex,
                        caseIndex,
                        childIndex,
                        getIfScreenSimulatorDataValueToUpdate()
                    )
                    break
                }

                //for normal components
                default: {
                    const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
                    const childIndex = findChildIndex(
                        state,
                        indexOfScreen,
                        parentComponentData
                    )

                    const screenDataComponentDataObject =
                        state.simulator.screenData[indexOfScreen].layout.childrens[
                            childIndex
                        ].componentData
                    const temporaryFlowJsonComponentDataObject =
                        state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].componentData

                    if (
                        [null, undefined].includes(temporaryFlowJsonComponentDataObject)
                    ) {
                        console.log(
                            'error while adding buttons in TemporaryFlowJsonScreens'
                        )
                        return
                    }

                    if ([null, undefined].includes(screenDataComponentDataObject)) {
                        console.log('error while adding buttons in screenData')
                        return
                    }

                    const getTemporaryFlowJsonValueToUpdate = () => {
                        if (
                            [null, undefined].includes(
                                temporaryFlowJsonComponentDataObject.value
                            )
                        ) {
                            return { 'data-source': dynamicArrayVariable }
                        }

                        if (
                            [null, undefined].includes(
                                temporaryFlowJsonComponentDataObject.value['data-source']
                            )
                        ) {
                            return {
                                ...temporaryFlowJsonComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }

                        if (
                            Array(
                                temporaryFlowJsonComponentDataObject.value[
                                    'data-source'
                                ].includes('{data.')
                            )
                        ) {
                            return {
                                ...temporaryFlowJsonComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }
                        if (
                            Array.isArray(
                                temporaryFlowJsonComponentDataObject.value['data-source']
                            )
                        ) {
                            return {
                                ...temporaryFlowJsonComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }
                    }

                    const getScreenDataValueToUpdate = () => {
                        if (
                            [null, undefined].includes(screenDataComponentDataObject.value)
                        ) {
                            return { 'data-source': dynamicArrayVariable }
                        }

                        if (
                            [null, undefined].includes(
                                screenDataComponentDataObject.value['data-source']
                            )
                        ) {
                            return {
                                ...screenDataComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }
                        if (
                            Array(
                                screenDataComponentDataObject.value['data-source'].includes(
                                    '{data.'
                                )
                            )
                        ) {
                            return {
                                ...screenDataComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }
                        if (
                            Array.isArray(screenDataComponentDataObject.value['data-source'])
                        ) {
                            return {
                                ...screenDataComponentDataObject.value,
                                'data-source': dynamicArrayVariable
                            }
                        }
                    }

                    updateTemporaryFlowJson(
                        state,
                        indexOfScreen,
                        childIndex,
                        getTemporaryFlowJsonValueToUpdate()
                    )
                    updateSimulatorJson(
                        state,
                        indexOfScreen,
                        childIndex,
                        getScreenDataValueToUpdate()
                    )
                    break
                }
            }

            // state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[childIndex].componentData.value = getTemporaryFlowJsonValueToUpdate();
            // state.simulator.screenData[indexOfScreen].layout.childrens[childIndex].componentData.value = getScreenDataValueToUpdate();
        },

        pushButtonItemsInArray: (state, action) => {
            const { currentScreenData, parentComponentData, buttonDataToUpdate } =
                action.payload

            let objectToPush = {
                id: buttonDataToUpdate.title,
                title: buttonDataToUpdate.title,
            }
            // check description
            if (buttonDataToUpdate.description && buttonDataToUpdate.description.trim().length > 0) {
                objectToPush = {
                    ...objectToPush,
                    description: buttonDataToUpdate.description
                }
            }
            // check metadata
            if (buttonDataToUpdate.metadata && buttonDataToUpdate.metadata.trim().length > 0) {
                objectToPush = {
                    ...objectToPush,
                    metadata: buttonDataToUpdate.metadata
                }
            }

            switch (state.openConditionComponentScreen.type) {
                //for components of 'if
                case 'If': {
                    const ifComponentIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )
                    const ifComponentId = state.flowJson?.ifScreens?.findIndex(
                        screen =>
                            screen.u_id === state.openConditionComponentScreen.item.u_id
                    )

                    const childIndex = findChildIndexForConditionalComponentScreen(
                        state,
                        ifComponentId,
                        parentComponentData
                    )


                    let simulatorComponentDataObject = null
                    let ifScreensComponentDataObject = null
                    //for then screen
                    if (state.openConditionComponentScreen.success) {
                        simulatorComponentDataObject =
                            state.ifScreenSimulator.ifComponents[ifComponentIndex].then[0]
                                .childrens[childIndex].componentData

                        ifScreensComponentDataObject =
                            state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                                childIndex
                            ].componentData
                    }
                    //for else screen
                    else {
                        simulatorComponentDataObject =
                            state.ifScreenSimulator.ifComponents[ifComponentIndex].else[0]
                                .childrens[childIndex].componentData

                        ifScreensComponentDataObject =
                            state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                                childIndex
                            ].componentData
                    }

                    // =================================== Preliminary Checks it should not happen ======================================
                    if ([null, undefined].includes(ifScreensComponentDataObject)) {
                        console.log(
                            'error while adding buttons in TemporaryFlowJsonScreens'
                        )
                        return
                    }

                    if ([null, undefined].includes(simulatorComponentDataObject)) {
                        console.log('error while adding buttons in screenData')
                        return
                    }
                    // ====================================================================================================================

                    const getIfScreensValueToUpdate = () => {
                        if (
                            [null, undefined].includes(ifScreensComponentDataObject.value)
                        ) {
                            return { 'data-source': [{ ...objectToPush }] }
                        }

                        if (
                            [null, undefined].includes(
                                ifScreensComponentDataObject.value['data-source']
                            )
                        ) {
                            return {
                                ...ifScreensComponentDataObject.value,
                                'data-source': [{ ...objectToPush }]
                            }
                        }

                        if (
                            ifScreensComponentDataObject.value['data-source'].includes(
                                '{data.'
                            )
                        ) {
                            return {
                                ...ifScreensComponentDataObject.value,
                                'data-source': [{ ...objectToPush }]
                            }
                        }

                        if (
                            Array.isArray(ifScreensComponentDataObject.value['data-source'])
                        ) {
                            return {
                                ...ifScreensComponentDataObject.value,
                                'data-source': [
                                    ...ifScreensComponentDataObject.value['data-source'],
                                    { ...objectToPush }
                                ]
                            }
                        }
                    }

                    const getIfScreenSimulatorDataValueToUpdate = () => {
                        if (
                            [null, undefined].includes(simulatorComponentDataObject.value)
                        ) {
                            return { 'data-source': [{ ...objectToPush }] }
                        }

                        if (
                            [null, undefined].includes(
                                simulatorComponentDataObject.value['data-source']
                            )
                        ) {
                            return {
                                ...simulatorComponentDataObject.value,
                                'data-source': [{ ...objectToPush }]
                            }
                        }

                        if (
                            simulatorComponentDataObject.value['data-source'].includes(
                                '{data.'
                            )
                        ) {
                            return {
                                ...simulatorComponentDataObject.value,
                                'data-source': [{ ...objectToPush }]
                            }
                        }

                        if (
                            Array.isArray(simulatorComponentDataObject.value['data-source'])
                        ) {
                            return {
                                ...simulatorComponentDataObject.value,
                                'data-source': [
                                    ...simulatorComponentDataObject.value['data-source'],
                                    { ...objectToPush }
                                ]
                            }
                        }
                    }

                    updateConditionalComponentScreensJson(
                        state,
                        ifComponentIndex,
                        childIndex,
                        getIfScreensValueToUpdate()
                    )
                    updateSimulatorJsonForConditionalComponentScreens(
                        state,
                        ifComponentIndex,
                        childIndex,
                        getIfScreenSimulatorDataValueToUpdate()
                    )


                    // update in property menu
                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                                childIndex
                            ].type
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                                childIndex
                            ].type,

                        value: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                            childIndex
                            ]
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                            childIndex
                            ]
                    }
                    break
                }

                //for components of 'switch'
                case 'Switch': {
                    const conditionIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )

                    // Find case index
                    const caseIndex = findCaseIndex(state, conditionIndex)

                    // find working component to update value
                    // const childIndex = findChildrenIndexForSwitch(
                    //     state,
                    //     conditionIndex,
                    //     parentComponentData,
                    //     caseIndex
                    // )
                    const childIndex = state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens.findIndex(
                        component => component.u_id === parentComponentData.u_id
                    )
                    let simulatorComponentDataObject = null
                    let ifScreensComponentDataObject = null

                    simulatorComponentDataObject =
                        state.ifScreenSimulator.ifComponents[conditionIndex].cases[caseIndex]
                            .childrens[childIndex].componentData

                    ifScreensComponentDataObject =
                        state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ].componentData

                    // =================================== Preliminary Checks it should not happen ======================================
                    if ([null, undefined].includes(ifScreensComponentDataObject)) {
                        console.log(
                            'error while adding buttons in TemporaryFlowJsonScreens'
                        )
                        return
                    }

                    if ([null, undefined].includes(simulatorComponentDataObject)) {
                        console.log('error while adding buttons in screenData')
                        return
                    }
                    // ====================================================================================================================

                    const getIfScreensValueToUpdate = () => {
                        if (
                            [null, undefined].includes(ifScreensComponentDataObject.value)
                        ) {
                            return { 'data-source': [{ ...objectToPush }] }
                        }

                        if (
                            [null, undefined].includes(
                                ifScreensComponentDataObject.value['data-source']
                            )
                        ) {
                            return {
                                ...ifScreensComponentDataObject.value,
                                'data-source': [{ ...objectToPush }]
                            }
                        }

                        if (
                            ifScreensComponentDataObject.value['data-source'].includes(
                                '{data.'
                            )
                        ) {
                            return {
                                ...ifScreensComponentDataObject.value,
                                'data-source': [{ ...objectToPush }]
                            }
                        }

                        if (
                            Array.isArray(ifScreensComponentDataObject.value['data-source'])
                        ) {
                            return {
                                ...ifScreensComponentDataObject.value,
                                'data-source': [
                                    ...ifScreensComponentDataObject.value['data-source'],
                                    { ...objectToPush }
                                ]
                            }
                        }
                    }

                    const getIfScreenSimulatorDataValueToUpdate = () => {
                        if (
                            [null, undefined].includes(simulatorComponentDataObject.value)
                        ) {
                            return { 'data-source': [{ ...objectToPush }] }
                        }

                        if (
                            [null, undefined].includes(
                                simulatorComponentDataObject.value['data-source']
                            )
                        ) {
                            return {
                                ...simulatorComponentDataObject.value,
                                'data-source': [{ ...objectToPush }]
                            }
                        }

                        if (
                            simulatorComponentDataObject.value['data-source'].includes(
                                '{data.'
                            )
                        ) {
                            return {
                                ...simulatorComponentDataObject.value,
                                'data-source': [{ ...objectToPush }]
                            }
                        }

                        if (
                            Array.isArray(simulatorComponentDataObject.value['data-source'])
                        ) {
                            return {
                                ...simulatorComponentDataObject.value,
                                'data-source': [
                                    ...simulatorComponentDataObject.value['data-source'],
                                    { ...objectToPush }
                                ]
                            }
                        }
                    }

                    updateConditionalComponentScreensJsonSwitch(
                        state,
                        conditionIndex,
                        caseIndex,
                        childIndex,
                        getIfScreensValueToUpdate()
                    )
                    updateSimulatorJsonForConditionalComponentSwitch(
                        state,
                        conditionIndex,
                        caseIndex,
                        childIndex,
                        getIfScreenSimulatorDataValueToUpdate()
                    )


                    // update in property menu
                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ].type,

                        value: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ]
                    }
                    break
                }
                //for normal components
                default: {
                    const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
                    const childIndex = findChildIndex(
                        state,
                        indexOfScreen,
                        parentComponentData
                    )



                    const screenDataComponentDataObject =
                        state.simulator.screenData[indexOfScreen].layout.childrens[
                            childIndex
                        ].componentData
                    const temporaryFlowJsonComponentDataObject =
                        state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].componentData

                    // =================================== Preliminary Checks it should not happen ======================================
                    if (
                        [null, undefined].includes(temporaryFlowJsonComponentDataObject)
                    ) {
                        console.log(
                            'error while adding buttons in TemporaryFlowJsonScreens'
                        )
                        return
                    }

                    if ([null, undefined].includes(screenDataComponentDataObject)) {
                        console.log('error while adding buttons in screenData')
                        return
                    }
                    // ====================================================================================================================

                    const getTemporaryFlowJsonValueToUpdate = () => {
                        if (
                            [null, undefined].includes(
                                temporaryFlowJsonComponentDataObject.value
                            )
                        ) {
                            return { 'data-source': [{ ...objectToPush }] }
                        }

                        if (
                            [null, undefined].includes(
                                temporaryFlowJsonComponentDataObject.value['data-source']
                            )
                        ) {
                            return {
                                ...temporaryFlowJsonComponentDataObject.value,
                                'data-source': [{ ...objectToPush }]
                            }
                        }

                        if (
                            temporaryFlowJsonComponentDataObject.value[
                                'data-source'
                            ].includes('{data.')
                        ) {
                            return {
                                ...temporaryFlowJsonComponentDataObject.value,
                                'data-source': [{ ...objectToPush }]
                            }
                        }

                        if (
                            Array.isArray(
                                temporaryFlowJsonComponentDataObject.value['data-source']
                            )
                        ) {
                            return {
                                ...temporaryFlowJsonComponentDataObject.value,
                                'data-source': [
                                    ...temporaryFlowJsonComponentDataObject.value['data-source'],
                                    { ...objectToPush }
                                ]
                            }
                        }
                    }

                    const getScreenDataValueToUpdate = () => {
                        if (
                            [null, undefined].includes(screenDataComponentDataObject.value)
                        ) {
                            return { 'data-source': [{ ...objectToPush }] }
                        }

                        if (
                            [null, undefined].includes(
                                screenDataComponentDataObject.value['data-source']
                            )
                        ) {
                            return {
                                ...screenDataComponentDataObject.value,
                                'data-source': [{ ...objectToPush }]
                            }
                        }

                        if (
                            screenDataComponentDataObject.value['data-source'].includes(
                                '{data.'
                            )
                        ) {
                            return {
                                ...screenDataComponentDataObject.value,
                                'data-source': [{ ...objectToPush }]
                            }
                        }

                        if (
                            Array.isArray(screenDataComponentDataObject.value['data-source'])
                        ) {
                            return {
                                ...screenDataComponentDataObject.value,
                                'data-source': [
                                    ...screenDataComponentDataObject.value['data-source'],
                                    { ...objectToPush }
                                ]
                            }
                        }
                    }

                    updateTemporaryFlowJson(
                        state,
                        indexOfScreen,
                        childIndex,
                        getTemporaryFlowJsonValueToUpdate()
                    )
                    updateSimulatorJson(
                        state,
                        indexOfScreen,
                        childIndex,
                        getScreenDataValueToUpdate()
                    )



                    // update in property menu
                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].type,
                        value:
                            state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                                .childrens[childIndex]
                    }
                    break
                }
            }
            // state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[childIndex].componentData.value = getTemporaryFlowJsonValueToUpdate();
            // state.simulator.screenData[indexOfScreen].layout.childrens[childIndex].componentData.value = getScreenDataValueToUpdate();
        },

        deleteButtonItem: (state, action) => {
            const { index, currentScreenData, parentComponentData, id } = action.payload

            switch (state.openConditionComponentScreen.type) {
                //for if components then screen
                case "If": {
                    //----------------to delete from ifScreens then
                    const ifComponentIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )
                    const childIndex =
                        findChildIndexForConditionalComponentScreen(
                            state,
                            ifComponentIndex,
                            parentComponentData
                        )
                    // success Screen
                    if (
                        state.openConditionComponentScreen.state === true &&
                        state.openConditionComponentScreen.success === true
                    ) {

                        //  ==================================================== DELETE INIT VALUES ====================================
                        const componentType = state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[childIndex].type;
                        const initValue = state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[childIndex].componentData['init-values'];
                        const dataSourceObject = state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[childIndex].componentData.value['data-source'];
                        const simulatorInitValue = state.ifScreenSimulator.ifComponents[ifComponentIndex].then[0].childrens[childIndex].componentData['init-values'];
                        const simulatorDataSourceObject = state.ifScreenSimulator.ifComponents[ifComponentIndex].then[0].childrens[childIndex].componentData.value['data-source'];
                        const simulatorComponentType = state.ifScreenSimulator.ifComponents[ifComponentIndex].then[0].childrens[childIndex].type;

                        // Delete init values from flow json
                        deleteInitValueOnDeleteOption(state, initValue, dataSourceObject, componentType, index, id);

                        // Delete init values from simulator
                        deleteInitValueOnDeleteOption(state, simulatorInitValue, simulatorDataSourceObject, simulatorComponentType, index, id);

                        //  ==================================================== DELETE INIT VALUES ====================================
                        state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                            childIndex
                        ].componentData.value['data-source'].splice(index, 1)
                        //----------------to delete from ifScreens Simulator then
                        state.ifScreenSimulator.ifComponents[ifComponentIndex].then[0].childrens[
                            childIndex
                        ].componentData.value['data-source'].splice(index, 1)
                    }
                    //for if components else screen
                    else {
                        //  ==================================================== DELETE INIT VALUES ====================================
                        const componentType = state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[childIndex].type;
                        const initValue = state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[childIndex].componentData['init-values'];
                        const dataSourceObject = state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[childIndex].componentData.value['data-source'];
                        const simulatorInitValue = state.ifScreenSimulator.ifComponents[ifComponentIndex].else[0].childrens[childIndex].componentData['init-values'];
                        const simulatorDataSourceObject = state.ifScreenSimulator.ifComponents[ifComponentIndex].else[0].childrens[childIndex].componentData.value['data-source'];
                        const simulatorComponentType = state.ifScreenSimulator.ifComponents[ifComponentIndex].else[0].childrens[childIndex].type;

                        // Delete init values from flow json
                        deleteInitValueOnDeleteOption(state, initValue, dataSourceObject, componentType, index, id);

                        // Delete init values from simulator
                        deleteInitValueOnDeleteOption(state, simulatorInitValue, simulatorDataSourceObject, simulatorComponentType, index, id);
                        //  ==================================================== DELETE INIT VALUES ====================================
                        state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                            childIndex
                        ].componentData.value['data-source'].splice(index, 1)

                        //---------------to delete from ifScreens Simulator else
                        state.ifScreenSimulator.ifComponents[ifComponentIndex].else[0].childrens[
                            childIndex
                        ].componentData.value['data-source'].splice(index, 1)
                    }

                    // update in property menu 
                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                                childIndex
                            ].type
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                                childIndex
                            ].type,

                        value: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                            childIndex
                            ]
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                            childIndex
                            ]
                    }

                    break;
                }

                default: {
                    const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
                    const childIndex = findChildIndex(
                        state,
                        indexOfScreen,
                        parentComponentData
                    )
                    //  ==================================================== DELETE INIT VALUES ====================================
                    const componentType = state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[childIndex].type;
                    const initValue = state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[childIndex].componentData['init-values'];
                    const dataSourceObject = state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[childIndex].componentData.value['data-source'];
                    const simulatorInitValue = state?.simulator.screenData[indexOfScreen]?.layout.childrens[childIndex].componentData['init-values'];
                    const simulatorDataSourceObject = state?.simulator.screenData[indexOfScreen]?.layout.childrens[childIndex].componentData.value['data-source'];
                    const simulatorComponentType = state?.simulator.screenData[indexOfScreen]?.layout.childrens[childIndex].type;

                    // Delete init values from flow json
                    deleteInitValueOnDeleteOption(state, initValue, dataSourceObject, componentType, index, id);
                    // Delete init values from simulator
                    deleteInitValueOnDeleteOption(state, simulatorInitValue, simulatorDataSourceObject, simulatorComponentType, index, id);

                    //  ==================================================== DELETE INIT VALUES ====================================


                    //to delete from temporaryFlowJson
                    state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[
                        childIndex
                    ].componentData.value['data-source'].splice(index, 1)
                    //to delete from temporaryFlowJson simulator
                    state.simulator.screenData[indexOfScreen].layout.childrens[
                        childIndex
                    ].componentData.value['data-source'].splice(index, 1);

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].type,
                        value:
                            state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                                .childrens[childIndex]
                    }
                    break;
                }
            }
        },

        // Component Not added;
        // deleteDataChannelUrlComponent: (state, action) => {
        //     state.flowJson.dataChannelUri = null
        //     state.showPropertyWindowTab = {
        //         state: false,
        //         item: null,
        //         value: null
        //     }
        // },

        deleteVariableExample: (state, action) => {
            const { index, currentScreenData } = action.payload
            const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)

            state.flowJson.TemporaryFlowJsonScreens[
                indexOfScreen
            ].data.variables.splice(index, 1)
        },

        setNameOfFooterLabel: (state, action) => {
            const { currentScreenData, parentComponentData, footerDataToUpdate } =
                action.payload

            const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
            const childIndex = findChildIndex(
                state,
                indexOfScreen,
                parentComponentData
            )

            const temporaryFlowJsonComponentData = getComponentData(
                state,
                indexOfScreen,
                childIndex
            )
            if (temporaryFlowJsonComponentData === null) {
                updateTemporaryFlowJson(
                    state,
                    indexOfScreen,
                    childIndex,
                    footerDataToUpdate
                )
                updateSimulatorJson(
                    state,
                    indexOfScreen,
                    childIndex,
                    footerDataToUpdate
                )
            } else {
                updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
                    ...temporaryFlowJsonComponentData,
                    ...footerDataToUpdate
                })
                updateSimulatorJson(state, indexOfScreen, childIndex, {
                    ...temporaryFlowJsonComponentData,
                    ...footerDataToUpdate
                })
            }

            state.showPropertyWindowTab = {
                state: true,
                item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                    .childrens[childIndex].type,
                value:
                    state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                        .childrens[childIndex]
            }
        },

        setFooterAction: (state, action) => {
            const { footerAction, currentScreenData, parentComponentData } =
                action.payload
            const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
            const childIndex = findChildIndex(
                state,
                indexOfScreen,
                parentComponentData
            )

            const temporaryFlowJsonComponentData = getComponentData(
                state,
                indexOfScreen,
                childIndex
            )

            const indexOfCurrentScreen = state.screen.allScreens.findIndex(
                screen => screen.id === currentScreenData.id
            )

            if (footerAction === 'complete') {
                state.completeActionInFooterButtonExist = true
            }
            let payloadToAdd

            if (temporaryFlowJsonComponentData === null) {
                if (footerAction === 'navigate') {
                    payloadToAdd = {
                        'on-click-action': {
                            name: footerAction,
                            next: {
                                type: 'screen',
                                name: state.screen.allScreens[indexOfCurrentScreen + 1].id
                            },
                            payload: {}
                        }
                    }
                } else {
                    payloadToAdd = {
                        'on-click-action': {
                            name: footerAction,
                            payload: {}
                        }
                    }
                }

                updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
                    ...payloadToAdd
                })
            } else {
                if (footerAction === 'navigate') {
                    payloadToAdd = {
                        'on-click-action': {
                            name: footerAction,
                            next: {
                                type: 'screen',
                                name: state.screen.allScreens[indexOfCurrentScreen + 1].id
                            },
                            payload: {}
                        }
                    }
                } else {
                    payloadToAdd = {
                        'on-click-action': {
                            name: footerAction,
                            payload: {}
                        }
                    }
                }

                updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
                    ...temporaryFlowJsonComponentData,
                    ...payloadToAdd
                })
            }
        },

        addExtraVariable: (state, action) => {
            const { variableToAdd, currentScreenData } = action.payload

            // UPDATE VARIABLE TO ADD
            // added screen Id,  so user will get variable in current screen
            // previous implementation has bug 
            // if user drag screen so user not getting proper variable.

            // const updateVariableToAdd = {
            //     ...variableToAdd,
            //     "ScreenId": currentScreenData.id
            // }
            const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)

            if (
                state.flowJson.TemporaryFlowJsonScreens[indexOfScreen]?.data?.variables
            ) {
                state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].data = {
                    ...state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].data,
                    variables: [
                        ...state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].data
                            .variables,
                        { ...variableToAdd }
                    ]
                }
            } else {
                state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].data = {
                    ...state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].data,
                    variables: [{ ...variableToAdd }]
                }
            }

            // switch (state.openConditionComponentScreen.type) {
            //   case 'If':
            //     {
            //       const ifComponentIndex = findConditionalComponentIndex(
            //         state,
            //         currentScreenData
            //       )
            //       if (state.flowJson.ifScreens[ifComponentIndex]?.data?.variables) {
            //         state.flowJson.ifScreens[ifComponentIndex].data = {
            //           ...state.flowJson.ifScreens[ifComponentIndex].data,
            //           variables: [
            //             ...state.flowJson.ifScreens[ifComponentIndex].data.variables,
            //             { ...variableToAdd }
            //           ]
            //         }
            //       } else {
            //         state.flowJson.ifScreens[ifComponentIndex].data = {
            //           ...state.flowJson.ifScreens[ifComponentIndex].data,
            //           variables: [{ ...variableToAdd }]
            //         }
            //       }
            //     }
            //     break

            //   case 'Switch':
            //     {
            //     }
            //     break

            //   default: {
            //     const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
            //     if (
            //       state.flowJson.TemporaryFlowJsonScreens[indexOfScreen]?.data
            //         ?.variables
            //     ) {
            //       state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].data = {
            //         ...state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].data,
            //         variables: [
            //           ...state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].data
            //             .variables,
            //           { ...variableToAdd }
            //         ]
            //       }
            //     } else {
            //       state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].data = {
            //         ...state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].data,
            //         variables: [{ ...variableToAdd }]
            //       }
            //     }
            //   }
            // }
        },

        // ------------------------------------------------ Inputs ----------------------------------------------------------------

        addConstantVariableToButton: (state, action) => {
            const { currentScreenData, parentComponentData, inputDataToUpdate } =
                action.payload

            switch (state.openConditionComponentScreen.type) {
                case 'If': {
                    const ifComponentIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    );
                    const ifComponentId = state.flowJson?.ifScreens?.findIndex(
                        screen =>
                            screen.u_id === state.openConditionComponentScreen.item.u_id
                    );

                    const childIndex = findChildIndexForConditionalComponentScreen(
                        state,
                        ifComponentId,
                        parentComponentData
                    );


                    if (state.openConditionComponentScreen.success) {
                        state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                            childIndex
                        ].componentData.value['on-select-action'].payload = {
                            ...state.flowJson.ifScreens[ifComponentIndex].then[0]
                                .childrens[childIndex].componentData.value['on-select-action']
                                .payload,
                            ...inputDataToUpdate
                        }

                    } else {
                        state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                            childIndex
                        ].componentData.value['on-select-action'].payload = {
                            ...state.flowJson.ifScreens[ifComponentIndex].else[0]
                                .childrens[childIndex].componentData.value['on-select-action']
                                .payload,
                            ...inputDataToUpdate
                        }

                        // state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                        //     childIndex
                        // ].componentData.value
                    };


                    break;
                }
                case 'Switch': {
                    // Find IfScreen
                    const conditionIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )

                    // Find case index
                    const caseIndex = findCaseIndex(state, conditionIndex)

                    // find working component to update value
                    const childIndex = findChildrenIndexForSwitch(
                        state,
                        conditionIndex,
                        parentComponentData,
                        caseIndex
                    )

                    state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                        childIndex
                    ].componentData.value['on-select-action'].payload = {
                        ...state.flowJson.ifScreens[conditionIndex].cases[caseIndex]
                            .childrens[childIndex].componentData.value['on-select-action']
                            .payload,
                        ...inputDataToUpdate
                    }
                    break
                }

                default: {
                    const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
                    const childIndex = findChildIndex(
                        state,
                        indexOfScreen,
                        parentComponentData
                    )

                    state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[
                        childIndex
                    ].componentData.value['on-select-action'].payload = {
                        ...state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].componentData.value['on-select-action']
                            .payload,
                        ...inputDataToUpdate
                    }
                    break
                }
            }

        },

        addInputLabel: (state, action) => {
            const { currentScreenData, parentComponentData, inputDataToUpdate } =
                action.payload

            switch (state.openConditionComponentScreen.type) {
                case 'If': {
                    const ifComponentIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )
                    const ifComponentId = state.flowJson?.ifScreens?.findIndex(
                        screen =>
                            screen.u_id === state.openConditionComponentScreen.item.u_id
                    )

                    const childIndex = findChildIndexForConditionalComponentScreen(
                        state,
                        ifComponentId,
                        parentComponentData
                    )
                    const ifScreensComponentData =
                        getComponentDataForConditionalComponentScreen(
                            state,
                            ifComponentIndex,
                            childIndex
                        )

                    if (ifScreensComponentData === null) {
                        updateConditionalComponentScreensJson(
                            state,
                            ifComponentIndex,
                            childIndex,
                            inputDataToUpdate
                        )
                        updateSimulatorJsonForConditionalComponentScreens(
                            state,
                            ifComponentIndex,
                            childIndex,
                            inputDataToUpdate
                        )
                    } else {
                        updateConditionalComponentScreensJson(
                            state,
                            ifComponentIndex,
                            childIndex,
                            {
                                ...ifScreensComponentData,
                                ...inputDataToUpdate
                            }
                        )
                        updateSimulatorJsonForConditionalComponentScreens(
                            state,
                            ifComponentIndex,
                            childIndex,
                            {
                                ...ifScreensComponentData,
                                ...inputDataToUpdate
                            }
                        )
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                                childIndex
                            ].type
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                                childIndex
                            ].type,

                        value: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                            childIndex
                            ]
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                            childIndex
                            ]
                    }
                    break
                }
                case 'Switch': {
                    // Find IfScreen
                    const conditionIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )

                    // Find case index
                    const caseIndex = findCaseIndex(state, conditionIndex)

                    // find working component to update value
                    const childIndex = findChildrenIndexForSwitch(
                        state,
                        conditionIndex,
                        parentComponentData,
                        caseIndex
                    )
                    // const childIndex = state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens.findIndex(
                    //     component => component.u_id === parentComponentData.u_id
                    // )

                    const componentDataValue = getComponentDataForConditionalComponentsSwitch(
                        state,
                        conditionIndex,
                        caseIndex,
                        childIndex
                    )

                    if (componentDataValue === null) {
                        updateConditionalComponentScreensJsonSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            inputDataToUpdate
                        )
                        updateSimulatorJsonForConditionalComponentSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            inputDataToUpdate
                        )
                    } else {
                        updateConditionalComponentScreensJsonSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            {
                                ...componentDataValue,
                                ...inputDataToUpdate
                            }
                        )
                        updateSimulatorJsonForConditionalComponentSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            {
                                ...componentDataValue,
                                ...inputDataToUpdate
                            }
                        )
                    }
                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ].type,
                        value: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ]
                    }
                    break
                }

                default: {
                    const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
                    const childIndex = findChildIndex(
                        state,
                        indexOfScreen,
                        parentComponentData
                    )
                    const temporaryFlowJsonComponentData = getComponentData(
                        state,
                        indexOfScreen,
                        childIndex
                    )

                    if (temporaryFlowJsonComponentData === null) {
                        updateTemporaryFlowJson(
                            state,
                            indexOfScreen,
                            childIndex,
                            inputDataToUpdate
                        )
                        updateSimulatorJson(
                            state,
                            indexOfScreen,
                            childIndex,
                            inputDataToUpdate
                        )
                    } else {
                        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
                            ...temporaryFlowJsonComponentData,
                            ...inputDataToUpdate
                        })
                        updateSimulatorJson(state, indexOfScreen, childIndex, {
                            ...temporaryFlowJsonComponentData,
                            ...inputDataToUpdate
                        })
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].type,
                        value:
                            state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                                .childrens[childIndex]
                    }
                    break
                }
            }
        },

        setInputComponentRequiredField: (state, action) => {
            const { isRequired, currentScreenData, parentComponentData, name } =
                action.payload
            const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
            const childIndex = findChildIndex(
                state,
                indexOfScreen,
                parentComponentData
            )
            const temporaryFlowJsonComponentData = getComponentData(
                state,
                indexOfScreen,
                childIndex
            )

            if (temporaryFlowJsonComponentData === null) {
                updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
                    label: null,
                    required: isRequired,
                    name
                })
            } else {
                state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[
                    childIndex
                ].componentData.value.required = isRequired
            }

            state.showPropertyWindowTab = {
                state: true,
                item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                    .childrens[childIndex].type,
                value:
                    state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                        .childrens[childIndex]
            }
        },

        // Delete : added into addInputLabel method
        // -------------------------------------------------- Headers ---------------------------------------------------------------
        setTextHeadingAndSubHeading: (state, action) => {
            // Updated
            const { currentScreenData, parentComponentData, headerDataToUpdate } =
                action.payload

            switch (state.openConditionComponentScreen.type) {
                //for components inside 'if'
                case 'If': {
                    const ifComponentIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )
                    const ifComponentId = state.flowJson?.ifScreens?.findIndex(
                        screen =>
                            screen.u_id === state.openConditionComponentScreen.item.u_id
                    )
                    const childIndex = findChildIndexForConditionalComponentScreen(
                        state,
                        ifComponentId,
                        parentComponentData
                    )

                    const ifScreensComponentData =
                        getComponentDataForConditionalComponentScreen(
                            state,
                            ifComponentIndex,
                            childIndex
                        )

                    if (ifScreensComponentData === null) {
                        updateConditionalComponentScreensJson(
                            state,
                            ifComponentIndex,
                            childIndex,
                            headerDataToUpdate
                        )
                        updateSimulatorJsonForConditionalComponentScreens(
                            state,
                            ifComponentIndex,
                            childIndex,
                            headerDataToUpdate
                        )
                    } else {
                        updateConditionalComponentScreensJson(
                            state,
                            ifComponentIndex,
                            childIndex,
                            {
                                ...ifScreensComponentData,
                                ...headerDataToUpdate
                            }
                        )
                        updateSimulatorJsonForConditionalComponentScreens(
                            state,
                            ifComponentIndex,
                            childIndex,
                            {
                                ...ifScreensComponentData,
                                ...headerDataToUpdate
                            }
                        )
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                                childIndex
                            ].type
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                                childIndex
                            ].type,

                        value: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                            childIndex
                            ]
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                            childIndex
                            ]
                    }
                    break
                }

                //for components inside switch case
                case 'Switch': {
                    break
                }

                //for normal other component
                default: {
                    const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)

                    const childIndex = findChildIndex(
                        state,
                        indexOfScreen,
                        parentComponentData
                    )
                    const temporaryFlowJsonComponentData = getComponentData(
                        state,
                        indexOfScreen,
                        childIndex
                    )

                    if (temporaryFlowJsonComponentData === null) {
                        updateTemporaryFlowJson(
                            state,
                            indexOfScreen,
                            childIndex,
                            headerDataToUpdate
                        )
                        updateSimulatorJson(
                            state,
                            indexOfScreen,
                            childIndex,
                            headerDataToUpdate
                        )
                    } else {
                        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
                            ...temporaryFlowJsonComponentData,
                            ...headerDataToUpdate
                        })
                        updateSimulatorJson(state, indexOfScreen, childIndex, {
                            ...temporaryFlowJsonComponentData,
                            ...headerDataToUpdate
                        })
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].type,
                        value:
                            state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                                .childrens[childIndex]
                    }
                    break
                }
            }
        },

        // Delete : added into addInputLabel method
        setTextBodyInput: (state, action) => {
            const { currentScreenData, parentComponentData, textBodyDataToUpdate } =
                action.payload

            switch (state.openConditionComponentScreen.type) {
                //for components of 'if
                case 'If': {
                    const {
                        currentScreenData,
                        parentComponentData,
                        textBodyDataToUpdate
                    } = action.payload

                    const ifComponentIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )
                    const ifComponentId = state.flowJson?.ifScreens?.findIndex(
                        screen =>
                            screen.u_id === state.openConditionComponentScreen.item.u_id
                    )

                    const childIndex = findChildIndexForConditionalComponentScreen(
                        state,
                        ifComponentId,
                        parentComponentData
                    )
                    const ifScreensComponentData =
                        getComponentDataForConditionalComponentScreen(
                            state,
                            ifComponentIndex,
                            childIndex
                        )

                    if (ifScreensComponentData === null) {
                        updateConditionalComponentScreensJson(
                            state,
                            ifComponentIndex,
                            childIndex,
                            textBodyDataToUpdate
                        )
                        updateSimulatorJsonForConditionalComponentScreens(
                            state,
                            ifComponentIndex,
                            childIndex,
                            textBodyDataToUpdate
                        )
                    } else {
                        updateConditionalComponentScreensJson(
                            state,
                            ifComponentIndex,
                            childIndex,
                            {
                                ...ifScreensComponentData,
                                ...textBodyDataToUpdate
                            }
                        )
                        updateSimulatorJsonForConditionalComponentScreens(
                            state,
                            ifComponentIndex,
                            childIndex,
                            {
                                ...ifScreensComponentData,
                                ...textBodyDataToUpdate
                            }
                        )
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                                childIndex
                            ].type
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                                childIndex
                            ].type,

                        value: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                            childIndex
                            ]
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                            childIndex
                            ]
                    }
                    break
                }

                //for components of 'switch'
                case 'Switch': {
                    break
                }

                //for normal components
                default: {
                    const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
                    const childIndex = findChildIndex(
                        state,
                        indexOfScreen,
                        parentComponentData
                    )
                    const temporaryFlowJsonComponentData = getComponentData(
                        state,
                        indexOfScreen,
                        childIndex
                    )

                    if (temporaryFlowJsonComponentData === null) {
                        updateTemporaryFlowJson(
                            state,
                            indexOfScreen,
                            childIndex,
                            textBodyDataToUpdate
                        )
                        updateSimulatorJson(
                            state,
                            indexOfScreen,
                            childIndex,
                            textBodyDataToUpdate
                        )
                    } else {
                        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
                            ...temporaryFlowJsonComponentData,
                            ...textBodyDataToUpdate
                        })
                        updateSimulatorJson(state, indexOfScreen, childIndex, {
                            ...temporaryFlowJsonComponentData,
                            ...textBodyDataToUpdate
                        })
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].type,
                        value:
                            state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                                .childrens[childIndex]
                    }
                    break
                }
            }
        },

        // deleteComponetFromSlice: (state, action) => {
        //     const { currentScreen, parentComponentData } = action.payload
        //     const indexOfScreen = findCurrentScreenIndex(state, currentScreen)
        //     const childIndex = findChildIndex(
        //         state,
        //         indexOfScreen,
        //         parentComponentData
        //     )

        //     state.flowJson.TemporaryFlowJsonScreens[
        //         indexOfScreen
        //     ].layout.childrens.splice(childIndex, 1)
        //     state.simulator.screenData[indexOfScreen].layout.childrens.splice(
        //         childIndex,
        //         1
        //     )
        //     state.showPropertyWindowTab = {
        //         state: false,
        //         item: ''
        //     }

        //     // Component id is always unique so find with u_id And delete Output variable
        //     if (
        //         state.flowJson.outputVariable &&
        //         Array.isArray(state.flowJson.outputVariable)
        //     ) {
        //         const deleteOutputVariableOnClickDeleteComponent =
        //             state.flowJson.outputVariable.findIndex(
        //                 key => key.u_id === parentComponentData.u_id
        //             )
        //         if (deleteOutputVariableOnClickDeleteComponent !== -1) {
        //             state.flowJson.outputVariable.splice(
        //                 deleteOutputVariableOnClickDeleteComponent,
        //                 1
        //             )
        //         }
        //     }
        // },

        clearDropDownListState: (state, action) => {
            state.dropDownOptionslist = []
        },

        //  -------------------------------------------- Others ---------------------------------------------------------------------
        setDatePicker: (state, action) => {
            const { datePickerLabel, currentScreenData, parentComponentData } =
                action.payload
            const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)

            const childIndex = findChildIndex(
                state,
                indexOfScreen,
                parentComponentData
            )
            const temporaryFlowJsonComponentData = getComponentData(
                state,
                indexOfScreen,
                childIndex
            )

            if (temporaryFlowJsonComponentData === null) {
                updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
                    label: datePickerLabel
                })
                updateSimulatorJson(state, indexOfScreen, childIndex, {
                    label: datePickerLabel
                })
            } else {
                updateTemporaryFlowJson(
                    state,
                    indexOfScreen,
                    childIndex,
                    datePickerLabel
                )
                updateSimulatorJson(state, indexOfScreen, childIndex, datePickerLabel)
            }
        },

        setImageSrc: (state, action) => {
            const { imageSrc, currentScreenData, parentComponentData } =
                action.payload
            const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
            const childIndex = findChildIndex(
                state,
                indexOfScreen,
                parentComponentData
            )

            const imageAddressArray = imageSrc.toString().split('base64,')
            let newImageSrc
            if (imageAddressArray.length > 1) {
                newImageSrc = imageAddressArray[1]
            } else {
                newImageSrc = imageSrc
            }

            updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
                src: newImageSrc,
                width: 200,
                height: 200
            })
            updateSimulatorJson(state, indexOfScreen, childIndex, {
                src: newImageSrc,
                width: 200,
                height: 200
            })
        },

        updateTemporaryComponentArrayOnDropEvent: (state, action) => {
            const { screenData, componentData, componentIndex, dropEventIndex } =
                action.payload
            const screenIndex = findCurrentScreenIndex(state, screenData)

            switch (componentData.type) {
                case 'If': {
                    const u_id =
                        screenData.id +
                        '_' +
                        componentData.type +
                        '_' +
                        Math.floor(Math.random() * 1000) +
                        '_' +
                        state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout
                            .childrens.length
                    const payloadToAddInTemoporaryComponentArray = {
                        screenId: screenData.id,
                        u_id: u_id,
                        type: componentData.type,
                        componentData: {
                            type: componentData.type,
                            value: null
                        }
                    }

                    state.flowJson.TemporaryFlowJsonScreens[
                        screenIndex
                    ].layout.childrens.splice(
                        dropEventIndex + 1,
                        0,
                        payloadToAddInTemoporaryComponentArray
                    )
                    state.simulator.screenData[screenIndex].layout.childrens.splice(
                        dropEventIndex + 1,
                        0,
                        payloadToAddInTemoporaryComponentArray
                    )

                    const payloadToAddInIfScreen = {
                        screenId: screenData.id,
                        u_id: u_id,
                        parentData: {
                            root_u_id: null,
                            u_id: null,
                            type: null
                        },
                        then: [
                            {
                                id: screenData.id,
                                title: screenData.title,
                                childrens: []
                            }
                        ],
                        else: [
                            {
                                id: screenData.id,
                                title: screenData.title,
                                childrens: []
                            }
                        ]
                    }

                    state.flowJson.ifScreens.push(payloadToAddInIfScreen)

                    state.ifScreenSimulator.ifComponents.push(
                        payloadToAddInIfScreen
                    )

                    state.showPropertyWindowTab = {
                        state: true,
                        item: componentData.type,
                        value: payloadToAddInTemoporaryComponentArray
                    }
                    break
                }

                case 'Switch': {
                    const u_id =
                        screenData.id +
                        '_' +
                        componentData.type +
                        '_' +
                        Math.floor(Math.random() * 1000) +
                        '_' +
                        state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout
                            .childrens.length

                    const payloadToAddInTemoporaryComponentArray = {
                        screenId: screenData.id,
                        u_id: u_id,
                        type: componentData.type,
                        componentData: {
                            type: componentData.type,
                            value: null
                        }
                    }

                    state.flowJson.TemporaryFlowJsonScreens[
                        screenIndex
                    ].layout.childrens.splice(
                        dropEventIndex + 1,
                        0,
                        payloadToAddInTemoporaryComponentArray
                    )
                    state.simulator.screenData[screenIndex].layout.childrens.splice(
                        dropEventIndex + 1,
                        0,
                        payloadToAddInTemoporaryComponentArray
                    )

                    // Switch Cases added
                    const payloadToAddInSwitchCasesScreen = {
                        screenId: screenData.id,
                        u_id: u_id,
                        parentData: {
                            root_u_id: null,
                            u_id: null,
                            type: null
                        },
                        cases: [], // default will added when switch condition render.
                    }


                    state.flowJson.ifScreens.push(payloadToAddInSwitchCasesScreen)

                    state.ifScreenSimulator.ifComponents.push(
                        payloadToAddInSwitchCasesScreen
                    )
                    state.showPropertyWindowTab = {
                        state: true,
                        item: componentData.type,
                        value: payloadToAddInTemoporaryComponentArray
                    }
                    break
                }

                default: {
                    const payloadToAddInTemoporaryComponentArray = {
                        screenId: screenData.id,
                        u_id:
                            screenData.id +
                            '_' +
                            componentData.type +
                            '_' +
                            Math.floor(Math.random() * 1000) +
                            '_' +
                            state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout.childrens
                                .length,
                        type: componentData.type,
                        index: componentIndex,
                        componentData: {
                            type: componentData.type,
                            value: null
                        }
                    }

                    if (componentData.type === 'DataChannelUrl') {
                        state.flowJson.dataChannelUri = payloadToAddInTemoporaryComponentArray
                    }

                    if (componentData.type === 'Variable') {
                        state.flowJson.TemporaryFlowJsonScreens[
                            screenIndex
                        ].layout.childrens.splice(0, 0, payloadToAddInTemoporaryComponentArray)
                    } else {
                        state.flowJson.TemporaryFlowJsonScreens[
                            screenIndex
                        ].layout.childrens.splice(
                            dropEventIndex + 1,
                            0,
                            payloadToAddInTemoporaryComponentArray
                        )
                    }
                    state.simulator.screenData[screenIndex].layout.childrens.splice(
                        dropEventIndex + 1,
                        0,
                        payloadToAddInTemoporaryComponentArray
                    )
                    // On Drag & Drop Event - create if screen Array
                    state.showPropertyWindowTab = {
                        state: true,
                        item: componentData.type,
                        value: payloadToAddInTemoporaryComponentArray
                    }
                    break
                }
            }
        },

        updateTemporaryArrayOnRenderingComponentDroping: (state, action) => {
            const { screenData, componentData, componentIndex, dropEventIndex } =
                action.payload
            const screenIndex = findCurrentScreenIndex(state, screenData)

            state.flowJson.TemporaryFlowJsonScreens[
                screenIndex
            ].layout.childrens.splice(componentIndex, 1)
            state.simulator.screenData[screenIndex].layout.childrens.splice(
                componentIndex,
                1
            )

            state.flowJson.TemporaryFlowJsonScreens[
                screenIndex
            ].layout.childrens.splice(dropEventIndex + 1, 0, componentData)
            state.simulator.screenData[screenIndex].layout.childrens.splice(
                dropEventIndex + 1,
                0,
                componentData
            )
            state.showPropertyWindowTab = {
                state: true,
                item: componentData.type,
                value: componentData
            }
        },

        setShowPropertyWindowTab: (state, action) => {
            const { propertyWindowTabValue, item, value } = action.payload
            state.showPropertyWindowTab.state = propertyWindowTabValue
            state.showPropertyWindowTab.item = item
            state.showPropertyWindowTab.value = value
        },

        setShowDropDownItemList: (state, action) => {
            const dropDownItemList = action.payload
            const dropDownComponentIndex = state.dropDownOptionslist.findIndex(
                component => component.u_id === dropDownItemList.u_id
            )

            if (
                state.dropDownOptionslist.length < 1 ||
                state.dropDownOptionslist[dropDownComponentIndex] === null ||
                dropDownComponentIndex === -1
            ) {
                state.dropDownOptionslist = [
                    ...state.dropDownOptionslist,
                    { ...dropDownItemList }
                ]
            } else {
                state.dropDownOptionslist[dropDownComponentIndex] = {
                    ...state.dropDownOptionslist[dropDownComponentIndex],
                    ...dropDownItemList
                }
            }
        },

        changeComponentConfirmationModal: (state, action) => {
            const modalStatus = action.payload
            state.modals.showDeleteComponentConfirmationModal = modalStatus
        },

        onPendingCreateFlow: (state, action) => {
            state.flows.controls.isCreateFlowPending = true
        },

        onSuccessCreateFlow: (state, action) => {
            state.flows.controls.isCreateFlowPending = false
            const { flowId, flowName, category, createdAt } = action.payload
            state.flows.flowId = flowId
            state.flows.flowName = flowName
            state.flows.flowCategory = category
            state.flows.flowCreatedAt = createdAt
        },

        onFailureCreateFlow: (state, action) => {
            state.flows.controls.createFlowErrorMessage = action.payload.message
        },

        onChangeDataChannelUrl: (state, action) => {
            const { dataChannelUrl } = action.payload

            state.flowJson.dataChannelUri = dataChannelUrl
        },



        // callDataChannelUrlOnChecked: (state, action) => {
        //     const {
        //         currentScreenData,
        //         parentComponentData,
        //         setCallDataChannelUrl,
        //         value,
        //         uuid
        //     } = action.payload

        //     const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)

        //     const childIndex = findChildIndex(
        //         state,
        //         indexOfScreen,
        //         parentComponentData
        //     )

        //     const temporaryFlowJsonComponentDataObject =
        //         state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[
        //             childIndex
        //         ].componentData
        //     let getTemporaryFlowJsonValueToUpdate


        //     if (value === 'radioButton') {
        //         state.CallDataChannelUrlOnClicked.state = setCallDataChannelUrl
        //         state.CallDataChannelUrlOnClicked.uuid = uuid
        //     } else if (value === 'dropdown') {
        //         state.CallDataChannelUrlOnClickeddropdown.state = setCallDataChannelUrl
        //         state.CallDataChannelUrlOnClickeddropdown.uuid = uuid
        //     } else if (value === 'checkBox') {
        //         state.CallDataChannelUrlOnClickedCheckBox.state = setCallDataChannelUrl
        //         state.CallDataChannelUrlOnClickedCheckBox.uuid = uuid
        //     }

        //     if (setCallDataChannelUrl) {
        //         getTemporaryFlowJsonValueToUpdate = () => {
        //             if (
        //                 [null, undefined].includes(
        //                     temporaryFlowJsonComponentDataObject.value
        //                 )
        //             ) {
        //                 return {
        //                     'on-select-action': {
        //                         name: 'data_exchange',
        //                         payload: {}
        //                     }
        //                 }
        //             }

        //             if (temporaryFlowJsonComponentDataObject.value instanceof Object) {
        //                 return {
        //                     ...temporaryFlowJsonComponentDataObject.value,
        //                     'on-select-action': {
        //                         name: 'data_exchange',
        //                         payload: {}
        //                     }
        //                 }
        //             }
        //         }

        //         state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[
        //             childIndex
        //         ].componentData.value = getTemporaryFlowJsonValueToUpdate()
        //     } else {
        //         getTemporaryFlowJsonValueToUpdate = () => {
        //             if (
        //                 [null, undefined].includes(
        //                     temporaryFlowJsonComponentDataObject.value
        //                 )
        //             ) {
        //                 return null
        //             }

        //             if (
        //                 temporaryFlowJsonComponentDataObject.value instanceof Object &&
        //                 temporaryFlowJsonComponentDataObject.value['on-select-action']
        //             ) {
        //                 delete temporaryFlowJsonComponentDataObject.value[
        //                     'on-select-action'
        //                 ]
        //                 return {
        //                     ...temporaryFlowJsonComponentDataObject.value
        //                 }
        //             }
        //         }
        //     }
        //     updateTemporaryFlowJson(
        //         state,
        //         indexOfScreen,
        //         childIndex,
        //         getTemporaryFlowJsonValueToUpdate()
        //     )
        // },

        // ----------------------------------------- TEMPLATES ---------------------------------- //

        userDetailTemplateComponent: (state, action) => {
            const { screenData } = action.payload

            const screenIndex = findCurrentScreenIndex(state, screenData)

            let screenComponentToAdd = [
                {
                    screenId: '',
                    u_id: '',
                    type: 'TextInput',
                    index: 0,
                    componentData: {
                        type: 'TextInput',
                        value: {
                            label: 'Name',
                            name: 'name',
                            required: true,
                            'input-type': 'text',
                            visible: true
                        }
                    }
                },
                {
                    screenId: '',
                    u_id: '',
                    type: 'TextInput',
                    index: 0,
                    componentData: {
                        type: 'TextInput',
                        value: {
                            label: 'Email',
                            name: 'email',
                            required: true,
                            'input-type': 'email',
                            visible: true
                        }
                    }
                },
                {
                    screenId: '',
                    u_id: '',
                    type: 'TextArea',
                    index: 1,
                    componentData: {
                        type: 'TextArea',
                        value: {
                            label: 'Address',
                            name: 'address',
                            required: true,
                            visible: true
                        }
                    }
                },
                {
                    screenId: '',
                    u_id: '',
                    type: 'DatePicker',
                    index: 1,
                    componentData: {
                        type: 'DatePicker',
                        value: {
                            label: 'Date Of Birth',
                            name: 'dob',
                            required: true,
                            visible: true
                        }
                    }
                }
            ]

            const updatedScreenDataComponentForUserDetails = screenComponentToAdd.map(
                (item, index) => {
                    return {
                        screenId: screenData.id,
                        u_id: uuid(),
                        type: item.type,
                        index: item.index,
                        componentData: item.componentData
                    }
                }
            )

            state.simulator.screenData[screenIndex].layout.childrens = [
                ...state.simulator.screenData[screenIndex].layout.childrens,
                ...updatedScreenDataComponentForUserDetails
            ]
            state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout.childrens = [
                ...state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout
                    .childrens,
                ...updatedScreenDataComponentForUserDetails
            ]
        },

        // ----------------------------------------- TEMPLATES ---------------------------------- //
        // ====================================== UPDATE flows ==================================
        setFooterOnClickAction: (state, action) => {
            const { currentScreenData, parentComponentData, footerOnClickAction } =
                action.payload

            switch (state.openConditionComponentScreen.type) {
                //for components of 'if
                case 'If': {
                    const indexOfCurrentScreen = state.screen.allScreens.findIndex(
                        screen => screen.id === currentScreenData.id
                    )

                    let payloadToAdd
                    switch (footerOnClickAction) {
                        case 'complete': {
                            state.completeActionInFooterButtonExist = true
                            payloadToAdd = {
                                'on-click-action': {
                                    name: footerOnClickAction,
                                    payload: {}
                                }
                            }
                            break
                        }
                        case 'navigate': {
                            payloadToAdd = {
                                'on-click-action': {
                                    name: footerOnClickAction,
                                    next: {
                                        type: 'screen',
                                        name:
                                            state.screen.allScreens[indexOfCurrentScreen + 1]?.id ??
                                            ''
                                    },
                                    payload: {}
                                }
                            }
                            break
                        }

                        case 'data_exchange': {
                            payloadToAdd = {
                                'on-click-action': {
                                    name: footerOnClickAction,
                                    payload: {}
                                }
                            }
                            break
                        }

                        default:
                            payloadToAdd = {
                                'on-click-action': {
                                    name: footerOnClickAction,
                                    next: {
                                        type: 'screen',
                                        name: ''
                                    },
                                    payload: {}
                                }
                            }
                    }

                    const ifComponentIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )
                    const ifComponentId = state.flowJson?.ifScreens?.findIndex(
                        screen =>
                            screen.u_id === state.openConditionComponentScreen.item.u_id
                    )

                    const childIndex = findChildIndexForConditionalComponentScreen(
                        state,
                        ifComponentId,
                        parentComponentData
                    )
                    const ifScreensComponentData =
                        getComponentDataForConditionalComponentScreen(
                            state,
                            ifComponentIndex,
                            childIndex
                        )

                    if (ifScreensComponentData === null) {
                        updateConditionalComponentScreensJson(
                            state,
                            ifComponentIndex,
                            childIndex,
                            payloadToAdd
                        )
                        updateSimulatorJsonForConditionalComponentScreens(
                            state,
                            ifComponentIndex,
                            childIndex,
                            payloadToAdd
                        )
                    } else {
                        updateConditionalComponentScreensJson(
                            state,
                            ifComponentIndex,
                            childIndex,
                            {
                                ...ifScreensComponentData,
                                ...payloadToAdd
                            }
                        )
                        updateSimulatorJsonForConditionalComponentScreens(
                            state,
                            ifComponentIndex,
                            childIndex,
                            {
                                ...ifScreensComponentData,
                                ...payloadToAdd
                            }
                        )
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                                childIndex
                            ].type
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                                childIndex
                            ].type,

                        value: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                            childIndex
                            ]
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                            childIndex
                            ]
                    }
                    break
                }

                //for components of 'switch'
                case 'Switch': {
                    const indexOfCurrentScreen = state.screen.allScreens.findIndex(
                        screen => screen.id === currentScreenData.id
                    )
                    let payloadToAdd
                    switch (footerOnClickAction) {
                        case 'complete': {
                            state.completeActionInFooterButtonExist = true
                            payloadToAdd = {
                                'on-click-action': {
                                    name: footerOnClickAction,
                                    payload: {}
                                }
                            }
                            break
                        }
                        case 'navigate': {
                            payloadToAdd = {
                                'on-click-action': {
                                    name: footerOnClickAction,
                                    next: {
                                        type: 'screen',
                                        name:
                                            state.screen.allScreens[indexOfCurrentScreen + 1]?.id ??
                                            ''
                                    },
                                    payload: {}
                                }
                            }
                            break
                        }

                        case 'data_exchange': {
                            payloadToAdd = {
                                'on-click-action': {
                                    name: footerOnClickAction,
                                    payload: {}
                                }
                            }
                            break
                        }

                        default:
                            payloadToAdd = {
                                'on-click-action': {
                                    name: footerOnClickAction,
                                    next: {
                                        type: 'screen',
                                        name: ''
                                    },
                                    payload: {}
                                }
                            }
                    }
                    // Find IfScreen
                    const conditionIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )

                    // Find case index
                    const caseIndex = findCaseIndex(state, conditionIndex)

                    // find working component to update value
                    const childIndex = findChildrenIndexForSwitch(
                        state,
                        conditionIndex,
                        parentComponentData,
                        caseIndex
                    )

                    const componentDataValue = getComponentDataForConditionalComponentsSwitch(
                        state,
                        conditionIndex,
                        caseIndex,
                        childIndex
                    )

                    if (componentDataValue === null) {
                        updateConditionalComponentScreensJsonSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            payloadToAdd
                        )
                        updateSimulatorJsonForConditionalComponentSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            payloadToAdd
                        )
                    } else {
                        updateConditionalComponentScreensJsonSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            {
                                ...componentDataValue,
                                ...payloadToAdd
                            }
                        )
                        updateSimulatorJsonForConditionalComponentSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            {
                                ...componentDataValue,
                                ...payloadToAdd
                            }
                        )
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ].type,

                        value: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ]
                    }
                    break
                }

                //for normal components
                default: {
                    const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
                    const childIndex = findChildIndex(
                        state,
                        indexOfScreen,
                        parentComponentData
                    )

                    const temporaryFlowJsonComponentData = getComponentData(
                        state,
                        indexOfScreen,
                        childIndex
                    )

                    const indexOfCurrentScreen = state.screen.allScreens.findIndex(
                        screen => screen.id === currentScreenData.id
                    )

                    let payloadToAdd
                    switch (footerOnClickAction) {
                        case 'complete': {
                            state.completeActionInFooterButtonExist = true
                            payloadToAdd = {
                                'on-click-action': {
                                    name: footerOnClickAction,
                                    payload: {}
                                }
                            }
                            break
                        }
                        case 'navigate': {
                            payloadToAdd = {
                                'on-click-action': {
                                    name: footerOnClickAction,
                                    next: {
                                        type: 'screen',
                                        name:
                                            state.screen.allScreens[indexOfCurrentScreen + 1]?.id ??
                                            ''
                                    },
                                    payload: {}
                                }
                            }
                            break
                        }

                        case 'data_exchange': {
                            payloadToAdd = {
                                'on-click-action': {
                                    name: footerOnClickAction,
                                    payload: {}
                                }
                            }
                            break
                        }

                        default:
                            payloadToAdd = {
                                'on-click-action': {
                                    name: footerOnClickAction,
                                    next: {
                                        type: 'screen',
                                        name: ''
                                    },
                                    payload: {}
                                }
                            }
                    }

                    if (temporaryFlowJsonComponentData === null) {
                        updateTemporaryFlowJson(
                            state,
                            indexOfScreen,
                            childIndex,
                            payloadToAdd
                        )
                        updateSimulatorJson(state, indexOfScreen, childIndex, payloadToAdd)
                    } else {
                        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
                            ...temporaryFlowJsonComponentData,
                            ...payloadToAdd
                        })
                        updateSimulatorJson(state, indexOfScreen, childIndex, {
                            ...temporaryFlowJsonComponentData,
                            ...payloadToAdd
                        })
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].type,
                        value:
                            state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                                .childrens[childIndex]
                    }
                    break
                }
            }
        },

        setFooterScreenName: (state, action) => {
            const { currentScreenData, parentComponentData, screenName } =
                action.payload

            switch (state.openConditionComponentScreen.type) {
                //for components of 'if
                case 'If': {
                    const ifComponentIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )
                    const ifComponentId = state.flowJson?.ifScreens?.findIndex(
                        screen =>
                            screen.u_id === state.openConditionComponentScreen.item.u_id
                    )

                    const childIndex = findChildIndexForConditionalComponentScreen(
                        state,
                        ifComponentId,
                        parentComponentData
                    )
                    const ifScreensComponentData =
                        getComponentDataForConditionalComponentScreen(
                            state,
                            ifComponentIndex,
                            childIndex
                        )
                    let payloadToAdd
                    payloadToAdd = {
                        ...ifScreensComponentData,
                        'on-click-action': {
                            ...ifScreensComponentData['on-click-action'],
                            next: {
                                type: 'screen',
                                name: screenName
                            },
                            payload: {}
                        }
                    }

                    if (ifScreensComponentData === null) {
                        updateConditionalComponentScreensJson(
                            state,
                            ifComponentIndex,
                            childIndex,
                            payloadToAdd
                        )
                        updateSimulatorJsonForConditionalComponentScreens(
                            state,
                            ifComponentIndex,
                            childIndex,
                            payloadToAdd
                        )
                    } else {
                        updateConditionalComponentScreensJson(
                            state,
                            ifComponentIndex,
                            childIndex,
                            {
                                ...ifScreensComponentData,
                                ...payloadToAdd
                            }
                        )
                        updateSimulatorJsonForConditionalComponentScreens(
                            state,
                            ifComponentIndex,
                            childIndex,
                            {
                                ...ifScreensComponentData,
                                ...payloadToAdd
                            }
                        )
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                                childIndex
                            ].type
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                                childIndex
                            ].type,

                        value: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                            childIndex
                            ]
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                            childIndex
                            ]
                    }
                    break
                }

                //for components of 'switch'
                case 'Switch': {
                    // Find IfScreen
                    const conditionIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )
                    // Find case index
                    const caseIndex = findCaseIndex(state, conditionIndex)

                    // find working component to update value
                    const childIndex = findChildrenIndexForSwitch(
                        state,
                        conditionIndex,
                        parentComponentData,
                        caseIndex
                    )

                    const componentDataValue = getComponentDataForConditionalComponentsSwitch(
                        state,
                        conditionIndex,
                        caseIndex,
                        childIndex
                    )

                    let payloadToAdd
                    payloadToAdd = {
                        ...componentDataValue,
                        'on-click-action': {
                            ...componentDataValue['on-click-action'],
                            next: {
                                type: 'screen',
                                name: screenName
                            },
                            payload: {}
                        }
                    }



                    if (componentDataValue === null) {
                        updateConditionalComponentScreensJsonSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            payloadToAdd
                        )
                        updateSimulatorJsonForConditionalComponentSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            payloadToAdd
                        )
                    } else {
                        updateConditionalComponentScreensJsonSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            {
                                ...componentDataValue,
                                ...payloadToAdd
                            }
                        )
                        updateSimulatorJsonForConditionalComponentSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            {
                                ...componentDataValue,
                                ...payloadToAdd
                            }
                        )
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ].type,

                        value: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ]
                    }
                    break
                }

                //for normal components
                default: {
                    const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
                    //component index
                    const childIndex = findChildIndex(
                        state,
                        indexOfScreen,
                        parentComponentData
                    )

                    const temporaryFlowJsonComponentData = getComponentData(
                        state,
                        indexOfScreen,
                        childIndex
                    )
                    let payloadToAdd
                    payloadToAdd = {
                        ...temporaryFlowJsonComponentData,
                        'on-click-action': {
                            ...temporaryFlowJsonComponentData['on-click-action'],
                            next: {
                                type: 'screen',
                                name: screenName
                            },
                            payload: {}
                        }
                    }
                    if (temporaryFlowJsonComponentData === null) {
                        updateTemporaryFlowJson(
                            state,
                            indexOfScreen,
                            childIndex,
                            payloadToAdd
                        )
                        updateSimulatorJson(state, indexOfScreen, childIndex, payloadToAdd)
                    } else {
                        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
                            ...temporaryFlowJsonComponentData,
                            ...payloadToAdd
                        })
                        updateSimulatorJson(state, indexOfScreen, childIndex, {
                            ...temporaryFlowJsonComponentData,
                            ...payloadToAdd
                        })
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].type,
                        value:
                            state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                                .childrens[childIndex]
                    }
                    break
                }
            }
        },

        // text Input & text Area
        addOutputVariable: (state, action) => {
            const { currentScreenData, parentComponentData, inputDataToUpdate, type } =
                action.payload
            const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)

            // ========================== Create Output variable state =======================
            const variables = [...state.flowJson.outputVariable]

            const findWorkingComponent = variables.findIndex(
                key => key.u_id === parentComponentData.u_id
            )
            // 
            if (inputDataToUpdate.hasOwnProperty("input-type")) {
                // THIS DISPATCH CALL TO UPDATE TYPE ONLY
                if (findWorkingComponent !== -1) {
                    // Update in existing outputVariable arrays
                    state.flowJson.outputVariable[findWorkingComponent].type = type
                } else {
                    // Add new data 
                    // IT'S MEANS OUTPUT VARIABLE NOT CREATE FOR THAT COMPONENT SO NAME WILL BE EMPTY
                    // WITHOUT ADDING OUTPUT VARIABLE THEY SELECT INPUT-TYPE
                    const payloadToAdd = {
                        variableName: "",
                        screenId: parentComponentData.screenId,
                        index: indexOfScreen,
                        u_id: parentComponentData.u_id,
                        title: currentScreenData.title,
                        type: type ? type : "string"
                    }
                    variables.push(payloadToAdd)

                    state.flowJson.outputVariable = variables
                }
            } else {
                // UPDATE VARIABLE NAME ALWAYS
                if (findWorkingComponent !== -1) {
                    // Update in existing outputVariable arrays
                    state.flowJson.outputVariable[findWorkingComponent].variableName =
                        inputDataToUpdate.name
                } else {
                    //Add new data
                    const payloadToAdd = {
                        variableName: inputDataToUpdate.name,
                        screenId: parentComponentData.screenId,
                        index: indexOfScreen,
                        u_id: parentComponentData.u_id,
                        title: currentScreenData.title,
                        type: type ? type : "string"
                    }
                    variables.push(payloadToAdd)

                    state.flowJson.outputVariable = variables
                }
            }

            // ========================== Create Output variable state =======================

            // TODO -> using screen id change output variable name if used in next screen
        },

        deleteOutputVariableOfComponent: (state, action) => {
            const { u_id } = action.payload
            // update array of output variable
            const updateOutputVariable = state.flowJson.outputVariable.filter(variable => variable.u_id !== u_id);
            state.flowJson.outputVariable = updateOutputVariable;
        },

        addInitValue: (state, action) => {
            const { currentScreenData, parentComponentData, inputDataToUpdate } =
                action.payload

            switch (state.openConditionComponentScreen.type) {
                //for components of 'if
                case 'If': {
                    const ifComponentIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )
                    const ifComponentId = state.flowJson?.ifScreens?.findIndex(
                        screen =>
                            screen.u_id === state.openConditionComponentScreen.item.u_id
                    )

                    const childIndex = findChildIndexForConditionalComponentScreen(
                        state,
                        ifComponentId,
                        parentComponentData
                    )

                    // const temporaryFlowJsonComponentData =
                    //   state.flowJson.TemporaryFlowJsonScreens[ifComponentIndex].layout.childrens[
                    //     childIndex
                    //   ].componentData

                    const ifScreensComponentData =
                        getComponentDataForConditionalComponentScreenComponentData(
                            state,
                            ifComponentIndex,
                            childIndex
                        )


                    let componentData
                    if (ifScreensComponentData === null) {
                        componentData = {
                            value: null,
                            'init-values': {
                                value: inputDataToUpdate.value,
                                screenId: inputDataToUpdate.screenId
                            }
                        }
                    } else {
                        componentData = {
                            // value: ifScreensComponentData,  //Remove type 
                            ...ifScreensComponentData,
                            'init-values': {
                                value: inputDataToUpdate.value,
                                screenId: inputDataToUpdate.screenId
                            }
                        }
                    }
                    // TODO -> using screen id change output variable name if used in next screen

                    if (state.openConditionComponentScreen.success) {
                        state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                            childIndex
                        ].componentData = componentData

                        state.ifScreenSimulator.ifComponents[
                            ifComponentIndex
                        ].then[0].childrens[childIndex].componentData = componentData
                    } else {
                        state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                            childIndex
                        ].componentData = componentData

                        state.ifScreenSimulator.ifComponents[
                            ifComponentIndex
                        ].else[0].childrens[childIndex].componentData = componentData
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                                childIndex
                            ].type
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                                childIndex
                            ].type,

                        value: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                            childIndex
                            ]
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                            childIndex
                            ]
                    }
                    break
                }

                //for components of 'switch'
                case 'Switch': {
                    // Find IfScreen
                    const conditionIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )

                    // Find case index
                    const caseIndex = findCaseIndex(state, conditionIndex)

                    // find working component to update value
                    const childIndex = findChildrenIndexForSwitch(
                        state,
                        conditionIndex,
                        parentComponentData,
                        caseIndex
                    )

                    const getComponentData = getComponentDataForSwitch(
                        state,
                        conditionIndex,
                        caseIndex,
                        childIndex
                    )


                    let componentData
                    if (getComponentData === null) {
                        componentData = {
                            value: null,
                            'init-values': {
                                value: inputDataToUpdate.value,
                                screenId: inputDataToUpdate.screenId
                            }
                        }
                    } else {
                        componentData = {
                            ...getComponentData,
                            'init-values': {
                                value: inputDataToUpdate.value,
                                screenId: inputDataToUpdate.screenId
                            }
                        }
                    }
                    // TODO -> using screen id change output variable name if used in next screen

                    state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                        childIndex
                    ].componentData = componentData

                    state.ifScreenSimulator.ifComponents[
                        conditionIndex
                    ].cases[caseIndex].childrens[childIndex].componentData = componentData

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ].type,

                        value: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ]
                    }
                    break
                }

                //for normal components
                default: {
                    const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
                    const childIndex = findChildIndex(
                        state,
                        indexOfScreen,
                        parentComponentData
                    )

                    const temporaryFlowJsonComponentData =
                        state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].componentData

                    let componentData
                    if (temporaryFlowJsonComponentData === null) {
                        componentData = {
                            'init-values': {
                                value: inputDataToUpdate.value,
                                screenId: inputDataToUpdate.screenId
                            }
                        }
                    } else {
                        componentData = {
                            ...temporaryFlowJsonComponentData,
                            'init-values': {
                                value: inputDataToUpdate.value,
                                screenId: inputDataToUpdate.screenId
                            }
                        }
                    }
                    // TODO -> using screen id change output variable name if used in next screen

                    state.flowJson.TemporaryFlowJsonScreens[
                        indexOfScreen
                    ].layout.childrens[childIndex].componentData = componentData

                    state.simulator.screenData[indexOfScreen].layout.childrens[
                        childIndex
                    ].componentData = componentData

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].type,
                        value:
                            state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                                .childrens[childIndex]
                    }
                    break
                }
            }
        },

        // fallback to handle publish whatsApp flow
        updateOnclickAction: (state, action) => {
            const { allScreens, TemporaryFlowJsonScreens } = action.payload

            const lengthOfAllScreen = allScreens.length
            //to update footer button value we have to travel all TemporaryFlowJsonScreens & find footer screen and update values.
            if (
                TemporaryFlowJsonScreens &&
                Array.isArray(TemporaryFlowJsonScreens) &&
                TemporaryFlowJsonScreens.length > 0
            ) {
                for (let index = 0; index < TemporaryFlowJsonScreens.length; index++) {
                    const indexOfScreen = index
                    const screenChildren =
                        TemporaryFlowJsonScreens[index].layout.childrens

                    if (
                        screenChildren &&
                        Array.isArray(screenChildren) &&
                        screenChildren.length > 0
                    ) {
                        for (
                            let childIndex = 0;
                            childIndex < screenChildren.length;
                            childIndex++
                        ) {
                            const element = screenChildren[childIndex]
                            if (element.type === 'Footer') {
                                //on-click-action not present in previous publish flow
                                if (
                                    element.componentData.value &&
                                    !element.componentData.value['on-click-action']
                                ) {
                                    let payloadToAdd
                                    if (indexOfScreen + 1 < lengthOfAllScreen) {
                                        //find next screen name
                                        const screenName = allScreens[indexOfScreen + 1].id ?? ''
                                        payloadToAdd = {
                                            ...element.componentData.value,
                                            'on-click-action': {
                                                name: 'navigate',
                                                next: {
                                                    type: 'screen',
                                                    name: screenName
                                                },
                                                payload: {}
                                            }
                                        }
                                    } else {
                                        //last screen flow
                                        payloadToAdd = {
                                            ...element.componentData.value,
                                            'on-click-action': {
                                                name: 'complete',
                                                payload: {}
                                            }
                                        }
                                    }
                                    const temporaryFlowJsonComponentData = getComponentData(
                                        state,
                                        indexOfScreen,
                                        childIndex
                                    )

                                    //value if null
                                    if (temporaryFlowJsonComponentData === null) {
                                        updateTemporaryFlowJson(
                                            state,
                                            indexOfScreen,
                                            childIndex,
                                            payloadToAdd
                                        )
                                    } else {
                                        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
                                            ...temporaryFlowJsonComponentData,
                                            ...payloadToAdd
                                        })
                                    }
                                }
                            } else {
                                continue
                            }
                        }
                    }
                }
            }
        },

        addArrayTypeInput: (state, action) => {
            const {
                currentScreenData,
                parentComponentData,
                inputDataToUpdate,
                inputDataKey
            } = action.payload

            switch (state.openConditionComponentScreen.type) {
                //for components of 'if
                case 'If': {
                    const ifComponentIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )
                    // const ifComponentId = state.flowJson?.ifScreens?.findIndex(
                    //     screen =>
                    //         screen.u_id === state.openConditionComponentScreen.item.u_id
                    // )

                    const childIndex = findChildIndexForConditionalComponentScreen(
                        state,
                        ifComponentIndex,
                        parentComponentData
                    )
                    const ifScreensComponentData =
                        getComponentDataForConditionalComponentScreen(
                            state,
                            ifComponentIndex,
                            childIndex
                        )
                    if (ifScreensComponentData === null) {
                        // this case will create an array in value obj of componentData
                        const dataToUpdate = {
                            [inputDataKey]: inputDataToUpdate
                        }
                        updateConditionalComponentScreensJson(
                            state,
                            ifComponentIndex,
                            childIndex,
                            dataToUpdate
                        )
                        updateSimulatorJsonForConditionalComponentScreens(
                            state,
                            ifComponentIndex,
                            childIndex,
                            dataToUpdate
                        )
                    } else {
                        let previousValue;
                        if (state.openConditionComponentScreen.success) {
                            previousValue = state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                                childIndex
                            ].componentData.value
                        } else {
                            previousValue = state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                                childIndex
                            ].componentData.value
                        }

                        previousValue[inputDataKey] = inputDataToUpdate

                        updateConditionalComponentScreensJson(
                            state,
                            ifComponentIndex,
                            childIndex,
                            {
                                ...ifScreensComponentData,
                                ...previousValue //contains whole updated obj of value in componentData
                            }
                        )
                        updateSimulatorJsonForConditionalComponentScreens(
                            state,
                            ifComponentIndex,
                            childIndex,
                            {
                                ...ifScreensComponentData,
                                ...previousValue //contains whole updated obj of value in componentData
                            }
                        )
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                                childIndex
                            ].type
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                                childIndex
                            ].type,

                        value: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                            childIndex
                            ]
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                            childIndex
                            ]
                    }
                    break
                }

                //for components of 'switch'
                case 'Switch': {
                    // Find IfScreen
                    const conditionIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )
                    // Find case index
                    const caseIndex = findCaseIndex(state, conditionIndex)

                    // find working component to update value
                    const childIndex = findChildrenIndexForSwitch(
                        state,
                        conditionIndex,
                        parentComponentData,
                        caseIndex
                    )

                    const componentDataValue = getComponentDataForConditionalComponentsSwitch(
                        state,
                        conditionIndex,
                        caseIndex,
                        childIndex
                    )
                    if (componentDataValue === null) {
                        // this case will create an array in value obj of componentData
                        const dataToUpdate = {
                            [inputDataKey]: inputDataToUpdate
                        }

                        updateConditionalComponentScreensJsonSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            dataToUpdate
                        )
                        updateSimulatorJsonForConditionalComponentSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            dataToUpdate
                        )
                    } else {
                        const dataToUpdate = {
                            [inputDataKey]: inputDataToUpdate
                        }
                        updateConditionalComponentScreensJsonSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            {
                                ...componentDataValue,
                                ...dataToUpdate
                            }
                        )
                        updateSimulatorJsonForConditionalComponentSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            {
                                ...componentDataValue,
                                ...dataToUpdate
                            }
                        )
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ].type,

                        value: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ]
                    }
                    break
                }

                //for normal components
                default: {
                    const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
                    const childIndex = findChildIndex(
                        state,
                        indexOfScreen,
                        parentComponentData
                    )
                    const temporaryFlowJsonComponentData = getComponentData(
                        state,
                        indexOfScreen,
                        childIndex
                    )

                    if (temporaryFlowJsonComponentData === null) {
                        // this case will create an array in value obj of componentData
                        const dataToUpdate = {
                            [inputDataKey]: inputDataToUpdate
                        }
                        updateTemporaryFlowJson(
                            state,
                            indexOfScreen,
                            childIndex,
                            dataToUpdate
                        )
                        updateSimulatorJson(state, indexOfScreen, childIndex, dataToUpdate)
                    } else {
                        let previousValue =
                            state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                                .childrens[childIndex].componentData.value

                        previousValue[inputDataKey] = inputDataToUpdate

                        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
                            ...temporaryFlowJsonComponentData,
                            ...previousValue //contains whole updated obj of value in componentData
                        })
                        updateSimulatorJson(state, indexOfScreen, childIndex, {
                            ...temporaryFlowJsonComponentData,
                            ...previousValue
                        })
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].type,
                        value:
                            state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                                .childrens[childIndex]
                    }
                    break
                }
            }
        },

        publishFlowsDisable: (state, action) => {
            state.disableComponentsForPublishFlow = true
        },
        addConstantVariableForEmbeddedAndOptIn: (state, action) => {
            const { currentScreenData, parentComponentData, inputDataToUpdate } =
                action.payload
            const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
            const childIndex = findChildIndex(
                state,
                indexOfScreen,
                parentComponentData
            )

            state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[
                childIndex
            ].componentData.value['on-click-action'].payload = {
                ...state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                    .childrens[childIndex].componentData.value['on-click-action'].payload,
                ...inputDataToUpdate
            }
        },
        showPropertyWindowTabClose: (state, action) => {
            state.showPropertyWindowTab = {
                state: false,
                item: null,
                value: null
            }

            state.outputAndDynamicVariables = []
        },

        //
        unavailableDatesAdded: (state, action) => {
            const { currentScreenData, parentComponentData, inputDataToUpdate } =
                action.payload

            let updatedUnavailable = []

            switch (state.openConditionComponentScreen.type) {
                case 'If': {
                    const ifComponentIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )
                    const ifComponentId = state.flowJson?.ifScreens?.findIndex(
                        screen =>
                            screen.u_id === state.openConditionComponentScreen.item.u_id
                    )

                    const childIndex = findChildIndexForConditionalComponentScreen(
                        state,
                        ifComponentId,
                        parentComponentData
                    )
                    if (
                        parentComponentData.componentData.value !== null &&
                        Object.keys(parentComponentData.componentData.value).includes(
                            'unavailable-dates'
                        )
                    ) {
                        if (
                            Array.isArray(
                                parentComponentData.componentData.value['unavailable-dates']
                            ) &&
                            parentComponentData.componentData.value['unavailable-dates'].length >
                            0
                        ) {
                            const unavailableDates =
                                parentComponentData.componentData.value['unavailable-dates']
                            updatedUnavailable = [...unavailableDates]
                            updatedUnavailable.push(inputDataToUpdate)
                        } else {
                            updatedUnavailable.push(inputDataToUpdate)
                        }
                    } else {
                        updatedUnavailable.push(inputDataToUpdate)
                    }

                    const inputDataToUpdateUnavailableDate = {
                        'unavailable-dates': updatedUnavailable
                    }

                    const ifScreensComponentData =
                        getComponentDataForConditionalComponentScreen(
                            state,
                            ifComponentIndex,
                            childIndex
                        )

                    if (ifScreensComponentData === null) {
                        updateConditionalComponentScreensJson(
                            state,
                            ifComponentIndex,
                            childIndex,
                            inputDataToUpdateUnavailableDate
                        )
                        updateSimulatorJsonForConditionalComponentScreens(
                            state,
                            ifComponentIndex,
                            childIndex,
                            inputDataToUpdateUnavailableDate
                        )
                    } else {
                        updateConditionalComponentScreensJson(
                            state,
                            ifComponentIndex,
                            childIndex,
                            {
                                ...ifScreensComponentData,
                                ...inputDataToUpdateUnavailableDate
                            }
                        )
                        updateSimulatorJsonForConditionalComponentScreens(
                            state,
                            ifComponentIndex,
                            childIndex,
                            {
                                ...ifScreensComponentData,
                                ...inputDataToUpdateUnavailableDate
                            }
                        )
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                                childIndex
                            ].type
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                                childIndex
                            ].type,

                        value: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                            childIndex
                            ]
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                            childIndex
                            ]
                    }
                    break
                }
                case 'Switch': {
                    // Find IfScreen
                    const conditionIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )
                    // Find case index
                    const caseIndex = findCaseIndex(state, conditionIndex)

                    // find working component to update value
                    const childIndex = findChildrenIndexForSwitch(
                        state,
                        conditionIndex,
                        parentComponentData,
                        caseIndex
                    )

                    const componentDataValue = getComponentDataForConditionalComponentsSwitch(
                        state,
                        conditionIndex,
                        caseIndex,
                        childIndex
                    )

                    if (
                        parentComponentData.componentData.value !== null &&
                        Object.keys(parentComponentData.componentData.value).includes(
                            'unavailable-dates'
                        )
                    ) {
                        if (
                            Array.isArray(
                                parentComponentData.componentData.value['unavailable-dates']
                            ) &&
                            parentComponentData.componentData.value['unavailable-dates'].length >
                            0
                        ) {
                            const unavailableDates =
                                parentComponentData.componentData.value['unavailable-dates']
                            updatedUnavailable = [...unavailableDates]
                            updatedUnavailable.push(inputDataToUpdate)
                        } else {
                            updatedUnavailable.push(inputDataToUpdate)
                        }
                    } else {
                        updatedUnavailable.push(inputDataToUpdate)
                    }

                    const inputDataToUpdateUnavailableDate = {
                        'unavailable-dates': updatedUnavailable
                    }
                    if (componentDataValue === null) {

                        updateConditionalComponentScreensJsonSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            inputDataToUpdateUnavailableDate
                        )
                        updateSimulatorJsonForConditionalComponentSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            inputDataToUpdateUnavailableDate
                        )
                    } else {
                        updateConditionalComponentScreensJsonSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            {
                                ...componentDataValue,
                                ...inputDataToUpdateUnavailableDate
                            }
                        )
                        updateSimulatorJsonForConditionalComponentSwitch(
                            state,
                            conditionIndex,
                            caseIndex,
                            childIndex,
                            {
                                ...componentDataValue,
                                ...inputDataToUpdateUnavailableDate
                            }
                        )
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ].type,

                        value: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ]
                    }
                    break
                }

                default: {
                    const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
                    const childIndex = findChildIndex(
                        state,
                        indexOfScreen,
                        parentComponentData
                    )
                    const temporaryFlowJsonComponentData = getComponentData(
                        state,
                        indexOfScreen,
                        childIndex
                    )

                    if (
                        parentComponentData.componentData.value !== null &&
                        Object.keys(parentComponentData.componentData.value).includes(
                            'unavailable-dates'
                        )
                    ) {
                        if (
                            Array.isArray(
                                parentComponentData.componentData.value['unavailable-dates']
                            ) &&
                            parentComponentData.componentData.value['unavailable-dates'].length >
                            0
                        ) {
                            const unavailableDates =
                                parentComponentData.componentData.value['unavailable-dates']
                            updatedUnavailable = [...unavailableDates]
                            updatedUnavailable.push(inputDataToUpdate)
                        } else {
                            updatedUnavailable.push(inputDataToUpdate)
                        }
                    } else {
                        updatedUnavailable.push(inputDataToUpdate)
                    }

                    const inputDataToUpdateUnavailableDate = {
                        'unavailable-dates': updatedUnavailable
                    }

                    if (temporaryFlowJsonComponentData === null) {
                        updateTemporaryFlowJson(
                            state,
                            indexOfScreen,
                            childIndex,
                            inputDataToUpdateUnavailableDate
                        )
                        updateSimulatorJson(
                            state,
                            indexOfScreen,
                            childIndex,
                            inputDataToUpdateUnavailableDate
                        )
                    } else {
                        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
                            ...temporaryFlowJsonComponentData,
                            ...inputDataToUpdateUnavailableDate
                        })
                        updateSimulatorJson(state, indexOfScreen, childIndex, {
                            ...temporaryFlowJsonComponentData,
                            ...inputDataToUpdateUnavailableDate
                        })
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].type,
                        value:
                            state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                                .childrens[childIndex]
                    }
                    break;
                }
            }

        },

        // ====================================== UPDATE flows ==================================
        //-------------------------------------------If--------------------------------------------//

        onClickConditionComponentButton: (state, action) => {
            const { showIfSuccess, item, success, type, caseName, isSwitchComponent, caseId } = action.payload
            state.openConditionComponentScreen = {
                state: showIfSuccess,
                item: item,
                success: success,
                type: type,
                caseName: caseName,
                isSwitchComponent: isSwitchComponent,
                caseId: caseId
            }
        },

        // onFailureCreateFlow: (state, action) => {
        //   state.flows.controls.createFlowErrorMessage = action.payload.message
        // },

        addComponentToIfScreensJson: (state, action) => {
            const { componentData, screenData, index } = action.payload
            const screenIndex = findCurrentScreenIndex(state, screenData)

            const ifComponentId = state.flowJson?.ifScreens?.findIndex(
                screen => screen.u_id === state.openConditionComponentScreen.item.u_id
            )

            //for if component
            // if (componentData.type === 'If') {
            const u_id =
                screenData.id +
                '_' +
                componentData.type +
                '_' +
                Math.floor(Math.random() * 1000) +
                '_' +
                state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout.childrens
                    .length
            const payloadToAddInIfScreensComponentArray = {
                screenId: screenData.id,
                u_id: u_id,
                type: componentData.type,
                index,
                componentData: {
                    type: componentData.type,
                    value: null
                }
            }
            //push component data in then
            if (
                state.openConditionComponentScreen.state === true &&
                state.openConditionComponentScreen.success === true
            ) {
                //adding in ifScreens
                state.flowJson.ifScreens[ifComponentId].then[0].childrens.push(
                    payloadToAddInIfScreensComponentArray
                )
                //adding in ifScreenSimulator
                state.ifScreenSimulator.ifComponents[
                    ifComponentId
                ].then[0].childrens.push(payloadToAddInIfScreensComponentArray)
            }
            //push component data in else
            else {
                //adding in ifScreens
                state.flowJson.ifScreens[ifComponentId].else[0].childrens.push(
                    payloadToAddInIfScreensComponentArray
                )
                //adding in ifScreenSimulator
                state.ifScreenSimulator.ifComponents[
                    ifComponentId
                ].else[0].childrens.push(payloadToAddInIfScreensComponentArray)
            }

            state.showPropertyWindowTab = {
                state: true,
                item: componentData.type,
                value: payloadToAddInIfScreensComponentArray
            }

            if (componentData.type === 'If') {
                const payloadToAddInIfScreen = {
                    screenId: screenData.id,
                    u_id: u_id,
                    parentData: {
                        root_u_id: state.flowJson.ifScreens[ifComponentId].parentData
                            .root_u_id
                            ? state.flowJson.ifScreens[ifComponentId].parentData.root_u_id
                            : state.flowJson.ifScreens[ifComponentId].u_id,
                        u_id: state.flowJson.ifScreens[ifComponentId].u_id,
                        type:
                            state.openConditionComponentScreen.success === true
                                ? 'then'
                                : 'else'
                    },
                    then: [
                        {
                            id: screenData.id,
                            title: screenData.title,
                            childrens: []
                        }
                    ],
                    else: [
                        {
                            id: screenData.id,
                            title: screenData.title,
                            childrens: []
                        }
                    ]
                }
                state.flowJson.ifScreens.push(payloadToAddInIfScreen)

                const payloadToAddInIfScreenSimulatorData = {
                    screenId: screenData.id,
                    u_id: u_id,
                    parentData: {
                        root_u_id: state.flowJson.ifScreens[ifComponentId].parentData
                            .root_u_id
                            ? state.flowJson.ifScreens[ifComponentId].parentData.root_u_id
                            : state.flowJson.ifScreens[ifComponentId].u_id,
                        u_id: state.flowJson.ifScreens[ifComponentId].u_id,
                        type:
                            state.openConditionComponentScreen.success === true
                                ? 'then'
                                : 'else'
                    },
                    then: [
                        {
                            id: screenData.id,
                            title: screenData.title,
                            childrens: []
                        }
                    ],
                    else: [
                        {
                            id: screenData.id,
                            title: screenData.title,
                            childrens: []
                        }
                    ]
                }

                state.ifScreenSimulator.ifComponents.push(
                    payloadToAddInIfScreenSimulatorData
                )
            } else if (componentData.type === 'Switch') {

                const payloadToAddInIfScreen = {
                    screenId: screenData.id,
                    u_id: u_id,
                    parentData: {
                        root_u_id: null,
                        u_id: null,
                        type: null
                    },
                    cases: []
                }
                // added into ifScreen Array
                state.flowJson.ifScreens.push(payloadToAddInIfScreen)

                state.ifScreenSimulator.ifComponents.push(
                    payloadToAddInIfScreen
                )

            }
        },
        addComponentToIfScreensJsonForSwitch: (state, action) => {
            const { componentData, screenData, index } = action.payload
            const screenIndex = findCurrentScreenIndex(state, screenData)

            const conditionComponentIndex = findConditionalComponentIndex(
                state,
                screenData
            )

            const u_id =
                screenData.id +
                '_' +
                componentData.type +
                '_' +
                Math.floor(Math.random() * 1000) +
                '_' +
                state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout.childrens
                    .length
            const payloadToAddInIfScreensComponentArray = {
                screenId: screenData.id,
                u_id: u_id,
                type: componentData.type,
                index,
                componentData: {
                    type: componentData.type,
                    value: null
                }
            }

            const caseIndex = findCaseIndex(state, conditionComponentIndex)


            state.flowJson.ifScreens[conditionComponentIndex].cases[caseIndex].childrens.push(
                payloadToAddInIfScreensComponentArray
            );


            state.ifScreenSimulator.ifComponents[
                conditionComponentIndex
            ].cases[caseIndex].childrens.push(payloadToAddInIfScreensComponentArray);

            state.showPropertyWindowTab = {
                state: true,
                item: componentData.type,
                value: payloadToAddInIfScreensComponentArray
            }

            if (componentData.type === 'If') {
                const payloadToAddInIfScreen = {
                    screenId: screenData.id,
                    u_id: u_id,
                    parentData: {
                        root_u_id: state.flowJson.ifScreens[conditionComponentIndex].parentData
                            .root_u_id
                            ? state.flowJson.ifScreens[conditionComponentIndex].parentData.root_u_id
                            : state.flowJson.ifScreens[conditionComponentIndex].u_id,
                        u_id: state.flowJson.ifScreens[conditionComponentIndex].u_id,
                        type:
                            state.openConditionComponentScreen.success === true
                                ? 'then'
                                : 'else'
                    },
                    then: [
                        {
                            id: screenData.id,
                            title: screenData.title,
                            childrens: []
                        }
                    ],
                    else: [
                        {
                            id: screenData.id,
                            title: screenData.title,
                            childrens: []
                        }
                    ]
                }
                state.flowJson.ifScreens.push(payloadToAddInIfScreen)

                const payloadToAddInIfScreenSimulatorData = {
                    screenId: screenData.id,
                    u_id: u_id,
                    parentData: {
                        root_u_id: state.flowJson.ifScreens[conditionComponentIndex].parentData
                            .root_u_id
                            ? state.flowJson.ifScreens[conditionComponentIndex].parentData.root_u_id
                            : state.flowJson.ifScreens[conditionComponentIndex].u_id,
                        u_id: state.flowJson.ifScreens[conditionComponentIndex].u_id,
                        type:
                            state.openConditionComponentScreen.success === true
                                ? 'then'
                                : 'else'
                    },
                    then: [
                        {
                            id: screenData.id,
                            title: screenData.title,
                            childrens: []
                        }
                    ],
                    else: [
                        {
                            id: screenData.id,
                            title: screenData.title,
                            childrens: []
                        }
                    ]
                }

                state.ifScreenSimulator.ifComponents.push(
                    payloadToAddInIfScreenSimulatorData
                )
            } else if (componentData.type === 'Switch') {

                const payloadToAddInIfScreen = {
                    screenId: screenData.id,
                    u_id: u_id,
                    parentData: {
                        root_u_id: null,
                        u_id: null,
                        type: null
                    },
                    cases: []
                }
                // added into ifScreen Array
                state.flowJson.ifScreens.push(payloadToAddInIfScreen)

                state.ifScreenSimulator.ifComponents.push(
                    payloadToAddInIfScreen
                )

            }
        },

        deleteChildrenComponent: (state, action) => {
            const { currentScreen, parentComponentData } = action.payload

            // const ifComponentId = state.flowJson?.ifScreens?.findIndex(
            //     screen => screen.u_id === state.openConditionComponentScreen.item.u_id
            // )
            // WORKING TYPE 
            switch (state.openConditionComponentScreen.type) {
                case 'If': {
                    const conditionIndex = findConditionalComponentIndex(
                        state,
                        currentScreen
                    )

                    if (
                        state.openConditionComponentScreen.state &&
                        state.openConditionComponentScreen.success
                    ) {
                        const childIndex = state.flowJson.ifScreens[
                            conditionIndex
                        ].then[0].childrens.findIndex(
                            component => component.u_id === parentComponentData.u_id
                        )

                        state.flowJson.ifScreens[conditionIndex].then[0].childrens.splice(
                            childIndex,
                            1
                        )
                        state.ifScreenSimulator.ifComponents[
                            conditionIndex
                        ].then[0].childrens.splice(childIndex, 1)
                    }
                    //for if component's else screen
                    else {
                        const childIndex = state.flowJson.ifScreens[
                            conditionIndex
                        ].else[0].childrens.findIndex(
                            component => component.u_id === parentComponentData.u_id
                        )

                        state.flowJson.ifScreens[conditionIndex].else[0].childrens.splice(
                            childIndex,
                            1
                        )
                        state.ifScreenSimulator.ifComponents[
                            conditionIndex
                        ].else[0].childrens.splice(childIndex, 1)
                    }
                    state.showPropertyWindowTab = {
                        state: false,
                        item: ''
                    }
                    break;
                }

                case "Switch": {
                    const conditionIndex = findConditionalComponentIndex(
                        state,
                        currentScreen
                    )

                    // Find case index
                    const caseIndex = findCaseIndex(state, conditionIndex)

                    // find working component to update value
                    const childIndex = findChildrenIndexForSwitch(
                        state,
                        conditionIndex,
                        parentComponentData,
                        caseIndex
                    );

                    state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens.splice(
                        childIndex,
                        1
                    )
                    state.ifScreenSimulator.ifComponents[
                        conditionIndex
                    ].cases[caseIndex].childrens.splice(childIndex, 1);

                    state.showPropertyWindowTab = {
                        state: false,
                        item: ''
                    }
                    break
                }

                // for temporary flow json
                default: {
                    const indexOfScreen = findCurrentScreenIndex(state, currentScreen)
                    const childIndex = findChildIndex(
                        state,
                        indexOfScreen,
                        parentComponentData
                    )

                    state.flowJson.TemporaryFlowJsonScreens[
                        indexOfScreen
                    ].layout.childrens.splice(childIndex, 1)
                    state.simulator.screenData[indexOfScreen].layout.childrens.splice(
                        childIndex,
                        1
                    )
                    state.showPropertyWindowTab = {
                        state: false,
                        item: ''
                    }

                    // Component id is always unique so find with u_id And delete Output variable
                    if (
                        state.flowJson.outputVariable &&
                        Array.isArray(state.flowJson.outputVariable)
                    ) {
                        const deleteOutputVariableOnClickDeleteComponent =
                            state.flowJson.outputVariable.findIndex(
                                key => key.u_id === parentComponentData.u_id
                            )
                        if (deleteOutputVariableOnClickDeleteComponent !== -1) {
                            state.flowJson.outputVariable.splice(
                                deleteOutputVariableOnClickDeleteComponent,
                                1
                            )
                        }
                    }

                    break;
                }
            }

        },


        // delete If component from ifScreen Array
        deleteConditionComponentFromIfScreen: (state, action) => {
            const { currentScreen, parentComponentData } = action.payload

            const ifComponentIdIndex = state.flowJson?.ifScreens?.findIndex(
                screen => screen.u_id === parentComponentData.u_id
            )

            const findNestedIfComponent = state.flowJson?.ifScreens.find((screen) => screen.u_id === parentComponentData.u_id);
            // const ifScreenData = [];
            for (let index = 0; index < findNestedIfComponent.then[0].childrens.length; index++) {
                const element = findNestedIfComponent.then[0].childrens[index];
                if (element.type === "If") {
                    deleteNestedIfScreenComponent(state, element.u_id)
                }
                // same for switch

            }

            for (let index = 0; index < findNestedIfComponent.else[0].childrens.length; index++) {
                const element = findNestedIfComponent.else[0].childrens[index];
                if (element.type === "If") {
                    deleteNestedIfScreenComponent(state, element.u_id)
                }
                // same for switch
            }


            //Delete parent component

            state.flowJson.ifScreens.splice(ifComponentIdIndex, 1)

            const indexToDeleteFromIfComponentSimulator =
                state.ifScreenSimulator.ifComponents?.findIndex(
                    screen => screen.u_id === parentComponentData.u_id
                )
            state.ifScreenSimulator.ifComponents.splice(
                indexToDeleteFromIfComponentSimulator,
                1
            )

        },
        deleteConditionComponentFromSwitch: (state, action) => {
            const { currentScreen, parentComponentData } = action.payload

            const ifComponentIdIndex = state.flowJson?.ifScreens?.findIndex(
                screen => screen.u_id === parentComponentData.u_id
            )

            const findNestedIfComponent = state.flowJson?.ifScreens.find((screen) => screen.u_id === parentComponentData.u_id);
            // TODO
            // const ifScreenData = [];
            // for (let index = 0; index < findNestedIfComponent.then[0].childrens.length; index++) {
            //     const element = findNestedIfComponent.then[0].childrens[index];
            //     if (element.type === "If") {
            //         deleteNestedIfScreenComponent(state, element.u_id)
            //     }
            //     // same for switch
            // }

            // for (let index = 0; index < findNestedIfComponent.else[0].childrens.length; index++) {
            //     const element = findNestedIfComponent.else[0].childrens[index];
            //     if (element.type === "If") {
            //         deleteNestedIfScreenComponent(state, element.u_id)
            //     }
            //     // same for switch
            // }


            //Delete parent component

            state.flowJson.ifScreens.splice(ifComponentIdIndex, 1)

            const indexToDeleteFromIfComponentSimulator =
                state.ifScreenSimulator.ifComponents?.findIndex(
                    screen => screen.u_id === parentComponentData.u_id
                );

            state.ifScreenSimulator.ifComponents.splice(
                indexToDeleteFromIfComponentSimulator,
                1
            )

        },
        addCaseToSwitch: (state, action) => {
            const { currentScreenData, parentComponentData, buttonDataToUpdate } =
                action.payload

            const switchComponentIndex = findSwitchComponentIndex(state)

            state.flowJson.switchCasesScreen[switchComponentIndex].cases.push({
                caseName: buttonDataToUpdate,
                childrens: []
            })

            // state.ifConditionBlockForPropertyWindow.push({
            //   u_id: parentComponentData.u_id,
            //   conditionBlockValues: [
            //     {
            //       operatorForBlockComparison: null,
            //       compareTo: null,
            //       operator: null,
            //       compareWith: null
            //     }
            //   ]
            // })

            // state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[childIndex].componentData.value = getTemporaryFlowJsonValueToUpdate();
            // state.simulator.screenData[indexOfScreen].layout.childrens[childIndex].componentData.value = getScreenDataValueToUpdate();
        },

        addValueInComponentData: (state, action) => {
            const { currentScreenData, parentComponentData, inputDataToUpdate } =
                action.payload

            switch (state.openConditionComponentScreen.type) {
                case 'If': {
                    const ifComponentIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )
                    // It might be delete 
                    const ifComponentId = state.flowJson?.ifScreens?.findIndex(
                        screen =>
                            screen.u_id === state.openConditionComponentScreen.item.u_id
                    )
                    const childIndex = findChildIndexForConditionalComponentScreen(
                        state,
                        ifComponentId,
                        parentComponentData
                    )

                    const ifScreensComponentData =
                        getComponentDataForConditionalComponentScreenComponentData(
                            state,
                            ifComponentIndex,
                            childIndex
                        )

                    let componentData
                    if (ifScreensComponentData === null) {
                        componentData = {
                            ...inputDataToUpdate
                        }
                    } else {
                        componentData = {
                            ...ifScreensComponentData,
                            ...inputDataToUpdate
                        }
                    }
                    // TODO -> using screen id change output variable name if used in next screen

                    if (state.openConditionComponentScreen.success) {
                        state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                            childIndex
                        ].componentData = componentData

                        state.ifScreenSimulator.ifComponents[
                            ifComponentIndex
                        ].then[0].childrens[childIndex].componentData = componentData
                    } else {
                        state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                            childIndex
                        ].componentData = componentData

                        state.ifScreenSimulator.ifComponents[
                            ifComponentIndex
                        ].else[0].childrens[childIndex].componentData = componentData
                    }

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                                childIndex
                            ].type
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                                childIndex
                            ].type,

                        value: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                            childIndex
                            ]
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                            childIndex
                            ]
                    }
                    break
                }

                //for components of 'switch'
                case 'Switch': {
                    const conditionIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )

                    // Find case index
                    const caseIndex = findCaseIndex(state, conditionIndex)

                    // find working component to update value
                    // find working component to update value
                    const childIndex = findChildrenIndexForSwitch(
                        state,
                        conditionIndex,
                        parentComponentData,
                        caseIndex
                    )
                    const getComponentData = getComponentDataForSwitch(
                        state,
                        conditionIndex,
                        caseIndex,
                        childIndex
                    );

                    let componentData
                    if (getComponentData === null) {
                        componentData = {
                            ...inputDataToUpdate
                        }
                    } else {
                        componentData = {
                            ...getComponentData,
                            ...inputDataToUpdate
                        }
                    }
                    // TODO -> using screen id change output variable name if used in next screen

                    state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                        childIndex
                    ].componentData = componentData

                    state.ifScreenSimulator.ifComponents[
                        conditionIndex
                    ].cases[caseIndex].childrens[childIndex].componentData = componentData;

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ].type,
                        value: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ]
                    }
                    break
                }

                //for normal components
                default: {
                    const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
                    const childIndex = findChildIndex(
                        state,
                        indexOfScreen,
                        parentComponentData
                    )

                    const temporaryFlowJsonComponentData =
                        state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].componentData

                    let componentData
                    if (temporaryFlowJsonComponentData === null) {
                        componentData = {
                            ...inputDataToUpdate
                        }
                    } else {
                        componentData = {
                            ...temporaryFlowJsonComponentData,
                            ...inputDataToUpdate
                        }
                    }

                    state.flowJson.TemporaryFlowJsonScreens[
                        indexOfScreen
                    ].layout.childrens[childIndex].componentData = componentData

                    state.simulator.screenData[indexOfScreen].layout.childrens[
                        childIndex
                    ].componentData = componentData

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].type,
                        value:
                            state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                                .childrens[childIndex]
                    }
                    break
                }
            }
        },

        // switch case name update into ifScreen and simulator
        addSwitchCaseName: (state, action) => {
            const { currentScreenData, parentComponentData, inputDataToUpdate } =
                action.payload

            // Here we update case into ifScreen array.
            const conditionalComponentIndex = state.flowJson.ifScreens.findIndex((key) => key.u_id === parentComponentData.u_id)
            // update cases Array.
            state.flowJson.ifScreens[conditionalComponentIndex] = {
                ...state.flowJson.ifScreens[conditionalComponentIndex],
                cases: inputDataToUpdate.cases
            }

            //update into simulator
            const simulatorCurrentlyWorkingIndex = state.ifScreenSimulator.ifComponents.findIndex((key) => key.u_id === parentComponentData.u_id)

            state.ifScreenSimulator.ifComponents[simulatorCurrentlyWorkingIndex] = {
                ...state.ifScreenSimulator.ifComponents[simulatorCurrentlyWorkingIndex],
                cases: inputDataToUpdate.cases
            }
        },


        // If inputDataToUpdate.checked is true then add select option else delete
        // Get data from API
        addOnSelectOptionToGetDataFromApi: (state, action) => {
            const { currentScreenData, parentComponentData, inputDataToUpdate } =
                action.payload

            switch (state.openConditionComponentScreen.type) {
                case 'If': {
                    const ifComponentIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )
                    const ifComponentId = state.flowJson?.ifScreens?.findIndex(
                        screen =>
                            screen.u_id === state.openConditionComponentScreen.item.u_id
                    )

                    const childIndex = findChildIndexForConditionalComponentScreen(
                        state,
                        ifComponentId,
                        parentComponentData
                    )
                    const temporaryFlowJsonComponentData = getComponentDataForConditionalComponentScreenComponentData(
                        state,
                        ifComponentIndex,
                        childIndex
                    )

                    let getTemporaryFlowJsonValueToUpdate

                    // If checked is true then add select option else delete
                    if (inputDataToUpdate.getDataFromApi) {
                        getTemporaryFlowJsonValueToUpdate = () => {
                            if (
                                [null, undefined].includes(
                                    temporaryFlowJsonComponentData.value
                                )
                            ) {
                                return {
                                    'on-select-action': {
                                        name: 'data_exchange',
                                        payload: {}
                                    }
                                }
                            }

                            if (temporaryFlowJsonComponentData.value instanceof Object) {
                                return {
                                    ...temporaryFlowJsonComponentData.value,
                                    'on-select-action': {
                                        name: 'data_exchange',
                                        payload: {}
                                    }
                                }
                            }
                        }

                        if (state.openConditionComponentScreen.success) {
                            state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                                childIndex
                            ].componentData.value = getTemporaryFlowJsonValueToUpdate()
                        } else {
                            state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                                childIndex
                            ].componentData.value = getTemporaryFlowJsonValueToUpdate()
                        }

                    } else {
                        getTemporaryFlowJsonValueToUpdate = () => {
                            if (
                                [null, undefined].includes(
                                    temporaryFlowJsonComponentData.value
                                )
                            ) {
                                return null
                            }

                            if (
                                temporaryFlowJsonComponentData.value instanceof Object &&
                                temporaryFlowJsonComponentData.value['on-select-action']
                            ) {
                                delete temporaryFlowJsonComponentData.value[
                                    'on-select-action'
                                ]
                                return {
                                    ...temporaryFlowJsonComponentData.value
                                }
                            }
                        }
                    }

                    updateConditionalComponentScreensJson(
                        state,
                        ifComponentIndex,
                        childIndex,
                        getTemporaryFlowJsonValueToUpdate()
                    )

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                                childIndex
                            ].type
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                                childIndex
                            ].type,

                        value: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[ifComponentIndex].then[0].childrens[
                            childIndex
                            ]
                            : state.flowJson.ifScreens[ifComponentIndex].else[0].childrens[
                            childIndex
                            ]
                    }
                    break
                }
                case 'Switch': {
                    // Find IfScreen
                    const conditionIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )

                    // Find case index
                    const caseIndex = findCaseIndex(state, conditionIndex)

                    // find working component to update value
                    const childIndex = findChildrenIndexForSwitch(
                        state,
                        conditionIndex,
                        parentComponentData,
                        caseIndex
                    )

                    const temporaryFlowJsonComponentData = state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                        childIndex
                    ].componentData

                    let getTemporaryFlowJsonValueToUpdate

                    if (inputDataToUpdate.getDataFromApi) {
                        getTemporaryFlowJsonValueToUpdate = () => {
                            if (
                                [null, undefined].includes(
                                    temporaryFlowJsonComponentData.value
                                )
                            ) {
                                return {
                                    'on-select-action': {
                                        name: 'data_exchange',
                                        payload: {}
                                    }
                                }
                            }

                            if (temporaryFlowJsonComponentData.value instanceof Object) {
                                return {
                                    ...temporaryFlowJsonComponentData.value,
                                    'on-select-action': {
                                        name: 'data_exchange',
                                        payload: {}
                                    }
                                }
                            }
                        }

                        state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ].componentData.value = getTemporaryFlowJsonValueToUpdate()
                    } else {
                        getTemporaryFlowJsonValueToUpdate = () => {
                            if (
                                [null, undefined].includes(
                                    temporaryFlowJsonComponentData.value
                                )
                            ) {
                                return null
                            }

                            if (
                                temporaryFlowJsonComponentData.value instanceof Object &&
                                temporaryFlowJsonComponentData.value['on-select-action']
                            ) {
                                delete temporaryFlowJsonComponentData.value[
                                    'on-select-action'
                                ]
                                return {
                                    ...temporaryFlowJsonComponentData.value
                                }
                            }
                        }
                    }

                    updateConditionalComponentScreensJsonSwitch(
                        state,
                        conditionIndex,
                        caseIndex,
                        childIndex,
                        getTemporaryFlowJsonValueToUpdate()
                    )

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ].type,
                        value: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ]
                    }
                    break
                }

                default: {
                    const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
                    const childIndex = findChildIndex(
                        state,
                        indexOfScreen,
                        parentComponentData
                    )

                    const temporaryFlowJsonComponentData =
                        state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].componentData

                    let getTemporaryFlowJsonValueToUpdate

                    if (temporaryFlowJsonComponentData == null) {
                        if (
                            temporaryFlowJsonComponentData.value instanceof Object &&
                            temporaryFlowJsonComponentData.value['on-select-action']
                        ) {
                            delete temporaryFlowJsonComponentData.value[
                                'on-select-action'
                            ]
                            return {
                                ...temporaryFlowJsonComponentData.value
                            }
                        }
                    }

                    if (inputDataToUpdate.getDataFromApi) {
                        getTemporaryFlowJsonValueToUpdate = () => {
                            if (
                                [null, undefined].includes(
                                    temporaryFlowJsonComponentData.value
                                )
                            ) {
                                return {
                                    'on-select-action': {
                                        name: 'data_exchange',
                                        payload: {}
                                    }
                                }
                            }

                            if (temporaryFlowJsonComponentData.value instanceof Object) {
                                return {
                                    ...temporaryFlowJsonComponentData.value,
                                    'on-select-action': {
                                        name: 'data_exchange',
                                        payload: {}
                                    }
                                }
                            }
                        }

                        state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[
                            childIndex
                        ].componentData.value = getTemporaryFlowJsonValueToUpdate()
                    } else {
                        getTemporaryFlowJsonValueToUpdate = () => {
                            if (
                                [null, undefined].includes(
                                    temporaryFlowJsonComponentData.value
                                )
                            ) {
                                return null
                            }

                            if (
                                temporaryFlowJsonComponentData.value instanceof Object &&
                                temporaryFlowJsonComponentData.value['on-select-action']
                            ) {
                                delete temporaryFlowJsonComponentData.value[
                                    'on-select-action'
                                ]
                                return {
                                    ...temporaryFlowJsonComponentData.value
                                }
                            }
                        }
                    }
                    updateTemporaryFlowJson(
                        state,
                        indexOfScreen,
                        childIndex,
                        getTemporaryFlowJsonValueToUpdate()
                    )

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].type,
                        value:
                            state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                                .childrens[childIndex]
                    }
                    break
                }
            }
        },
        deleteUnavailableDates: (state, action) => {
            const { index, currentScreenData, parentComponentData } = action.payload
            const indexOfScreen = findCurrentScreenIndex(state, currentScreenData)
            const childIndex = findChildIndex(
                state,
                indexOfScreen,
                parentComponentData
            )

            switch (state.openConditionComponentScreen.type) {
                //for if components then screen
                case "If": {

                    const conditionIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )

                    //----------------to delete from ifScreens then
                    const childIndex =
                        findChildIndexForConditionalComponentScreen(
                            state,
                            conditionIndex,
                            parentComponentData
                        )

                    // success Screen
                    if (
                        state.openConditionComponentScreen.state === true &&
                        state.openConditionComponentScreen.success === true
                    ) {

                        state.flowJson.ifScreens[conditionIndex].then[0].childrens[
                            childIndex
                        ].componentData.value['unavailable-dates'].splice(index, 1)
                        //----------------to delete from ifScreens Simulator then
                        state.ifScreenSimulator.ifComponents[indexOfScreen].then[0].childrens[
                            childIndex
                        ].componentData.value['unavailable-dates'].splice(index, 1)
                    }
                    //for if components else screen
                    else {

                        state.flowJson.ifScreens[conditionIndex].else[0].childrens[
                            childIndex
                        ].componentData.value['unavailable-dates'].splice(index, 1)

                        //---------------to delete from ifScreens Simulator else
                        state.ifScreenSimulator.ifComponents[indexOfScreen].else[0].childrens[
                            childIndex
                        ].componentData.value['unavailable-dates'].splice(index, 1)
                    }

                    // update in property menu 
                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[conditionIndex].then[0].childrens[
                                childIndex
                            ].type
                            : state.flowJson.ifScreens[conditionIndex].else[0].childrens[
                                childIndex
                            ].type,

                        value: state.openConditionComponentScreen.success
                            ? state.flowJson.ifScreens[conditionIndex].then[0].childrens[
                            childIndex
                            ]
                            : state.flowJson.ifScreens[conditionIndex].else[0].childrens[
                            childIndex
                            ]
                    }
                    break;
                }
                // #TODO
                case 'Switch': {
                    // Find IfScreen
                    const conditionIndex = findConditionalComponentIndex(
                        state,
                        currentScreenData
                    )
                    // Find case index
                    const caseIndex = findCaseIndex(state, conditionIndex)

                    // find working component to update value
                    const childIndex = findChildrenIndexForSwitch(
                        state,
                        conditionIndex,
                        parentComponentData,
                        caseIndex
                    )



                    state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                        childIndex
                    ].componentData.value['unavailable-dates'].splice(index, 1)

                    //---------------to delete from ifScreens Simulator else
                    state.ifScreenSimulator.ifComponents[indexOfScreen].cases[caseIndex].childrens[
                        childIndex
                    ].componentData.value['unavailable-dates'].splice(index, 1)

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ].type,

                        value: state.flowJson.ifScreens[conditionIndex].cases[caseIndex].childrens[
                            childIndex
                        ]
                    }
                    break
                }

                default: {
                    //to delete from temporaryFlowJson
                    state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[
                        childIndex
                    ].componentData.value['unavailable-dates'].splice(index, 1)
                    //to delete from temporaryFlowJson simulator
                    state.simulator.screenData[indexOfScreen].layout.childrens[
                        childIndex
                    ].componentData.value['unavailable-dates'].splice(index, 1);

                    state.showPropertyWindowTab = {
                        state: true,
                        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                            .childrens[childIndex].type,
                        value:
                            state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
                                .childrens[childIndex]
                    }
                    break;
                }
            }
        },

        // IF USER WORKING ON IF ELSE CONDITION AND ADDED COMPONENT FOR THEN
        updateTemporaryComponentArrayOnDropEventForIfScreen: (state, action) => {
            const { screenData, componentData, componentIndex, dropEventIndex } =
                action.payload
            const screenIndex = findCurrentScreenIndex(state, screenData)

            const ifComponentIndex = findConditionalComponentIndex(
                state,
                screenData
            )

            switch (componentData.type) {
                case 'If': {
                    const u_id =
                        screenData.id +
                        '_' +
                        componentData.type +
                        '_' +
                        Math.floor(Math.random() * 1000) +
                        '_' +
                        state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout
                            .childrens.length
                    const payloadToAddInTemoporaryComponentArray = {
                        screenId: screenData.id,
                        u_id: u_id,
                        type: componentData.type,
                        componentData: {
                            type: componentData.type,
                            value: null
                        }
                    }

                    if (state.openConditionComponentScreen.state && state.openConditionComponentScreen.success) {
                        state.flowJson.ifScreens[ifComponentIndex].then[0].childrens.splice(
                            dropEventIndex + 1,
                            0,
                            payloadToAddInTemoporaryComponentArray
                        )
                        state.ifScreenSimulator.ifComponents[ifComponentIndex].then[0].childrens.splice(
                            dropEventIndex + 1,
                            0,
                            payloadToAddInTemoporaryComponentArray
                        )
                    } else {
                        state.flowJson.ifScreens[ifComponentIndex].else[0].childrens.splice(
                            dropEventIndex + 1,
                            0,
                            payloadToAddInTemoporaryComponentArray
                        )
                        state.ifScreenSimulator.ifComponents[ifComponentIndex].else[0].childrens.splice(
                            dropEventIndex + 1,
                            0,
                            payloadToAddInTemoporaryComponentArray
                        )
                    }

                    const payloadToAddInIfScreen = {
                        screenId: screenData.id,
                        u_id: u_id,
                        parentData: {
                            root_u_id: state.flowJson.ifScreens[ifComponentIndex].parentData
                                .root_u_id
                                ? state.flowJson.ifScreens[ifComponentIndex].parentData.root_u_id
                                : state.flowJson.ifScreens[ifComponentIndex].u_id,
                            u_id: state.flowJson.ifScreens[ifComponentIndex].u_id,
                            type:
                                state.openConditionComponentScreen.success === true
                                    ? 'then'
                                    : 'else'
                        },
                        then: [
                            {
                                id: screenData.id,
                                title: screenData.title,
                                childrens: []
                            }
                        ],
                        else: [
                            {
                                id: screenData.id,
                                title: screenData.title,
                                childrens: []
                            }
                        ]
                    }

                    state.flowJson.ifScreens.push(payloadToAddInIfScreen)
                    state.ifScreenSimulator.ifComponents.push(
                        payloadToAddInIfScreen
                    )

                    state.showPropertyWindowTab = {
                        state: true,
                        item: componentData.type,
                        value: payloadToAddInTemoporaryComponentArray
                    }
                    break
                }

                case 'Switch': {
                    const u_id =
                        screenData.id +
                        '_' +
                        componentData.type +
                        '_' +
                        Math.floor(Math.random() * 1000) +
                        '_' +
                        state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout
                            .childrens.length

                    const payloadToAddInTemoporaryComponentArray = {
                        screenId: screenData.id,
                        u_id: u_id,
                        type: componentData.type,
                        componentData: {
                            type: componentData.type,
                            value: null
                        }
                    }

                    if (state.openConditionComponentScreen.state && state.openConditionComponentScreen.success) {
                        state.flowJson.ifScreens[ifComponentIndex].then[0].childrens.splice(
                            dropEventIndex + 1,
                            0,
                            payloadToAddInTemoporaryComponentArray
                        )
                        state.ifScreenSimulator.ifComponents[ifComponentIndex].then[0].childrens.splice(
                            dropEventIndex + 1,
                            0,
                            payloadToAddInTemoporaryComponentArray
                        )
                    } else {
                        state.flowJson.ifScreens[ifComponentIndex].else[0].childrens.splice(
                            dropEventIndex + 1,
                            0,
                            payloadToAddInTemoporaryComponentArray
                        )
                        state.ifScreenSimulator.ifComponents[ifComponentIndex].else[0].childrens.splice(
                            dropEventIndex + 1,
                            0,
                            payloadToAddInTemoporaryComponentArray
                        )
                    }

                    // Switch Cases added
                    const payloadToAddInSwitchCasesScreen = {
                        screenId: screenData.id,
                        u_id: u_id,
                        parentData: { // #TODO
                            root_u_id: null,
                            u_id: null,
                            type: null
                        },
                        cases: [], // default will added when switch condition render.
                    }

                    state.flowJson.ifScreens.push(payloadToAddInSwitchCasesScreen)

                    state.ifScreenSimulator.ifComponents.push(
                        payloadToAddInSwitchCasesScreen
                    )
                    state.showPropertyWindowTab = {
                        state: true,
                        item: componentData.type,
                        value: payloadToAddInTemoporaryComponentArray
                    }
                    break
                }

                default: {
                    // WORKING INSIDE IF CONDITION AND ADDED COMPONENT FOR IF CONDITION
                    const payloadToAddInTemoporaryComponentArray = {
                        screenId: screenData.id,
                        u_id:
                            screenData.id +
                            '_' +
                            componentData.type +
                            '_' +
                            Math.floor(Math.random() * 1000) +
                            '_' +
                            state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout.childrens
                                .length,
                        type: componentData.type,
                        index: componentIndex,
                        componentData: {
                            type: componentData.type,
                            value: null
                        }
                    }

                    if (state.openConditionComponentScreen.state && state.openConditionComponentScreen.success) {
                        state.flowJson.ifScreens[ifComponentIndex].then[0].childrens.splice(
                            dropEventIndex + 1,
                            0,
                            payloadToAddInTemoporaryComponentArray
                        )
                        state.ifScreenSimulator.ifComponents[ifComponentIndex].then[0].childrens.splice(
                            dropEventIndex + 1,
                            0,
                            payloadToAddInTemoporaryComponentArray
                        )
                    } else {
                        state.flowJson.ifScreens[ifComponentIndex].else[0].childrens.splice(
                            dropEventIndex + 1,
                            0,
                            payloadToAddInTemoporaryComponentArray
                        )
                        state.ifScreenSimulator.ifComponents[ifComponentIndex].else[0].childrens.splice(
                            dropEventIndex + 1,
                            0,
                            payloadToAddInTemoporaryComponentArray
                        )
                    }
                    // On Drag & Drop Event - create if screen Array
                    state.showPropertyWindowTab = {
                        state: true,
                        item: componentData.type,
                        value: payloadToAddInTemoporaryComponentArray
                    }
                    break
                }
            }

        },

    },

    //----------------------------------extra-Reducers-----------------------------------------
    extraReducers: builder => {
        //-> get all flow

        builder.addCase(GetAllFlowThunk.pending, (state, action) => {
            state.showGetAllFlowLoader = true

            state.showgetAllFlowErrormessage = ''
        })

        builder.addCase(GetAllFlowThunk.fulfilled, (state, action) => {
            state.showGetAllFlowLoader = false

            state.showgetAllFlowErrormessage = ''

            state.allFlow = action.payload.data
        })

        builder.addCase(GetAllFlowThunk.rejected, (state, action) => {
            state.showGetAllFlowLoader = false

            state.showgetAllFlowErrormessage = action.error.message
        })

        builder.addCase(createFlowJson.pending, (state, action) => {
            state.flowJson.finalFlowJson = action.meta.arg.jsonData
        })

        builder.addCase(createFlowJson.fulfilled, (state, action) => {
            state.updatedFlowData = action.payload
        })

        builder.addCase(createFlowJson.rejected, (state, action) => { })

        builder.addCase(handleViewJsonThunk.pending, (state, action) => { })

        builder.addCase(handleViewJsonThunk.fulfilled, (state, action) => {
            state.flowJson.flowJsonToShow = action.payload
            state.flowJson.showJsonModal = true
        })

        builder.addCase(handleViewJsonThunk.rejected, (state, action) => { })
    }
})

export const {
    resetAllStates,
    setFlowNameAndCategory,
    addComponentToTemporaryFlowComponents,
    setNewScreen,
    changeCreateScreenModal,
    changeCurrentFlowScreen,
    deleteScreen,
    saveFlowComponentValueToTemporaryFlowJson,
    deleteDataElementFromSlice,
    // deleteComponetFromSlice,
    addDropDownLabel,
    addDropDownItems,
    deleteDropDownItem,
    setTextHeadingAndSubHeading,
    addButtonLabel,
    addButtonItems,
    pushButtonItemsInArray,
    deleteButtonItem,
    deleteVariableExample,
    addInputLabel,
    setInputComponentRequiredField,
    setTextBodyInput,
    setDatePicker,
    setImageSrc,
    setNameOfFooterLabel,
    setFooterAction,
    updateTemporaryComponentArrayOnDropEvent,
    setShowPropertyWindowTab,
    setShowDropDownItemList,
    clearDropDownListState,
    setFlowAndScreenData,
    updateFlowName,
    setFlowListingData,
    updateTemporaryArrayOnRenderingComponentDroping,
    changeComponentConfirmationModal,
    onChangeScreenDrop,
    addExtraVariable,
    // deleteDataChannelUrlComponent,
    onChangeDataChannelUrl,
    pushDynamicButtonOptions,
    addConstantVariableToButton,
    callDataChannelUrlOnChecked,
    callDataChannelUrlOnCheckedDropDown,
    callDataChannelUrlOnCheckedCheckBox,
    userDetailTemplateComponent,
    deleteDynamicVariableInSlice,
    setFooterOnClickAction,
    setFooterScreenName,
    addOutputVariable,
    addInitValue,
    updateOnclickAction,
    addArrayTypeInput,
    publishFlowsDisable,
    onClickConditionComponentButton,
    addComponentToIfScreensJson,
    deleteChildrenComponent,
    deleteConditionComponentFromIfScreen,
    generateConditionForIf,
    addConstantVariableForEmbeddedAndOptIn,
    showPropertyWindowTabClose,
    unavailableDatesAdded,
    addCaseToSwitch,
    addValueInComponentData,
    deleteOutputVariableOfComponent,
    deleteConditionComponentFromSwitch,
    addSwitchCaseName,
    addComponentToIfScreensJsonForSwitch,
    addOnSelectOptionToGetDataFromApi,
    deleteUnavailableDates,
    updateTemporaryComponentArrayOnDropEventForIfScreen
} = whatsAppFlowSlice.actions

export default whatsAppFlowSlice.reducer

// ======================================================= THUNK =====================================================

export const GetAllFlowThunk = createAsyncThunk('get-all-flows', async () => {
    try {
        const response = await axiosInstance.get(`flows/get-all-flows`)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        throw error.message
    }
})

export const CreateFlowThunk = createAsyncThunk(
    'create-flow',
    async flowdata => {
        try {
            const response = await axiosInstance.post(`flows/create-flow`, flowdata)
            if (response.status === 200) {
                return response.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
)

export const createFlowJson = createAsyncThunk(
    'update-flow-json',
    async flowObjectToUpdate => {
        try {
            const response = await axiosInstance.post(
                `flows/update-json-flow`,
                flowObjectToUpdate
            )
            if (response.status === 200) {
                return response.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
)

export const handleViewJsonThunk = createAsyncThunk(
    'get-Flow-Json',
    async ({ flowId }) => {
        try {
            const response = await axiosInstance.get(`flows/get-flow-json/${flowId}`)
            if (response.status === 200) {
                return response.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
)
