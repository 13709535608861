import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createWorkflowServices, deleteWorkflowService } from "../builderLogic/builderStoreLogic";
// import axiosInstance from "../utils/workflowAxios";

export let STATUS = {
    IDLE: 'idle',
    LOADING: 'Loading',
    ERROR: 'error'
}

const processWorkflowListSlice = createSlice({
    name: "process workflow list",
    initialState: {
        data: [],
        subWorkflows: [],
        page: 1,
        searchText: '',
        container: { id: '', name: '' },
        totalCount: 0,
        snackbar: { open: false, severity: "", message: "" },
        totalErrors: 0,
        status: STATUS.IDLE
    },
    reducers: {
        SetProcessWorkflowList: (state, action) => {
            state.data = action.payload;
        },
        RemoveProcessWorkflow: (state, action) => {
            state.data = action.payload;
        },
        UpdateTotalError: (state, action) => {
            return {
                ...state,
                totalErrors: action.payload.totalErrors
            }
        },
        CloseSnackBar: (state, action) => {
            return {
                ...state,
                snackbar: {
                    open: false,
                    message: "",
                    severity: "",
                },
            };
        },
        ShowSnackBar: (state, action) => {
            return {
                ...state,
                snackbar: {
                    open: true,
                    message: action.payload.message,
                    severity: action.payload.severity,
                },
            };
        },
        SetContainerDataForProcesses: (state, action) => {
            return {
                ...state,
                container: { id: action.payload.containerId, name: action.payload.containerName }
            };
        },

        SetProcessSubWorkflowList: (state, action) => {
            return {
                ...state,
                subWorkflows: action.payload.subWorkflows,
                totalCount: action.payload.totalCount,
            };
        },
        UpdateProcessWorkflowSearchTextPage: (state, action) => {
            return {
                ...state,
                page: action.payload.page,
                searchText: action.payload.searchText,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            // create workflow
            .addCase(createProcessWorkflow.pending, (state, action) => {
                state.status = STATUS.LOADING;
            })
            .addCase(createProcessWorkflow.fulfilled, (state, action) => {
                if (action.payload.data) {
                    state.snackbar = {
                        open: true, message: action.payload.message, severity: "success"
                    }
                } else {
                    state.snackbar = {
                        open: true, message: action.payload.message, severity: "error"
                    }
                }
                state.status = STATUS.IDLE;
            })
            .addCase(createProcessWorkflow.rejected, (state, action) => {
                state.status = STATUS.ERROR;
            })

            // delete workflow
            .addCase(deleteProcessWorkflow.pending, (state, action) => {
                state.status = STATUS.LOADING;
            })
            .addCase(deleteProcessWorkflow.fulfilled, (state, action) => {
                if (action.payload._id) {
                    let id = action.payload._id;
                    let workflowList = state.data;
                    state.data = workflowList.filter(workflow => workflow._id !== id);
                    state.snackbar = {
                        open: true, message: action.payload.message, severity: "success"
                    }
                } else {
                    state.snackbar = {
                        open: true, message: action.payload.message, severity: "error"
                    }
                }
                state.status = STATUS.IDLE;
            })
            .addCase(deleteProcessWorkflow.rejected, (state, action) => {
                state.status = STATUS.ERROR;
            })
    }

});

export const { SetProcessWorkflowList, SetProcessSubWorkflowList, UpdateProcessWorkflowSearchTextPage, UpdateTotalError, CloseSnackBar, ShowSnackBar, SetContainerDataForProcesses } = processWorkflowListSlice.actions;

export default processWorkflowListSlice.reducer;

// Thunks async  actions
export const deleteProcessWorkflow = createAsyncThunk('workflow/delete', async ({ botId, workflowId }) => {
    return await deleteWorkflowService(botId, workflowId);
});

export const createProcessWorkflow = createAsyncThunk('workflow/create', async ({ name, botId }) => {
    return await createWorkflowServices({ name, botId });
});