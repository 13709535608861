import { processWorkflowService } from "../../pages/pages/Bots/workflow-builder/service/processWorkflow.service";
import { workflowService } from "../../pages/pages/Bots/workflow-builder/service/workflow.service";
import { workflowTypes } from "../../utils/Constants/WorkflowConstants";

class SubWorkflowValidationException {
    message = '';
    constructor(message) {
        this.message = message;
    }
}

export class SubWorkflowValidation {
    #processWorkflowService = processWorkflowService;
    #workflowService = workflowService;

    #botId = '';
    #containerId = '';
    #workflowType = '';
    /**
     * Maintaining the fetched sub-workflow array => subWorkflowListWithUsedSubWorkflowIdArrayAndName,
     * so if the a particular workflow is used 4 time then we will fetch the record only once and use this array for remain.
     * @type { workflowId: string, name: string, usedSubWorkflowIdArray: string[] } []
     */
    #subWorkflowListWithUsedSubWorkflowIdArrayAndName = [];

    constructor(botId, containerId, workflowType) {
        this.#botId = botId;
        this.#containerId = containerId;
        this.#workflowType = workflowType;
    }


    async findRecursiveCallAndGenerateMessage({ usedSubWorkflowIdArray, subWorkflowId, subWorkflowName, workflowId }) {
        const result = { isRecursive: false, recursiveSubworkflowName: '' };
        try {
            const traversedSubworkflowIdsObject = { [subWorkflowId]: true };
            this.#subWorkflowListWithUsedSubWorkflowIdArrayAndName.push({ workflowId: subWorkflowId, name: subWorkflowName, usedSubWorkflowIdArray });
            await this.#traverseUsedSubWorkflow({ usedSubWorkflowIdArray, traversedSubworkflowIdsObject, subWorkflowName });
        } catch (error) {
            if (error instanceof SubWorkflowValidationException) {
                result.isRecursive = true;
                result.recursiveSubworkflowName = error.message;
                console.error(JSON.stringify({ message: 'Recursive call of sub-workflow encountered', subWorkflowName: error.message }));
            } else {
                console.error(JSON.stringify({ message: 'Error occurred during traverseUsedSubWorkflow function.', error, errorMessage: error.message }));
            }
        } finally {
            return result;
        }
    }

    async #traverseUsedSubWorkflow({ usedSubWorkflowIdArray, traversedSubworkflowIdsObject, subWorkflowName }) {
        if (usedSubWorkflowIdArray && Array.isArray(usedSubWorkflowIdArray) && usedSubWorkflowIdArray.length > 0) {
            for (let index = 0; index < usedSubWorkflowIdArray.length; index++) {
                const subWorkflowId = usedSubWorkflowIdArray[index];

                // ==================  Recursive Call Validation : START ===================
                if (traversedSubworkflowIdsObject[subWorkflowId]) {
                    throw new SubWorkflowValidationException(subWorkflowName);
                }
                // If the current workflowId is not recursive then we will add it in traversed object.
                const traversedSubworkflowIdsObjectToPass = { ...traversedSubworkflowIdsObject, [subWorkflowId]: true };
                // ==================  Recursive Call Validation : END ===================

                // First we will fetch subWorkflowDocument from the member variable #subWorkflowListWithUsedSubWorkflowIdArrayAndName
                let subWorkflowDocument = this.#subWorkflowListWithUsedSubWorkflowIdArrayAndName.find(({ workflowId }) => workflowId === subWorkflowId);
                if (!subWorkflowDocument) {
                    const fetchedRecord = await this.fetchSubWorkflowData({ subWorkflowId, projection: { name: 1, usedSubflowIdArray: 1 } });
                    if (fetchedRecord && fetchedRecord.data) {
                        subWorkflowDocument = fetchedRecord.data;
                        this.#subWorkflowListWithUsedSubWorkflowIdArrayAndName.push({
                            workflowId: subWorkflowId,
                            name: subWorkflowDocument.name,
                            usedSubWorkflowIdArray: subWorkflowDocument.usedSubflowIdArray
                        });
                    }
                }

                if (subWorkflowDocument) {
                    await this.#traverseUsedSubWorkflow({
                        usedSubWorkflowIdArray: subWorkflowDocument.usedSubflowIdArray,
                        traversedSubworkflowIdsObject: traversedSubworkflowIdsObjectToPass,
                        subWorkflowName: subWorkflowDocument.name
                    });
                }
            }
        }
    }

    async fetchSubWorkflowData({ subWorkflowId, projection = { errorCount: 1, name: 1, nodes: 1, usedSubflowIdArray: 1 } }) {
        switch (this.#workflowType) {
            case workflowTypes.PROCESS_WORKFLOW:
            case workflowTypes.PROCESS_SUB_WORKFLOW: {
                return await this.#processWorkflowService.getSubWorkflowDataFromServerAsync(this.#containerId, subWorkflowId, projection)
            }
            case workflowTypes.BOT_WORKFLOW:
            case workflowTypes.BOT_SUB_WORKFLOW:
            default: {
                return await this.#workflowService.getWorkflowDataFromServerAsync(this.#botId, subWorkflowId, projection);
            }
        }
    }
}