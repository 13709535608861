import WorkflowErrors from "../../utils/workflowErrors";
import { inputCardsArray } from "./builderStoreLogic";
import { getChannelFromTheChannelSwitchNode } from "./channelsValidation";
import { reports_key } from "./genericValidation";
import { Queue } from "./Queue";
import { suggestedVariableValidation } from "./suggestedVariableValidation";
import { nodeValidationsLogic } from "./validations";


export const findNode = (nodes, nodeId) => {
    return nodes.find((node) => node.id === nodeId);
}

export const findAllOutGoingEdgesOfNode = (edges, nodeId) => {
    return edges.filter((edge) => edge.source === nodeId);
}

export const findAllInCommingEdgesOfNode = (edges, nodeId) => {
    return edges.filter((edge) => edge.target === nodeId);
}

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const traverseToAllNodesForDeleteChannelIdAndParentId = ({ edges, nodes, sourceNodeId, targetNodeId, sourceNodeHandle, connectedEdge }) => {
    // This is the node where our traversal has to start, hence we cache it
    let firstSourceNode = nodes.find((node) => node.id === sourceNodeId);
    let belongsToChannelArrayToDelete = [];
    if (firstSourceNode.type === "channelSwitchNode") {
        const result = getChannelFromTheChannelSwitchNode(
            firstSourceNode,
            sourceNodeHandle
        );
        if (result.status) {
            belongsToChannelArrayToDelete.push({
                channelId: result.channelId,
                channelSwitchNodeId: firstSourceNode.id,
                refCount: 1,
            });
        } else {
            throw new Error("Development Issue: Could not obtain channel id from channel switch card.");
        }
    } else {
        belongsToChannelArrayToDelete = firstSourceNode.data.belongsToChannel;
    }

    // Init Queue
    let queue = new Queue(); // FIFO
    queue.enqueue({ targetNodeId, sourceNodeId, belongsToChannelArrayToDelete, connectedEdge });

    const traversedNodes = {};
    const traversedEdges = {};
    let nodesWhichAreNotTraversed = [];
    traversedNodes[sourceNodeId] = true;

    // MAJOR STEP: Writing first node id to all the subsequent node as parent ----------------------------------------------
    while (!queue.isEmpty()) {
        const { targetNodeId, sourceNodeId, belongsToChannelArrayToDelete, connectedEdge } = queue.dequeue();
        let belongsToChannelForPassingInQueueForRefrence = [];
        // we start with the target node of the first source node
        let targetNode = nodes.find((node) => node.id === targetNodeId);
        let sourceNode = nodes.find((node) => node.id === sourceNodeId);
        // Check if target node exist as a reference in source Node which means it is a recursive pattern so we are not traversing it
        if (sourceNode.data.parentNodesIdArray.find((parentNodeId) => parentNodeId.parentId === targetNode.id)) {
            continue;
        }

        // make source node as the parent of the target node.
        // if source node already exist as a parent node make a increament else add source node in parent node array
        const parentNodeInTargetNodeIndex = targetNode.data.parentNodesIdArray.findIndex((parent) => parent.parentId === firstSourceNode.id);
        if (parentNodeInTargetNodeIndex !== -1) {
            targetNode.data.parentNodesIdArray[parentNodeInTargetNodeIndex].refCount -= 1;
            if (targetNode.data.parentNodesIdArray[parentNodeInTargetNodeIndex].refCount <= 0) {
                targetNode.data.parentNodesIdArray.splice(parentNodeInTargetNodeIndex, 1);
            }
        }

        for (let firstSourceNodeParentId of firstSourceNode.data.parentNodesIdArray) {
            const parentNodeInTargetNodeIndex = targetNode.data.parentNodesIdArray.findIndex((parent) => parent.parentId === firstSourceNodeParentId.parentId);
            if (parentNodeInTargetNodeIndex !== -1) {
                targetNode.data.parentNodesIdArray[parentNodeInTargetNodeIndex].refCount = targetNode.data.parentNodesIdArray[parentNodeInTargetNodeIndex].refCount - firstSourceNodeParentId.refCount;
                if (targetNode.data.parentNodesIdArray[parentNodeInTargetNodeIndex].refCount <= 0) {
                    targetNode.data.parentNodesIdArray.splice(parentNodeInTargetNodeIndex, 1);
                }
            }
        }

        if (targetNode.type === "channelSwitchNode") {
            belongsToChannelForPassingInQueueForRefrence = [];
        } else {
            belongsToChannelForPassingInQueueForRefrence = belongsToChannelArrayToDelete;
            // ------------------------ PASSING CHANNEL INFO TO DELETE IT FROM CHILDREN ----------------------------------------------------------
            // Taking opportunity of first traversal to transfer channel information to all nodes

            for (let belongToChannelOfSourceNode of belongsToChannelArrayToDelete) {
                const indexBelongToChannelObjectInTargetNode = targetNode.data.belongsToChannel.findIndex((channelInTarget) =>
                (channelInTarget.channelId ===
                    belongToChannelOfSourceNode.channelId &&
                    channelInTarget.channelSwitchNodeId ===
                    belongToChannelOfSourceNode.channelSwitchNodeId)
                );
                if (indexBelongToChannelObjectInTargetNode !== -1) {
                    targetNode.data.belongsToChannel[indexBelongToChannelObjectInTargetNode].refCount -= belongToChannelOfSourceNode.refCount;
                    if (
                        targetNode.data.belongsToChannel[indexBelongToChannelObjectInTargetNode].refCount <= 0
                    ) {
                        targetNode.data.belongsToChannel.splice(indexBelongToChannelObjectInTargetNode, 1);
                    }
                }
            }
        }

        // --------------------------------------------------------------------


        // HERE WE ARE CHECKING THAT ALL INCOMING EDGES OF CURRENTNODE ARE TRAVERSED IF YES THEN ONLY WE WILL ADD ALL OUTGOING EDGES OF CURRENTNODE 
        // OTHERWISE WE WILL NOT ADD IT IN QUEUE

        // Here we are assuming all incoming edges of currentNode is traversed
        let isAllInComingEdgesTraversed = true;

        // first we will add edge between previous Node and currentNode into traversedEdges  
        traversedEdges[connectedEdge.id] = true;

        const allInComingEdges = findAllInCommingEdgesOfNode(edges, targetNode.id);

        for (let index = 0; index < allInComingEdges.length; index++) {
            const incomingEdge = allInComingEdges[index];
            // checking incoming edge is traversed or not and incoming edge type is not of loopBackCard => having handle id 'continue_target_of_loop_back_node'
            if (!traversedEdges[incomingEdge.id] && incomingEdge.sourceHandle.includes('continue_target_of_loop_back_node') === false) {
                // since all the edges of current node is not traversed so we are not adding it in the queue.
                // we will wait for this condition to be satisfied in later
                isAllInComingEdgesTraversed = false;
                break;
            }
        }

        if (isAllInComingEdgesTraversed && !traversedNodes[targetNode.id]) {
            // here we know that all incoming edges of current node is traversed and we are going to traverse the current node
            // thats why we will remove current node from nodesWhichAreNotTraversed array
            nodesWhichAreNotTraversed = nodesWhichAreNotTraversed.filter(nodeId => nodeId === targetNode.id);
            const allOutGoingEdgesOfCurrentNode = findAllOutGoingEdgesOfNode(edges, targetNode.id);
            if (allOutGoingEdgesOfCurrentNode.length === 0) {
                // That means we have no outgoing edges of current node and this is the last node of the flow
                continue;
            }

            for (let index = 0; index < allOutGoingEdgesOfCurrentNode.length; index++) {
                const connectedEdge = allOutGoingEdgesOfCurrentNode[index];
                queue.enqueue({
                    targetNodeId: connectedEdge.target,
                    sourceNodeId: connectedEdge.source,
                    belongsToChannelArrayToDelete: belongsToChannelForPassingInQueueForRefrence,
                    connectedEdge
                });
            }

            traversedNodes[targetNode.id] = true;
        }

        if (isAllInComingEdgesTraversed === false) {
            // Here we know that one or more incoming edge of current node is not traversed yet
            // so we will add current node in nodesWhichAreNotTraversed Array
            nodesWhichAreNotTraversed = Array.from(new Set([...nodesWhichAreNotTraversed, targetNode.id]));
        }

        if (queue.isEmpty() && nodesWhichAreNotTraversed.length > 0) {
            const currentNodeId = nodesWhichAreNotTraversed.shift();
            const allOutGoingEdgesOfCurrentNode = findAllOutGoingEdgesOfNode(edges, currentNodeId);
            if (allOutGoingEdgesOfCurrentNode.length === 0) {
                // That means we have no outgoing edges of current node and this is the last node of the flow
                continue;
            }
            // now we have edges of currentNode
            // find all nodes and update the belongs to arrary
            for (let index = 0; index < allOutGoingEdgesOfCurrentNode.length; index++) {
                const connectedEdge = allOutGoingEdgesOfCurrentNode[index];
                queue.enqueue({
                    targetNodeId: connectedEdge.target,
                    sourceNodeId: connectedEdge.source,
                    belongsToChannelArrayToDelete: belongsToChannelForPassingInQueueForRefrence,
                    connectedEdge
                });
            }
            traversedNodes[currentNodeId] = true;
        }
    }

    return nodes;
};

/**
 * This method updated parentNodeId and channel id in child nodes, this method also mantains refCount
 * refCount was introduced to keep the track of edges coming from different parent node also mantain the count for the same
 * @param {*} param0 
 * @returns 
 */
export const traverseToAllNodesForAddChannelIdAndParentId = ({
    state,
    sourceNodeId,
    targetNodeId,
    startNodeHandle,
    connectedEdge
}) => {
    // clone node array
    const clonedNodesArray = state.nodes.map((node) => {
        return {
            ...node,
            id: node.id,
            data: {
                ...node.data,
                belongsToChannel: node.data.belongsToChannel.map(
                    (belongsToChannel) => ({
                        ...belongsToChannel,
                        channelId: belongsToChannel.channelId,
                        refCount: belongsToChannel.refCount,
                    })
                ),
                parentNodesIdArray: node.data.parentNodesIdArray.map(
                    (parentNodesId) => ({
                        ...parentNodesId,
                        refCount: parentNodesId.refCount,
                    })
                ),
                reports: {
                    errors: (node.data.reports && node.data.reports.errors) ? node.data.reports.errors : {},
                    warnings: (node.data.reports && node.data.reports.warnings) ? node.data.reports.warnings : {}
                }
            },
            styles: { ...node.data.style },
        };
    });

    const firstSourceNode = clonedNodesArray.find((node) => node.id === sourceNodeId); //  find source node
    let belongsToChannelArrayToAdd = [];

    if (firstSourceNode.type === "channelSwitchNode") {
        const result = getChannelFromTheChannelSwitchNode(
            firstSourceNode,
            startNodeHandle
        );
        if (result.status) {
            belongsToChannelArrayToAdd.push({
                channelId: result.channelId,
                channelSwitchNodeId: firstSourceNode.id,
                refCount: 1,
            });
        } else {
            throw new Error("Development Issue: Could not obtain channel id from channel switch card.");
        }
    } else {
        belongsToChannelArrayToAdd = firstSourceNode.data.belongsToChannel;
    }

    // ------------------ MAJOR STEP ---------------------------------------------------
    // init queue
    let queue = new Queue(); // FIFO

    /*
          taragetNodeId:  passing the target node of sourceNode---->targetNode
          sourceNodeId:  passing the source node of sourceNode---->targetNode
          belongsToChannelArrayToAdd:  for passing the belogsToChannelArray based on the sourceNode
      */
    queue.enqueue({ targetNodeId, sourceNodeId, belongsToChannelArrayToAdd, connectedEdge });

    const traversedNodes = {};
    const traversedEdges = {};
    let nodesWhichAreNotTraversed = [];
    traversedNodes[sourceNodeId] = true;

    // --------------- MAJOR STEP ----------------------------------------------------------

    // writing first node id to all the subsequent node as parent
    while (!queue.isEmpty()) {
        const { targetNodeId, sourceNodeId, belongsToChannelArrayToAdd, connectedEdge } =
            queue.dequeue();
        let belongsToChannelToPassInQueue = [];
        // we start with the target node of the first source node
        let targetNode = clonedNodesArray.find((node) => node.id === targetNodeId);
        let sourceNode = clonedNodesArray.find((node) => node.id === sourceNodeId);
        if (sourceNode.data.parentNodesIdArray.find((parentNodeId) => parentNodeId.parentId === targetNode.id)) {
            continue;
        }

        // make source node as the parent of the target node.
        // if source node already exist as a parent node make a increament else add source node in parent node array
        const parentNodeInTargetNode = targetNode.data.parentNodesIdArray.find(
            (parent) => parent.parentId === firstSourceNode.id
        );
        if (parentNodeInTargetNode) {
            parentNodeInTargetNode.refCount += 1;
        } else {
            targetNode.data.parentNodesIdArray.push({
                parentId: firstSourceNode.id,
                refCount: 1,
            });
        }

        // now add the all grand parent as parent in target node by looping over all grand parent
        for (let firstSourceNodeParentIds of firstSourceNode.data
            .parentNodesIdArray) {
            const parentNodeInTargetNodeIndex =
                targetNode.data.parentNodesIdArray.findIndex(
                    (parent) => parent.parentId === firstSourceNodeParentIds.parentId
                );
            if (targetNode.id !== firstSourceNodeParentIds.parentId) {
                if (parentNodeInTargetNodeIndex !== -1) {
                    targetNode.data.parentNodesIdArray[
                        parentNodeInTargetNodeIndex
                    ].refCount =
                        targetNode.data.parentNodesIdArray[parentNodeInTargetNodeIndex]
                            .refCount + firstSourceNodeParentIds.refCount;
                } else {
                    targetNode.data.parentNodesIdArray.push({
                        parentId: firstSourceNodeParentIds.parentId,
                        refCount: firstSourceNodeParentIds.refCount,
                    });
                }
            }
        }

        if (targetNode.type === "channelSwitchNode") {
            // for the channelSwitch node and the child of channelSwitch node the belongs to channel array will be different
            belongsToChannelToPassInQueue = [];
        } else {
            // for the other than channelSwitch node the belongToChannel Array will be the same as source array
            belongsToChannelToPassInQueue = belongsToChannelArrayToAdd;

            // ------------------------ PASSING CHANNEL INFO ----------------------------------------------------------
            // Taking opportunity of first traversal to transfer channel information to all nodes
            for (let belongToChannelOfSourceNode of belongsToChannelArrayToAdd) {
                const indexBelongToChannelObjectInTargetNode =
                    targetNode.data.belongsToChannel.findIndex(
                        (channelInTarget) =>
                            channelInTarget.channelId ===
                            belongToChannelOfSourceNode.channelId &&
                            channelInTarget.channelSwitchNodeId ===
                            belongToChannelOfSourceNode.channelSwitchNodeId
                    );
                if (indexBelongToChannelObjectInTargetNode !== -1) {
                    targetNode.data.belongsToChannel[
                        indexBelongToChannelObjectInTargetNode
                    ].refCount += belongToChannelOfSourceNode.refCount;
                } else {
                    targetNode.data.belongsToChannel.push({
                        channelId: belongToChannelOfSourceNode.channelId,
                        channelSwitchNodeId:
                            belongToChannelOfSourceNode.channelSwitchNodeId,
                        refCount: belongToChannelOfSourceNode.refCount,
                    });
                }
            }
        }

        // --------------------------------------------------------------------


        // HERE WE ARE CHECKING THAT ALL INCOMING EDGES OF CURRENTNODE ARE TRAVERSED IF YES THEN ONLY WE WILL ADD ALL OUTGOING EDGES OF CURRENTNODE 
        // OTHERWISE WE WILL NOT ADD IT IN QUEUE

        // Here we are assuming all incoming edges of currentNode is traversed
        let isAllInComingEdgesTraversed = true;

        // first we will add edge between previous Node and currentNode into traversedEdges  
        traversedEdges[connectedEdge.id] = true;

        const allInComingEdges = findAllInCommingEdgesOfNode(state.edges, targetNode.id);

        for (let index = 0; index < allInComingEdges.length; index++) {
            const incomingEdge = allInComingEdges[index];
            // checking incoming edge is traversed or not and incoming edge type is not of loopBackCard => having handle id 'continue_target_of_loop_back_node'
            if (!traversedEdges[incomingEdge.id] && incomingEdge.sourceHandle.includes('continue_target_of_loop_back_node') === false) {
                // since all the edges of current node is not traversed so we are not adding it in the queue.
                // we will wait for this condition to be satisfied in later
                isAllInComingEdgesTraversed = false;
                break;
            }
        }

        if (isAllInComingEdgesTraversed && !traversedNodes[targetNode.id]) {
            // here we know that all incoming edges of current node is traversed and we are going to traverse the current node
            // thats why we will remove current node from nodesWhichAreNotTraversed array
            nodesWhichAreNotTraversed = nodesWhichAreNotTraversed.filter(nodeId => nodeId === targetNode.id);
            const allOutGoingEdgesOfCurrentNode = findAllOutGoingEdgesOfNode(state.edges, targetNode.id);
            if (allOutGoingEdgesOfCurrentNode.length === 0) {
                // That means we have no outgoing edges of current node and this is the last node of the flow
                continue;
            }

            for (let index = 0; index < allOutGoingEdgesOfCurrentNode.length; index++) {
                const connectedEdge = allOutGoingEdgesOfCurrentNode[index];
                queue.enqueue({
                    targetNodeId: connectedEdge.target,
                    sourceNodeId: connectedEdge.source,
                    belongsToChannelArrayToAdd: belongsToChannelToPassInQueue,
                    connectedEdge
                });
            }

            traversedNodes[targetNode.id] = true;
        }

        if (isAllInComingEdgesTraversed === false) {
            // Here we know that one or more incoming edge of current node is not traversed yet
            // so we will add current node in nodesWhichAreNotTraversed Array
            nodesWhichAreNotTraversed = Array.from(new Set([...nodesWhichAreNotTraversed, targetNode.id]));
        }

        if (queue.isEmpty() && nodesWhichAreNotTraversed.length > 0) {
            const currentNodeId = nodesWhichAreNotTraversed.shift();
            const allOutGoingEdgesOfCurrentNode = findAllOutGoingEdgesOfNode(state.edges, currentNodeId);
            if (allOutGoingEdgesOfCurrentNode.length === 0) {
                // That means we have no outgoing edges of current node and this is the last node of the flow
                continue;
            }
            // now we have edges of currentNode
            // find all nodes and update the belongs to arrary
            for (let index = 0; index < allOutGoingEdgesOfCurrentNode.length; index++) {
                const connectedEdge = allOutGoingEdgesOfCurrentNode[index];
                queue.enqueue({
                    targetNodeId: connectedEdge.target,
                    sourceNodeId: connectedEdge.source,
                    belongsToChannelArrayToAdd: belongsToChannelToPassInQueue,
                    connectedEdge
                });
            }
            traversedNodes[currentNodeId] = true;
        }
    }

    return clonedNodesArray;
};

/**
 * Validation specific traversal
 * @param {*} param0 
 * @returns 
 */
export const traverseToAllChildNodesForValidations = ({
    edges,
    stateVariables,
    stateVariableSuggestionList,
    nodes,
    nodeId,
    localeSupportedByBot
}) => {
    const clonedNodesArray = nodes.map((node) => {
        return {
            ...node,
            id: node.id,
            data: {
                ...node.data,
                belongsToChannel: node.data.belongsToChannel.map(
                    (belongsToChannel) => ({
                        ...belongsToChannel,
                        channelId: belongsToChannel.channelId,
                        refCount: belongsToChannel.refCount,
                    })
                ),
                parentNodesIdArray: node.data.parentNodesIdArray.map(
                    (parentNodesId) => ({
                        ...parentNodesId,
                        refCount: parentNodesId.refCount,
                    })
                ),
                reports: {
                    errors: (node.data.reports && node.data.reports.errors) ? node.data.reports.errors : {},
                    warnings: (node.data.reports && node.data.reports.warnings) ? node.data.reports.warnings : {}
                }
            },
            styles: { ...node.data.style },
        };
    });


    // ------------------ MAJOR STEP ---------------------------------------------------
    // init queue
    let queue = new Queue(); // FIFO

    let visited = {};

    visited[nodeId] = true;

    queue.enqueue(nodeId);

    // --------------- MAJOR STEP ----------------------------------------------------------

    while (!queue.isEmpty()) {
        const nodeId = queue.dequeue();
        // we start with the target node of the first source node
        const currentNode = clonedNodesArray.find((node) => node.id === nodeId);
        // -------------------------------   Validation of target Node --------------------------
        // let errorNotFound1 = false;
        // let errorNotFound2 = false;

        let reportsOfNodeValidationOnChannel = nodeValidationsLogic.validateNodeForChannel(currentNode, localeSupportedByBot);
        // if (result !== undefined && result.flag === true) {
        //     errorNotFound1 = false;
        //     errorObject = { flag: result.flag, messages: result.messages };
        //     currentNode.style = {
        //         // boxShadow: "rgb(244 109 109) 0px 1px 15px 1px",
        //         // border: "2px solid rgb(244 109 109)",
        //     };
        // } else {
        //     errorNotFound1 = true;
        // }

        currentNode.data.reports = reportsOfNodeValidationOnChannel;

        let reportsOfNodeValidationOnVariableSuggestion = suggestedVariableValidation(
            currentNode,
            stateVariables,
            stateVariableSuggestionList,
            localeSupportedByBot
        );
        // if (
        //     resultOfVariableSuggetionValidation !== undefined &&
        //     resultOfVariableSuggetionValidation.flag === true
        // ) {
        //     errorNotFound2 = false;
        //     if (errorObject.messages !== undefined) {
        //         errorObject = {
        //             flag: resultOfVariableSuggetionValidation.flag,
        //             messages: [
        //                 ...errorObject.messages,
        //                 ...resultOfVariableSuggetionValidation.messages,
        //             ],
        //         };
        //     } else {
        //         errorObject = {
        //             flag: resultOfVariableSuggetionValidation.flag,
        //             messages: resultOfVariableSuggetionValidation.messages,
        //         };
        //     }
        //     currentNode.style = {
        //         // boxShadow: "rgb(244 109 109) 0px 1px 15px 1px",
        //         // border: "2px solid rgb(244 109 109)",
        //     };
        // } else {
        //     errorNotFound2 = true;
        // }

        // if (errorNotFound1 === true && errorNotFound2 === true) {
        //     errorObject = {
        //         flag: false,
        //         messages: [],
        //     };
        //     currentNode.style = "";
        // }
        // currentNode.data.error = {
        //     flag: errorObject.flag,
        //     messages: errorObject.messages,
        // };

        currentNode.data.reports = reportsOfNodeValidationOnVariableSuggestion;

        let date = new Date();

        currentNode.data.validateCalledAt = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

        ///--------------------------------------------------------------------------------------

        const sourceNodeEdgesArray = edges.filter((edge) => edge.source === nodeId);
        sourceNodeEdgesArray.forEach((edge) => {
            if (!visited[edge.target]) {
                if (!(edge.sourceHandle !== null && edge.sourceHandle.includes("continue_target_of_loop_back_node"))) {
                    /// for start loop and end loop
                    queue.enqueue(edge.target);
                    visited[edge.target] = true;
                }
            }
        });
    }

    return clonedNodesArray;
};

/**
 * This method will delete the edge which is connected by loopback node [continue handle]
 * @param {*} {
    nodes,
    edges,
    nodeId,
    parentListOfSourceNode,
} 
 * @returns 
 */
export const traversalForDeletingLoopBack = ({
    nodes,
    edges,
    nodeId,
    parentListOfSourceNode,
}) => {
    // let clonedEdgesArray = edges.map((edge) => {
    //     return {
    //         ...edge,
    //     };
    // });

    let clonedEdgesArray = edges;

    // init queue
    let queue = new Queue(); // FIFO

    let visited = {};

    visited[nodeId] = true;

    queue.enqueue(nodeId);

    while (!queue.isEmpty()) {
        const nodeId = queue.dequeue();

        const currentNode = nodes.find((node) => node.id === nodeId);
        if (currentNode) {
            if (currentNode.type === "loopBackNode") {
                const loopBackNodeSourceEdge = clonedEdgesArray.find(
                    (edge) => edge.source === nodeId
                );
                if (loopBackNodeSourceEdge) {
                    let findResult = parentListOfSourceNode.find((parent) => parent.parentId === loopBackNodeSourceEdge.target);
                    if (findResult) {
                        currentNode.data.contents.isSourceNodeConnected = false;
                        const newEdgesArrayAfterDeletingLoopBackEdge = clonedEdgesArray.filter((edge) => edge.id !== loopBackNodeSourceEdge.id);
                        clonedEdgesArray = newEdgesArrayAfterDeletingLoopBackEdge;
                    }
                }
            }

            const sourceNodeEdgesArray = clonedEdgesArray.filter((edge) => edge.source === nodeId);
            sourceNodeEdgesArray.forEach((edge) => {
                if (!visited[edge.target]) {
                    if (
                        !(edge.sourceHandle !== null && edge.sourceHandle.includes("continue_target_of_loop_back_node"))
                    ) {
                        /// for start loop and end loop
                        queue.enqueue(edge.target);
                        visited[edge.target] = true;
                    }
                }
            });
        }
    }
    return { nodes: nodes, edges: clonedEdgesArray };
};

/**
 * This method will validate all the nodes againt channel information
 * @param {*} { nodes, edges, localeSupportedByBot, stateVariables, stateVariableSuggestionList }
 * @returns new nodes ,errorCount, warning count 
 */
export const validateAllNodes = ({ nodes, edges, localeSupportedByBot, stateVariables, stateVariableSuggestionList }) => {

    let errorCount = 0;
    let warningCount = 0;

    const clonedNodesArray = nodes.map((node) => {
        return {
            ...node,
            id: node.id,
            data: {
                ...node.data,
                belongsToChannel: node.data.belongsToChannel.map(
                    (belongsToChannel) => ({
                        ...belongsToChannel,
                        channelId: belongsToChannel.channelId,
                        refCount: belongsToChannel.refCount,
                    })
                ),
                parentNodesIdArray: node.data.parentNodesIdArray.map(
                    (parentNodesId) => ({
                        ...parentNodesId,
                        refCount: parentNodesId.refCount,
                    })
                ),
            },
        };
    });

    try {

        for (let index = 0; index < clonedNodesArray.length; index++) {
            const currentNodeInIteration = clonedNodesArray[index];

            //  Initial error object
            //  Assuming we have no errors

            // STEP 1 ===================================== HANGING NODE VALIDATION =================================================

            if (currentNodeInIteration.type !== 'startNode') {
                const inComingEdgesOfNodes = findAllInCommingEdgesOfNode(edges, currentNodeInIteration.id);
                if (inComingEdgesOfNodes.length === 0) {
                    currentNodeInIteration.data.reports.warnings[reports_key.warning_key.HANGING_NODE.key] = {
                        messages: [WorkflowErrors.HANGING_NODE_ERROR]
                    };
                } else {
                    currentNodeInIteration.data.reports.warnings[reports_key.warning_key.HANGING_NODE.key] = {
                        messages: []
                    };
                }
            }

            // STEP 1 ===================================== HANGING NODE VALIDATION =================================================

            // -------------------------------   Validation of target Node --------------------------

            // STEP 2 ===================================== NODE_LEVEL_VALIDATION =================================================

            const reportsOfNodeValidationOnChannel = nodeValidationsLogic.validateNodeForChannel(currentNodeInIteration, localeSupportedByBot);

            currentNodeInIteration.data.reports = reportsOfNodeValidationOnChannel;

            // STEP 2 ===================================== NODE_LEVEL_VALIDATION =================================================



            // STEP 3 ===================================== VALIDATE_VARIABLE_SUGGESTION_LIST =================================================
            const reportsOfNodeValidationOnVariableSuggestion = suggestedVariableValidation(
                currentNodeInIteration,
                stateVariables,
                stateVariableSuggestionList,
                localeSupportedByBot
            );

            // STEP 3 ===================================== VALIDATE_VARIABLE_SUGGESTION_LIST =================================================

            // Assign all the error to current node
            currentNodeInIteration.data = {
                ...currentNodeInIteration.data,
                reports: reportsOfNodeValidationOnVariableSuggestion
            }

            // Calculate all the error in current workflow

            // errorCount += currentNodeInIteration.data.error.messages.length;
            let messageLengthForError = 0;
            if (currentNodeInIteration.data.reports && currentNodeInIteration.data.reports.errors) {
                if (Object.keys(currentNodeInIteration.data.reports.errors).length > 0) {
                    const reportErrors = currentNodeInIteration.data.reports.errors;
                    Object.keys(reportErrors).forEach(errorKey => {
                        if (reportErrors[errorKey].messages && reportErrors[errorKey].messages.length > 0) {
                            messageLengthForError += reportErrors[errorKey].messages.length;
                        }
                    });
                }
                errorCount += messageLengthForError;
            }

            let messageLengthForWarning = 0;
            if (currentNodeInIteration.data.reports && currentNodeInIteration.data.reports.warnings) {
                if (Object.keys(currentNodeInIteration.data.reports.warnings).length > 0) {
                    let reportWarning = currentNodeInIteration.data.reports.warnings;
                    Object.keys(reportWarning).forEach(warningKey => {
                        if (reportWarning[warningKey].messages && reportWarning[warningKey].messages.length > 0) {
                            messageLengthForWarning += reportWarning[warningKey].messages.length;
                        }
                    });
                }
                warningCount += messageLengthForWarning;
            }


            // warningCount += currentNodeInIteration.data.warning.messages.length;



            let date = new Date();
            currentNodeInIteration.data.validateCalledAt = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        }

        return { nodes: clonedNodesArray, errorCount, warningCount };
    } catch (error) {
        // TODO Log this exception 
        return { nodes: clonedNodesArray, errorCount, warningCount };

    }
};