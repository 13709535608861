import axiosTestSimulator from "../../../../../utils/test_simulatorAxios";
import axios from "../../../../../utils/workflowAxios";

export const processWorkflowService = {

    async createProcessWorkflow({ name, botId }) {
        try {
            const result = await axios.post(`workflow/create`, { name, botId });
            return result;
        } catch (error) {
            console.log(error);
        }
    },

    async createProcessSubWorkflow({ name, containerId, containerName }) {
        try {
            const result = await axios.post(`process-container/sub-workflows/create/${containerId}`, { name, containerId, containerName });
            return result;
        } catch (error) {
            console.log(error);
        }
    },

    async updateProcessWorkflowDataAsync({
        processId,
        workflowId,
        nodes,
        edges,
        variables,
        variableSuggestionList,
        errorCount,
        usedSubflowIdArray,
        selectedLocale,
        defaultLocale,
        localeSupportedByBot,
        workflowSaveType
    }) {
        try {
            if (!processId) {
                throw new Error('ProcessId is null or undefined.')
            }

            const dataToSend = {
                nodes,
                edges,
                variables,
                variableSuggestionList,
                errorCount,
                usedSubflowIdArray,
                selectedLocale,
                defaultLocale,
                localeSupportedByBot,
                workflowSaveType
            };
            const response = await axios.post(
                `process-workflow/update/${processId}/${workflowId}`,
                dataToSend,
                { headers: { "Content-Type": "application/json" } }
            );
            return response;
        } catch (error) {
            console.log(error);
            return error;
        }
    },

    async updateProcessSubWorkflowDataAsync({
        container,
        workflowId,
        nodes,
        edges,
        variables,
        variableSuggestionList,
        errorCount,
        usedSubflowIdArray,
        workflowSaveType
    }) {
        const containerId = container.id;
        const containerName = container.name;
        try {
            const dataToSend = {
                nodes,
                edges,
                variables,
                variableSuggestionList,
                errorCount,
                usedSubflowIdArray,
                workflowSaveType
            };
            const response = await axios.put(
                `process-container/sub-workflows/${containerId}/${workflowId}`,
                dataToSend,
                {
                    headers: { "Content-Type": "application/json" },
                    params: { containerName }
                }
            );
            return response;
        } catch (error) {
            console.log(error);
            return error;
        }
    },

    async deleteWorkflow(botId, workflowId) {
        try {
            const result = await axios.delete(`workflow/${botId}/${workflowId}`);
            return result;
        } catch (error) {
            console.log(error);
        }
    },

    async deleteSubWorkflow(containerId, workflowId) {
        try {
            const result = await axios.delete(`process-container/sub-workflows/${containerId}/${workflowId}`);
            return result;
        } catch (error) {
            console.log(error);
        }
    },

    async getWorkflowListOfProcessFormServerAsync(processId) {
        try {
            const getAllWorkflowResult = await axios.get(`process-workflow/getAll/${processId}`);
            return getAllWorkflowResult.data.data;
        } catch (error) {
            console.log(error);
        }
    },

    async getSubWorkflowsOfContainerFormServerAsync(containerId, params) {
        try {
            const getAllWorkflowResult = await axios.get(`process-container/sub-workflows/all/${containerId}`, { params });
            return getAllWorkflowResult.data;
        } catch (error) {
            console.log(error);
        }
    },

    async getSubWorkflowsOfContainerFormServerAsyncWithPagination(containerId, params) {
        try {
            const getAllWorkflowResult = await axios.get(`process-container/sub-workflows/${containerId}`, { params });
            return getAllWorkflowResult.data;
        } catch (error) {
            console.log(error);
        }
    },

    async getWorkflowDataFromServerAsync(processId, workflowId) {
        try {
            const response = await axios.get(`process-workflow/getOne/${processId}/${workflowId}`, {
                headers: { "Content-Type": "application/json" },
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    async getSubWorkflowDataFromServerAsync(containerId, workflowId, projection = {}) {
        try {
            const response = await axios.get(`process-container/sub-workflows/${containerId}/${workflowId}`, {
                headers: { "Content-Type": "application/json" },
                params: projection,
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    async getMediaListFromServerAsync() {
        try {
            const response = await axios.get(`workflow/get-media`, {
                headers: { "Content-Type": "application/json" },
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    async getMessageTemplateList() {
        try {
            const getAllTemplateResult = await axios.get(`message-template/getAll`);
            return getAllTemplateResult.data;
        } catch (error) {
            console.log(error);
        }
    },

    async uploadMediaListInServerAsync(formData) {
        try {
            return await axios.post(`workflow/upload-media`, formData);
        } catch (error) {
            throw error;
        }
    },

    async deployBotAsync({
        botId,
        workflowId,
        nodes,
        edges,
        variables,
        variableSuggestionList,
    }) {
        try {
            const dataToSend = { nodes, edges, variables, variableSuggestionList };
            const response = await axios.post(
                `process-workflow/deploy-bot/${botId}/${workflowId}`,
                dataToSend,
                { headers: { "Content-Type": "application/json" } }
            );
            return response;
        } catch (error) {
            console.log(error);
            return error;
        }
    },

    async testUrlForWebhookNode({ url, method, headers, body }) {
        try {
            const response = await axios.post(
                `workflow/test-url-for-webhook`,
                { url, method, headers, body },
                { headers: { "Content-Type": "application/json" } }
            );
            return response;
        } catch (error) {
            console.log(error);
        }
    },

    async testWhatsAppInSimulator({
        isDefaultWorkflow,
        botId,
        workflowId,
        data,
        formData,
        botVersion
    }) {

        let path = "";
        if (isDefaultWorkflow) {
            path = `/whatsapp-simulated-server-for-default`;
        } else {
            path = `/whatsapp-simulated-server`;
        }

        if (formData !== undefined) {
            formData.append("botId", botId);
            formData.append("workflowId", workflowId);
            formData.append("data", JSON.stringify(data));
            formData.append("botVersion", botVersion);
            return await axiosTestSimulator.post(path, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        } else {
            return await axiosTestSimulator.post(
                path,
                { botId, workflowId, data, botVersion },
                {
                    headers: {},
                }
            );
        }
    },

    async testTubuluInSimulator({
        isDefaultWorkflow,
        botId,
        workflowId,
        data,
        formData,
        botVersion
    }) {

        let path = "";
        if (isDefaultWorkflow) {
            path = `tubulu/simulated-server-for-default`;
        } else {
            path = `tubulu/simulated-server`;
        }
        if (formData !== undefined) {
            formData.append("botId", botId);
            formData.append("workflowId", workflowId);
            formData.append("data", JSON.stringify(data));
            formData.append("botVersion", botVersion);
            return await axiosTestSimulator.post(path, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        } else {
            return await axiosTestSimulator.post(
                path,
                { botId, workflowId, data, botVersion },
            );
        }
    },

    async testWebChatInSimulator() { },

    async getDLTEntityDataForSMS({ integrationId }) {
        try {
            const result = await axios.get(`workflow/get-dlt-entity-data-for-sms?integrationId=${integrationId}`);
            if (
                result.data
                && result.data.senderNames
                && result.data.dltTemplateList
                && result.data.id
                && Array.isArray(result.data.dltTemplateList) === true
                && Array.isArray(result.data.senderNames) === true
            ) {
                return { dltEntityData: result.data };
            } else {
                return { dltEntityData: {} };
            }
        } catch (error) {
            console.log(error);
            return { dltEntityData: {} };
        }
    },

    async getSenderEmailListAndReplayToEmailListForEmail({ integrationId }) {
        try {
            const result = await axios.get(`workflow/get-sender-email-list?integrationId=${integrationId}`);
            if (result.data.senderEmailList) {
                return { replyToEmailList: result.data.replyToEmailList, senderEmailList: result.data.senderEmailList };
            } else {
                return { replyToEmailList: [], senderEmailList: [] };
            }
        } catch (error) {
            console.log(error);
            return { replyToEmailList: [], senderEmailList: [] };
        }
    },

    async deleteMedia(mediaFile) {
        try {
            return await axios.post(`workflow/delete-media`, mediaFile);
        } catch (error) {
            throw error;
        }
    },
};






