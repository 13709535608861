
export class TraversalClass {
    /**
    * @type {Array<{
    *      id:string,
    *      type:string,
    * }>}
     */
    #nodes = [];

    /**
    * @type {Array<{
    *      id:string,
    *      type:string,
    *      source:string,
    *      sourceHandle:string,
    *      target:string,
    *      targetHandle:string
    *  }>}
     */
    #edges = [];

    /**
     * @type {Array<string>}
     */
    #validCardTypeArrayForLoopBackCycle = [];

    /**
     * @param {{ 
     *  nodes:{ 
     *      id:string,
     *      type:string
     *  },
     *  edges:{
     *      id:string,
     *      type:string,
     *      source:string,
     *      sourceHandle:string,
     *      target:string,
     *      targetHandle:string
     *  }
     * }} param0
     */
    constructor({ nodes, edges }) {
        this.#nodes = nodes;
        this.#edges = edges;
    }

    /**
     * Traversal connected nodes to check for the valid card types for the loop back cycle. 
     * @param {*} sourceEdgesArray 
     * @param {*} traversedNodeIds 
     * @param {*} isValidCardFound 
     * @returns {Boolean}
     */

    #traverseLoopBackCycleAndReturnValidCardExistOrNot = ({ sourceEdgesArray, traversedNodeIds, isValidCardFound = false }) => {
        let isCycleContainValidCard = false;
        for (let index = 0; index < sourceEdgesArray.length; index++) {
            const edge = sourceEdgesArray[index];
            const targetNodeOfEdge = this.#nodes.find((node) => node.id === edge.target);
            if (targetNodeOfEdge) {
                if (traversedNodeIds[targetNodeOfEdge.id]) {
                    if (isValidCardFound === true) {
                        return true;
                    }
                    return false;
                }

                const traverseNodeIdsToPass = { ...traversedNodeIds, [targetNodeOfEdge.id]: true }
                let isValidFoundBetweenTheCycle = isValidCardFound;
                if (this.#validCardTypeArrayForLoopBackCycle.includes(targetNodeOfEdge.type)) {
                    isValidFoundBetweenTheCycle = true;
                }
                const sourceEdgeOfCurrentNode = this.#edges.filter(edge => edge.source === targetNodeOfEdge.id && !(edge.sourceHandle !== null && edge.sourceHandle.includes("continue_target_of_loop_back_node")));
                isCycleContainValidCard = this.#traverseLoopBackCycleAndReturnValidCardExistOrNot({
                    sourceEdgesArray: sourceEdgeOfCurrentNode,
                    traversedNodeIds: traverseNodeIdsToPass,
                    isValidCardFound: isValidFoundBetweenTheCycle,
                });
                if (isCycleContainValidCard) {
                    return isCycleContainValidCard;
                }
            }
        }
        return isCycleContainValidCard;
    };


    /**
     * This method to check for valid card type in the loop back cycle
     * @param {{loopBackNodeId:string, validCardTypeArrayForLoopBackCycle:string []}} param0 
     * @returns {Boolean}
     */
    checkIsValidCardTypePresentInLoopBackCycle({ loopBackNodeId, validCardTypeArrayForLoopBackCycle = [] }) {
        this.#validCardTypeArrayForLoopBackCycle = validCardTypeArrayForLoopBackCycle;
        const traversedNodeIds = {}
        traversedNodeIds[loopBackNodeId] = true;
        const sourceEdgesArray = this.#edges.filter(edge => edge.source === loopBackNodeId);
        return this.#traverseLoopBackCycleAndReturnValidCardExistOrNot({ sourceEdgesArray, traversedNodeIds });
    }
}